import { Action } from '@ngrx/store';
import { IData } from '@shared/interfaces';
import {EnvironmentType} from "@shared/enums/app.enums";

export enum DataActionTypes {
    AllDataRequested = '[Data] Requested all data for one edition',
    DataByUnitRequested = '[Data] Requested data for one unit',
    MainDataByUnitRequested = '[Data] Requested main data for one unit',
    DataAllMainDataForOneUnitRequested = '[Data] All main data for one unit requested',
    DataByYearRequested = '[Data] Requested yearly data',
    DataByVariableRequested = '[Data] Requested data for one Variable',
    DataByUrlRequested = '[Data] Requested data by url',
    DataLoaded = '[Data] Data loaded',
    DataRescaledByUnitRequested = '[DATA] Rescaled data by unit requested'
}

export class AllDataRequested implements Action {
    readonly type = DataActionTypes.AllDataRequested;

    constructor(public payload: { indice_code: string, year: number }) {}
}

export class DataByUnitRequested implements Action {
    readonly type = DataActionTypes.DataByUnitRequested;

    constructor(public payload: { indice: string, edition: number, unit_id: number }) {}
}

export class MainDataByUnitRequested implements Action {
    readonly type = DataActionTypes.MainDataByUnitRequested;

    constructor(public payload: { indice: string, edition: number, unit_id: number }) {}
}

export class DataAllMainDataForOneUnitRequested implements Action {
    readonly type = DataActionTypes.DataAllMainDataForOneUnitRequested;

    constructor(public payload: { code: string }) {}
}

export class DataByYearRequested implements Action {
    readonly type = DataActionTypes.DataByYearRequested;

    constructor(public payload: { year: number }) {}
}

export class DataByVariableRequested implements Action {
    readonly type = DataActionTypes.DataByVariableRequested;

    constructor(public payload: { variable_id: number }) {}
}

export class DataByUrlRequested implements Action {
    readonly type = DataActionTypes.DataByUrlRequested;

    constructor(public payload: { url: string }) {}
}

export class DataLoaded implements Action {
    readonly type = DataActionTypes.DataLoaded;

    constructor(public payload: { url: string, data: IData[], end: boolean }) {}
}

export class DataRescaledByUnitRequested implements Action {
    readonly type = DataActionTypes.DataRescaledByUnitRequested;

    constructor(public payload: { unit_id: number, environment: EnvironmentType }) {}
}

export type DataActions =
    DataLoaded
    | DataByVariableRequested
    | DataByYearRequested
    | DataByUnitRequested
    | MainDataByUnitRequested
    | DataAllMainDataForOneUnitRequested
    | DataByUrlRequested
    | AllDataRequested
    | DataRescaledByUnitRequested;
