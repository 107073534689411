import { Charts2SeriesTypes } from '@features/charts2/types/charts.enums';
import {Action} from "@ngrx/store";

export enum Charts2LegendPosition {
    top = 'top',
    bottom = 'bottom',
    left = 'left',
    right = 'right',
    none = 'none'
}

export enum Charts2LegendAlignment {
    left = 'left',
    middle = 'middle',
    right = 'right',
    bottom = 'bottom',
    top = 'top'
}

export enum Charts2LegendOrientation {
    horizontal = 'horizontal',
    vertical = 'vertical'
}

export enum Charts2LegendTypes {
    categorical = 'categorical',
    ordinal = 'ordinal',
    continuous = 'continuous'
}

export enum Charts2LegendObjectTypes {
    line = 'line',
    rect = 'rect',
    ordinal_rect = 'ordinal-rect',
    square = 'square',
    circle = 'circle',
    rangescatter = 'range-scatter',
    lineVertical = 'line-vertical',
    rectRounded = 'rect-rounded',
    label = 'label',
    none = 'none',
    extra = 'extra'
}

export const Charts2LegendSeries2Type = new Map([
    [
        Charts2SeriesTypes.catline,
        Charts2LegendObjectTypes.line
    ],
    [
        Charts2SeriesTypes.bar,
        Charts2LegendObjectTypes.rect
    ],
    [
        Charts2SeriesTypes.horizontal,
        Charts2LegendObjectTypes.line
    ],
    [
        Charts2SeriesTypes.line,
        Charts2LegendObjectTypes.line
    ],
    [
        Charts2SeriesTypes.point,
        Charts2LegendObjectTypes.circle
    ],
    [
        Charts2SeriesTypes.scatter,
        Charts2LegendObjectTypes.circle
    ],
    [
        Charts2SeriesTypes.rangescatter,
        Charts2LegendObjectTypes.circle
    ],
    [
        Charts2SeriesTypes.vertical,
        Charts2LegendObjectTypes.line
    ],
    [
        Charts2SeriesTypes.matrix,
        Charts2LegendObjectTypes.rect
    ],
    [
        Charts2SeriesTypes.map,
        Charts2LegendObjectTypes.square
    ]
]);

export const Charts2LegendItemsDimensions = new Map<Charts2LegendObjectTypes, any>([
    [
        Charts2LegendObjectTypes.circle,
        {
            obj: 'circle',
            r: 5,
            cx: 5,
            cy: -5,
            paddingRight: 15
        }
    ],
    [
        Charts2LegendObjectTypes.rect,
        {
            obj: 'rect',
            x: 0,
            y: -10,
            height: 10,
            width: 20,
            paddingRight: 25
        },
    ],
    [
        Charts2LegendObjectTypes.ordinal_rect,
        {
            obj: 'rect',
            x: 0,
            y: -10,
            height: 15,
            width: 40,
            paddingRight: 25
        },
    ],
    [
        Charts2LegendObjectTypes.square,
        {
             obj: 'rect',
             x: 0,
             y: -12,
             height: 14,
             width: 14,
             paddingRight: 25
        },
    ],
    [
        Charts2LegendObjectTypes.rectRounded,
        {
             obj: 'rect',
             x: 0,
             y: -10,
             height: 10,
             width: 20,
             rx: 5,
             paddingRight: 25,
        },
    ],
    [
        Charts2LegendObjectTypes.line,
        {
            obj: 'line',
            x: 0,
            y: -7,
            height: 4,
            width: 20,
            paddingRight: 25
        }
    ],
    [
        Charts2LegendObjectTypes.lineVertical,
        {
            obj: 'rect',
            x: 0,
            y: -10,
            height: 10,
            width: 3,
            paddingRight: 8
        }
    ]
]);

export enum Charts2LegendActionTypes {
    LegendHighlightClass = '[LEGEND] highlight class',
    LegendHighlightInterval = '[LEGEND] highlight interval',
    LegendHighlightValue = '[LEGEND] highlight value',
    LegendResetHighlights = '[LEGEND] reset highlights'
}

export class LegendHighlightClass implements Action {
    readonly type = Charts2LegendActionTypes.LegendHighlightClass;

    public constructor(public payload: { class: string, elem?: any }) {}
}

export class LegendHighlightInterval implements Action {
    readonly type = Charts2LegendActionTypes.LegendHighlightInterval;

    public constructor(public payload: { min: number, max: number }) {}
}

export class LegendHighlightValue implements Action {
    readonly type = Charts2LegendActionTypes.LegendHighlightValue;

    public constructor(public payload: { value: number }) {}
}

export class LegendResetHighlights implements Action {
    readonly type = Charts2LegendActionTypes.LegendResetHighlights;
}

export type Charts2LegendActions = LegendHighlightClass
    | LegendHighlightInterval
    | LegendHighlightValue
    | LegendResetHighlights;
