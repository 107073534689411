import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import { effect } from 'signal';
import {Store} from '@ngrx/store';
import {filter, map, mergeMap, withLatestFrom, concatMap, exhaustMap} from 'rxjs/operators';
import {of} from 'rxjs';

import {
    ListsActionTypes,
    ListsCmsRequestedBySlug,
    ListsLoaded,
    ListsLoadedAtRequestTime,
    ListsLoadRequested,
    ListsRemove,
    ListsRemoveUrlsFromRequested,
    ListsRequestedByUrl,
    ListsRequestedByUrls,
    ListsResetNotScopeId,
    ListsSetFeatureScope,
    ListsSetPageScope,
    ListsSetUrlAsRequested,
} from './lists.actions';

import {
    selectListKeysByNotScopeId,
    selectListsCurrentScopeByType,
    selectListsLoadingStateById, selectListsRequestedByUrls,
    selectListsResetFeatureKeys,
    selectListsResetPageKeys,
    selectListsScopeFeatureIsChanged,
    selectListsUrlIsLoaded,
} from '@features/general/lists/state/lists.selectors';
import { ROUTER_NAVIGATED } from '@ngrx/router-store';
import { RouterNavigatedAction } from '@ngrx/router-store/src/actions';
import { selectRouterUrlAsArray } from 'app/core/selectors';
import { QueueResolve } from '@features/general/queue/state/queue.actions';
import { ListsService } from '@features/general/lists/state/lists.service';
import {IAnswer} from '@shared/interfaces';
import {IList, ListLoadingStates, ListsCmsHelper} from '@features/general/lists/structure';

@Injectable()
export class ListsEffects {

    /**
     * Reset lists outside of the current scope
     */
    ListsResetNotScopeId = createEffect(() =>this.actions$
        .pipe(
            ofType<ListsResetNotScopeId>(ListsActionTypes.ListsResetNotScopeId),
            map(action => action.payload.scope_id),
            mergeMap((scope) => of(scope)
                .pipe(
                    withLatestFrom(this.store.select(selectListKeysByNotScopeId(scope))),
                    filter(([, keys]) => !!keys.length),
                    map(([, keys]) => new ListsRemove({ keys }))
                )),
        ));

    /**
     * Change the scope when navigation
     */
    ListsChangeRouterFeature = createEffect(() =>this.actions$
        .pipe(
            ofType<RouterNavigatedAction>(ROUTER_NAVIGATED),
            withLatestFrom(this.store.select(selectListsScopeFeatureIsChanged)),
            filter(([action, changed]) => changed),
            map(([action, changed]) => action.payload.routerState.url),
            withLatestFrom(this.store.select(selectRouterUrlAsArray)),
            map(([url, routerUrl]) => new ListsSetFeatureScope({feature: routerUrl[0] || '', page: url}))
        ));

    /**
     * Change the scope when navigation
     */
    ListsChangeRouterPage = createEffect(() =>this.actions$
        .pipe(
            ofType<RouterNavigatedAction>(ROUTER_NAVIGATED),
            withLatestFrom(this.store.select(selectListsScopeFeatureIsChanged)),
            filter(([action, changed]) => !changed),
            map(([action, changed]) => action.payload.routerState.url),
            map((url) => new ListsSetPageScope({page: url}))
        ));

    /**
     * Change the feature (data hub, c3, ...), remove lists
     */
    ListsChangeFeatureScope = createEffect(() =>this.actions$
        .pipe(
            ofType<ListsSetFeatureScope>(ListsActionTypes.ListsSetFeatureScope),
            withLatestFrom(this.store.select(selectListsResetFeatureKeys)),
            filter(([, keys]) => !!keys.length),
            map(([, keys]) => new ListsRemove({keys}))
        ));

    /**
     * Change the page, remove lists
     */
    ListsChangePageScope = createEffect(() =>this.actions$
        .pipe(
            ofType<ListsSetPageScope>(ListsActionTypes.ListsSetPageScope),
            withLatestFrom(this.store.select(selectListsResetPageKeys)),
            filter(([, keys]) => !!keys.length),
            map(([, keys]) => new ListsRemove({keys}))
        ));

    listsLoadRequested = createEffect(() =>this.actions$
        .pipe(
            ofType<ListsLoadRequested>(ListsActionTypes.ListsLoadRequested),
            mergeMap((request) => of(request)
                .pipe(
                    withLatestFrom(this.store.select(selectListsCurrentScopeByType(request.payload.type))),
                    map(([, current_scope]) => request.payload.lists.map((list) => ({...list, scope_id: current_scope }))),
                    map((lists) => new ListsLoaded({ lists }))
                ))
        ));

    listsLoaded = createEffect(() =>this.actions$
        .pipe(
            ofType<ListsLoaded>(ListsActionTypes.ListsLoaded),
            concatMap((request) => [
                new ListsRemoveUrlsFromRequested({ urls: request.payload.lists.map((list) => list.id) }),
                new QueueResolve({ relation: ListsActionTypes.ListsLoaded })
            ])
        ));

    listsCmsRequestBySlug = createEffect(() =>this.actions$
        .pipe(
            ofType<ListsCmsRequestedBySlug>(ListsActionTypes.ListsCmsRequestedBySlug),
            map((request) => request.payload),
            map((request) => ({request, url: ListsCmsHelper.getPartialListCmsUrlBySlug(request.slug, request.params)})),
            exhaustMap((vars) => of(vars)
                .pipe(
                    withLatestFrom(this.store.select(selectListsLoadingStateById(vars.url)))
                )),
            filter(([vars, loadState]) => loadState === ListLoadingStates.none),
            map(([vars, loaded]) => vars),
            filter((vars) => !!vars.url),
            map((vars) => new ListsSetUrlAsRequested({ url: vars.url, slug: vars.request.slug, scope: vars.request.type }))
        ));

    listsCmsRequestByUrl = createEffect(() =>this.actions$
        .pipe(
            ofType<ListsRequestedByUrl>(ListsActionTypes.ListsRequestedByUrl),
            map((request) => request.payload),
            filter((request) => !!request.url),
            exhaustMap((request) => of(request)
                .pipe(
                    withLatestFrom(this.store.select(selectListsLoadingStateById(request.url)))
                )),
            filter(([, loadState]) => [ListLoadingStates.none, ListLoadingStates.loaded].includes(loadState)),
            map(([request, loadState]) => {
                switch(loadState) {
                    case ListLoadingStates.none:
                        return new ListsSetUrlAsRequested({ url: request.url, slug: request.slug, scope: request.type });
                    case ListLoadingStates.loaded:
                    default:
                        return new ListsLoadedAtRequestTime(request);
                }

            })
        ));

    // Request multiple urls at a time
    listsCmsRequestedByUrls = createEffect(() =>this.actions$
        .pipe(
            ofType<ListsRequestedByUrls>(ListsActionTypes.ListsRequestedByUrls),
            map((request) => request.payload),
            filter((request) => !!request),
            mergeMap((payload) => of(payload)
                .pipe(
                    withLatestFrom(this.store.select(selectListsRequestedByUrls(payload))),
                    map(([, requests]) => requests)
                )),
            mergeMap(requests => [
                ...requests.needed.map((d) => new ListsSetUrlAsRequested(d)),
                ...requests.loaded.map((d) => new ListsLoadedAtRequestTime(d))
            ]),
        ))


    listsCmsRequestValuesFromCms = createEffect(() =>this.actions$
        .pipe(
            ofType<ListsSetUrlAsRequested>(ListsActionTypes.ListsSetUrlAsRequested),
            map((request) => request.payload),
            mergeMap((request) => of(request)
                .pipe(
                    withLatestFrom(this.store.select(selectListsUrlIsLoaded(request.url))),
                    filter(([, isLoaded]) => !isLoaded),
                    mergeMap(() => this.listService.getListById(request.url)),
                    filter((list) => !!list && !!list.data),
                    map((list: IAnswer<IList>) => {
                        return {
                            id: request.url,
                            slug: request.slug,
                            scope: request.scope,
                            scope_id: null,
                            values: list.data
                        };
                    }),
                    map((list: any) => new ListsLoadRequested({type: request.scope, lists: [list]}))
                )),
        ));

    constructor(private actions$: Actions, private store: Store<any>, private listService: ListsService) {}
}
