import { Action } from '@ngrx/store';
import { IUnitGroup } from '@shared/interfaces';

export enum UnitGroupsActionTypes {
    UnitGroupsByUrlRequested = '[UNITGROUPS] Unit groups by url requested',
    UnitGroupsLoaded = '[UNITGROUPS] Unit groups Loaded',
}

export class UnitGroupsByUrlRequested implements Action {
    readonly type = UnitGroupsActionTypes.UnitGroupsByUrlRequested;

    constructor(public payload: { url: string }) {}
}

export class UnitGroupsLoaded implements Action {
    readonly type = UnitGroupsActionTypes.UnitGroupsLoaded;

    constructor(public payload: { groups: IUnitGroup[] }) {}
}

export type UnitGroupsActions =
    UnitGroupsByUrlRequested
    | UnitGroupsLoaded;
