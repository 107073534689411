import { Action } from '@ngrx/store';
import { IGroup } from '@shared/interfaces';

export enum GroupsActionTypes {
    GroupsByEditionRequested = '[GROUPS] Requested all groups for an edition',
    GroupsLoaded = '[GROUPS]  Groups loaded',
}

export class GroupsByEditionRequested implements Action {
    readonly type = GroupsActionTypes.GroupsByEditionRequested;
}

export class GroupsLoaded implements Action {
    readonly type = GroupsActionTypes.GroupsLoaded;

    constructor(public payload: { groups: IGroup[] }) {}
}

export type GroupsActions =
    GroupsByEditionRequested
    | GroupsLoaded;
