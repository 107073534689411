import { RouterHelper } from '@shared/helpers';

export enum EuMimfRoutesList {
    HOME_PAGE = '',
    COUNTRY_PROFILES = 'country-profiles',
    COUNTRY_PROFILE = 'country-profile',
    MAPS = 'maps',
    RANKINGS = 'rankings',
    DOCUMENTATION = 'documentation',
    MAINTENANCE = 'maintenance',
}

export class EuMimfRoutes extends RouterHelper {
    static routesClass = EuMimfRoutes;
    static MAIN_URL = 'multidimensional-inequality';
    static CMS_SITE = 'mimf';
    static routes = EuMimfRoutesList;
}
