import { Component } from '@angular/core';
import { Action } from '@ngrx/store';
import { BaseComponent } from '@shared/base-classes/base-component';

@Component({
    selector: 'app-ecl-skeleton-loader',
    templateUrl: './ecl-skeleton-loader.component.html',
    styleUrls: ['./ecl-skeleton-loader.component.scss']
})
export class EclSkeletonLoaderComponent extends BaseComponent<any, any, Action> {

    configUpdate() {
    }

    dataUpdate() {
    }

    initConfig(): any {
        return undefined;
    }
}
