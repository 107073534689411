import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { GeneralEffects, generalReducer } from '@features/general/general.reducer';
import { GeneralService } from '@features/general/general.service';
import { CustomI18nService } from '@features/general/i18n/state/i18n.service';
import { ListsService } from '@features/general/lists/state/lists.service';
import { CmsService } from '@features/general/cms/state/cms.service';
import { CmsModuleService } from '@features/general/cms/state/cms.module.service';
import { HeadService } from '@features/general/head/state/head.service';
import { CmsChartsService } from '@features/general/charts/state/cms-charts.service';
import { PDFService } from '@features/general/pdf/pdf.service';
import { ToolService } from '@features/general/tool/state/tool.service';
import { MatomoService } from "@shared/services/matomo.service";
import { ChartV3Service } from "@shared/services/chartV3.service";

@NgModule({
  declarations: [],
  imports: [
      CommonModule,
      StoreModule.forFeature('general', generalReducer),
      EffectsModule.forFeature(GeneralEffects),
  ],
    providers: [
        GeneralService,
        ListsService,
        CmsService,
        CmsModuleService,
        HeadService,
        CmsChartsService,
        PDFService,
        CustomI18nService,
        ToolService,
        MatomoService,
        ChartV3Service
    ]
})
export class GeneralModule { }
