import { createSelector } from '@ngrx/store';

import * as fromCmsCharts from '@features/general/charts/state/cms-charts.reducer';
import { CmsPageParameterType } from '@features/general/charts/state/params/cms-chart-parameters.entity';
import { selectGeneralState } from '@features/general/general.selectors';
import { CmsChartsSetParamsStrategy } from '@features/general/charts/structure/cms-charts.structure';
import { CmsChartParametersHelper } from '@features/general/charts/state/params/cms-chart-parameter-helper';
import CmsChartParamsHelper from '@features/general/charts/state/params/cms-chart-params-helper';
import { selectCmsActiveCharts } from "@features/general/cms/state/cms.selectors";
import { CmsChartsHelper } from "@features/general/charts/structure/cms-charts.helper";
import { selectCmsChartId } from "@features/general/charts/state/main/entities";
import {ArrayHelper} from "@shared/helpers";

export const selectCmsChartsParamsState = createSelector(
    selectGeneralState,
    state => state.charts.params
);

export const selectAllCmsChartsParams = createSelector(
    selectCmsChartsParamsState,
    fromCmsCharts.selectAlLChartsParameters
);

export const selectAllCmsChartsParamsEntities = createSelector(
    selectCmsChartsParamsState,
    fromCmsCharts.selectEntitiesChartsParameters
);
/**
 * Get the chart params
 * @param chart_id
 */
export const selectCmsChartsGetChartParams = (chart_id: string) => createSelector(
    selectAllCmsChartsParams,
    (params) => params.filter((param) => param.chart_id === chart_id)
);

export const selectCmsChartsGetChartsParams = (chart_ids: string[]) => createSelector(
    selectAllCmsChartsParams,
    (params) => params.filter((param) => chart_ids.includes(param.chart_id))
);

/**
 * Get the chart page params
 * @param chart_id
 */
export const selectCmsChartsGetChartPageParams = (chart_id: string) => createSelector(
    selectCmsChartsGetChartParams(chart_id),
    (params) => params.filter((param) => param.type === CmsPageParameterType.page)
);

export const selectCmsChartsGetChartsPageParams = (chart_ids: string[]) => createSelector(
    selectCmsChartsGetChartsParams(chart_ids),
    (params) => params.filter((param) => param.type === CmsPageParameterType.page)
);


/**
 * Get the chart other params
 * @param chart_id
 */
export const selectCmsChartsGetChartOtherParams = (chart_id: string) => createSelector(
    selectCmsChartsGetChartParams(chart_id),
    (params) => params.filter((param) => param.type === CmsPageParameterType.other)
);

export const selectCmsChartsGetChartsOtherParams = (chart_ids: string[]) => createSelector(
    selectCmsChartsGetChartsParams(chart_ids),
    (params) => params.filter((param) => param.type === CmsPageParameterType.other)
);

export const selectCmsChartGetChartParamByName = (chart_id: string, name: string) => createSelector(
    selectCmsChartsGetChartParams(chart_id),
    (params) => params.find((param) => param.name === name)
);

export const selectCmsChartsGetChartIndependentParams = (chart_id: string) => createSelector(
    selectCmsChartsGetChartOtherParams(chart_id),
    (params) => params.filter((param) => param.dependencies.length === 0)
);

export const selectCmsChartsGetChartsIndependentParams = (chart_ids: string[]) => createSelector(
    selectCmsChartsGetChartsOtherParams(chart_ids),
    (params) => params.filter((param) => param.dependencies.length === 0)
);

export const selectCmsChartsGetChartIndependentParamNames = (chart_id: string) => createSelector(
    selectCmsChartsGetChartIndependentParams(chart_id),
    (params) => params.map((param) => param.name)
);

export const selectCmsChartsGetChartsIndependentParamNames = (chart_ids: string[]) => createSelector(
    selectCmsChartsGetChartsIndependentParams(chart_ids),
    (params) => params.map((param) => param.name)
);

export const selectCmsChartsParamIsPageType = (chart_id: string, name: string) => createSelector(
    selectCmsChartsGetChartPageParams(chart_id),
    (page_params) => !!page_params.find((param) => param.name === name)
);

export const selectCmsChartParamsByNames = (chart_id: string, names: string[]) => createSelector(
    selectCmsChartsGetChartParams(chart_id),
    (params) => params.filter((param) => names.includes(param.origin))
);

export const selectCmsChartsParamsByNames = (payload: {chart_id: string, names: string[]}[]) => createSelector(
    selectCmsChartsGetChartsParams(ArrayHelper.uniquePluck(payload, 'chart_id')),
    (params) => params.filter((param) => {
        const names = payload.find((p) => p.chart_id === param.chart_id)?.names || [];
        return names.includes(param.origin);
    })
);

export const selectCmsChartParamsWithSameOriginByName = (chart_id: string, name: string) => createSelector(
    selectCmsChartsGetChartParams(chart_id),
    selectCmsChartGetChartParamByName(chart_id, name),
    (params, current) => params.filter((param) => param.origin === current.origin)
);

export const selectCmsChartParamsWithSameOrigin = (chart_id: string, origin: string) => createSelector(
    selectCmsChartsGetChartParams(chart_id),
    (params) => params.filter((param) => param.origin === origin)
);

export const selectCmsChartCopiedParamsWithSameOrigin = (chart_id: string, origin: string) => createSelector(
    selectCmsChartsGetChartParams(chart_id),
    (params) => params.filter((param) => param.origin === origin && param.isCopy)
);

export const selectCmsChartsParamsGetParamsSubordinates = (chart_id: string, names: string[]) => createSelector(
    selectCmsChartParamsByNames(chart_id, names),
    (params) => CmsChartParametersHelper.GetParamsSubordinates(params)
);

export const selectCmsChartsParamsGetMultiParamsAllSubordinates = (args: {chart_id: string, names: string[]}[]) => createSelector(
    selectCmsChartsParamsByNames(args),
    selectCmsChartsGetChartsParams(ArrayHelper.uniquePluck(args, 'chart_id')),
    (named_params, all) => {
        const getSubordinates = (param, params) => {
            if (!param) { return []; }
            else {
                const subs = params.filter((d) => param.subordinates.includes(d.name) && d.chart_id === param.chart_id);
                return [...subs, ...subs.reduce((s,c) => [...s, ...getSubordinates(c, params)], [])];
            }
        }
        return named_params.reduce((res, curr) => {
            const subs = getSubordinates(curr, all);
            return [...res, ...subs];
        }, []);
    }
);

export const selectCmsChartsParamsGetMultiParamsDirectSubordinates = (args: {chart_id: string, names: string[]}[]) => createSelector(
    selectCmsChartsParamsByNames(args),
    selectCmsChartsGetChartsParams(ArrayHelper.uniquePluck(args, 'chart_id')),
    (named_params, all) => {
        return named_params.reduce((res, curr) => {
            const subs = all.filter((d) => curr.subordinates.includes(d.name) && d.chart_id === curr.chart_id);
            return [...res, ...subs];
        }, []);
    }
);

/**
 * Returns the direct param dependencies
 * @param chart_id
 * @param names
 */
export const selectCmsChartsParamsGetParamsDependencies = (chart_id: string, names: string[]) => createSelector(
    selectCmsChartParamsByNames(chart_id, names),
    (params) => {
        const parents = params.reduce((deps, param) => {
            deps.push(...param.dependencies);
            return deps;
        }, []);
        return Array.from(new Set(parents));
    }
);

export const selectCmsChartsParamsGetParamsDependenciesWithPages = (chart_id: string, names: string[]) => createSelector(
    selectCmsChartsParamsGetParamsDependencies(chart_id, names),
    selectCmsChartsGetChartPageParams(chart_id),
    (params, page) => [...params, ...page]
);

export const selectCmsChartsParamsGetParamsWithPage = (chart_id: string, names: string[]) => createSelector(
    selectCmsChartParamsByNames(chart_id, names),
    selectCmsChartsGetChartPageParams(chart_id),
    (params, page) => [...params, ...page]
);

export const selectCmsChartsParamsGetParamsByNameAndValue = (chart_id: string, name: string, value: any) => createSelector(
    selectCmsChartParamsByNames(chart_id, [name]),
    (params) => params.filter((param) => param.value === value)
);

export const selectCmsChartsParamsGetParamsByMultipleNameAndValue = (elems: {chart_id: string, name: string}[]) => createSelector(
    selectAllCmsChartsParams,
    (params) => {
        const ids = elems.map((elem) => CmsChartParametersHelper.getIdFromChartAndName(elem.chart_id, elem.name));
        return params.filter((param) => {
            const id = CmsChartParametersHelper.getIdFromChartAndName(param.chart_id, param.name);
            return ids.includes(id);
        });
    }
);

export const selectCmsParamsAddDefaultValue = ( elems: {chart_id: string, name: string}[]) => createSelector(
    selectAllCmsChartsParamsEntities,
    (params) => {
        return elems.map((elem) => {
            const id = CmsChartParametersHelper.getIdFromChartAndName(elem.chart_id, elem.name);
            return { ...elem, value: params[id]?.default_value || null };
        });
    }
);

/**
 * @param {{chart_id: string, name: string }} elems
 * name = param name
 * @return {{
 *     chart_id: string,
 *     name: string,
 *     value: number,
 *     loadStrategy: CmsChartLoadStrategy
 * }}
 */
export const selectCmsParamsAddDefaultValueAndStrategy = (elems: {chart_id: string, name: string}[]) => createSelector(
    selectAllCmsChartsParamsEntities,
    selectCmsActiveCharts,
    (params, charts) => {
        return elems.map((elem) => {
            const chart = charts.find((c) => selectCmsChartId(c) === elem.chart_id);
//            const loadStrategies = CmsChartsHelper.getChartLoadStrategies(chart);
            const loadStrategy = CmsChartsHelper.getChartParamLoadStrategy(chart, elem.name);
            const param_id = CmsChartParametersHelper.getIdFromChartAndName(elem.chart_id, elem.name);
//            return { ...elem, value: params[param_id]?.default_value || null, loadStrategy: loadStrategies && loadStrategies[elem.name] || null };
            return { ...elem, value: params[param_id]?.default_value || null, loadStrategy };
        });
    }
);

export const selectCmsChartsGetChartConvertedParams = (chart_id: string) => createSelector(
    selectCmsChartsGetChartParams(chart_id),
    (params) => params
        .filter((param) => param.value != null)
        .reduce((result, current) => {
            const param = result.find((d) => d.name === current.converted);
            if (param) {
                param.value += ',' + current.value;
            } else {
                result.push({ name: current.converted, value: current.value });
            }
            return result;
        }, [])
);

export const selectCmsChartsGetChartConvertedParamsAsObject = (chart_id: string) => createSelector(
    selectCmsChartsGetChartConvertedParams(chart_id),
    (params) => params.reduce((result, current) => {
        result[current.name] = current.value;
        return result;
    }, {})
);

/**
 * For use in the effect when setting multiple values
 * If param value not in values - remove
 * if one element of values not in params - add
 * @param chart_id
 * @param name
 * @param values Is the array with the full list of values to keep or add
 * @param strategy The strategy to apply to the params (toggle, default)
 */
export const selectCmsChartsParamsGetMultipleParams = (
    chart_id: string,
    name: string,
    values: any[],
    strategy = CmsChartsSetParamsStrategy.default) => createSelector(
        selectCmsChartParamsWithSameOriginByName(chart_id, name),
        (params) => CmsChartParamsHelper.CreateAddRemoveCopyActions(params, values, strategy)
);
