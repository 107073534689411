import { ListsCmsHelper } from '@features/general/lists/structure/list-cms.helper';

export enum ListsScopes {
    app = 'app',
    feature = 'feature',
    page = 'page',
}

export enum ListLoadingStates {
    none = 'none',
    loaded = 'loaded',
    requested = 'requested',
    loading = 'loading',
    nodata = 'nodata',
    error = 'error'
}

export const urlListModule = 'list/';

export enum ListsCmsSlugs {
    indices_per_unit = 'indices-per-unit',
    categories_per_unit = 'categories-per-unit',
    variables_per_index = 'variables-per-index',
    years_per_index = 'years-per-index',
    years_per_variable_group = 'years-per-variable-group',
    years_per_component_vgroup = 'years-per-component-variable-group',
    statistics_types = 'statistics-types',
    main_variables_per_index = 'main-variables-per-index',
    related_indices = 'related-indices',
    units_per_index = 'units-per-index',
    units_groups_per_index = 'units-groups-per-index',
    variables_per_index_regions = 'variables-per-index-regions',
    years_per_variable = 'years-per-variable',
    variable_groups_per_index = 'variable-groups-per-index',
    variable_groups_per_year = 'variable-groups-per-year',
    variable_groups_per_component = 'variable-groups-per-component',
    variables_per_index_group_year = 'variables-per-index-group-year',
    variables_per_index_group_year_levels = 'variables-per-index-group-year-levels',
    variables_per_index_limit_level = 'variables-per-index-limit-level',
    variables_per_index_limit_level_ignore_data = 'variables-per-index-limit-level-ignore-data',
    child_variables_per_index = 'child-variables-per-index',
    child_variables_per_index_group_year = 'child-variables-per-index-group-year',
    child_variables_per_index_type_check = 'child-variables-per-index-type-check',
    child_variables_per_root = 'child-variables-per-root',
    none = 'none'
}

export const ListsCms = new Map([
    [
        ListsCmsSlugs.indices_per_unit,
        {
            route: (params: {[_: string]: string | number}) => `${urlListModule}unit/${params.unit_code}/${params.unit_type}/indices`,
            rules: ['unit_code', 'unit_type']
        }
    ],
    [
        ListsCmsSlugs.categories_per_unit,
        {
            route: (params) => `${urlListModule}unit/${params.unit_code}/${params.unit_type}/categories`,
            rules: ['unit_code', 'unit_type']
        }
    ],
    [
        ListsCmsSlugs.variables_per_index,
        {
            route: (params) => {
                const args = ['type_id', 'breakdown', 'timeline'];
                const rules = { breakdowns: 'breakdown' };
                const url = ListsCmsHelper.ConvertArgsToStringUrl(args, params, rules);
                /*
                if (params.breakdowns) {
                    url += `?breakdown=true`;
                }*/

                return `${urlListModule}indice/${params.index_code}/components` + url;
            },
            rules: ['index_code']
        }
    ],/*
    [
        ListsCmsSlugs.child_variables_per_index,
        {
            route: (params) => {
                return `indice/${params.index_code}/components?root=${params.root}`;
            },
            rules: ['index_code', 'root']
        }
    ],*/
    [
        ListsCmsSlugs.child_variables_per_index,
        {
            route: (params) => {
                const args = ['root', 'type_id', 'breakdown'];
                const url = ListsCmsHelper.ConvertArgsToStringUrl(args, params);
                return `${urlListModule}indice/${params.index_code}/components` + url;
            },
            rules: ['index_code', 'root']
        }
    ],
    [
        ListsCmsSlugs.child_variables_per_root,
        {
            route: (params) => `${urlListModule}root/components?root=${params.root}&type_id=${params.type_id || 1}${params.levels ? '&levels=' + params.levels : ''}`,
            rules: ['root']
        }
    ],
    [
        ListsCmsSlugs.child_variables_per_index_type_check,
        {
            route: (params) => {
                const args = ['root', 'type_id', 'breakdown'];
                const url = ListsCmsHelper.ConvertArgsToStringUrl(args, params);

                return `${urlListModule}indice/${params.index_code}/components-data` + url;
            },
            rules: ['index_code', 'root', 'type_id']
        }
    ],
    [
        ListsCmsSlugs.variables_per_index_limit_level,
        {
            route: (params) => {
                return `${urlListModule}indice/${params.index_code}/components?levels=${params.levels}${params.root ? '&root=' + params.root : ''}${params.type_id ? '&type_id=' + params.type_id : ''}`
            },
            rules: ['index_code', 'levels']
        }
    ],
    [
        ListsCmsSlugs.variables_per_index_limit_level_ignore_data,
        {
            route: (params) => {
                return `${urlListModule}indice/${params.index_code}/components?levels=${params.levels}${params.root ? '&root=' + params.root : ''}`
            },
            rules: ['index_code', 'levels']
        }
    ],
    [
        ListsCmsSlugs.main_variables_per_index,
        {
            route: (params) => `${urlListModule}indice/${params.index_code}/main-components`,
            rules: ['index_code']
        }
    ],
    [
        ListsCmsSlugs.years_per_index,
        {
            route: (params) => `${urlListModule}indice/${params.index_code}/years`,
            rules: ['index_code']
        }
    ],
    [
        ListsCmsSlugs.years_per_variable_group,
        {
            route: (params) => `${urlListModule}indice/${params.index_code}/${params.variable_group_id}/years${params.type_id ? '?type_id=' + params.type_id : ''}`,
            rules: ['index_code', 'variable_group_id']
        }
    ],
    [
        ListsCmsSlugs.years_per_component_vgroup,
        {
            route: (params) => {
                const args = ['type_id'];
                const url = ListsCmsHelper.ConvertArgsToStringUrl(args, params);

                return `${urlListModule}components/${params.component}/${params.variable_group_id}/years` + url;
            },
            rules: ['component', 'variable_group_id']
        }
    ],
    [
        ListsCmsSlugs.statistics_types,
        {
            route: (params) => `${urlListModule}statistics/types`,
            rules: []
        }
    ],
    [
        ListsCmsSlugs.related_indices,
        {
           // route: (params) => `${urlListModule}indice/${params.index_code}/related-indices`,
           route: (params) => `chart/related-indices?index_code=${params.index_code.toString().toLowerCase().trim()}`,
            rules: ['index_code']
        }
    ],
    [
        ListsCmsSlugs.units_per_index,
        {
            route: (params) => `${urlListModule}indice/${params.index_code}/units`,
            rules: ['index_code']
        }
    ],
    [
        ListsCmsSlugs.units_groups_per_index,
        {
            route: (params) => `${urlListModule}indice/${params.index_code}/orgs-units`,
            rules: ['index_code']
        }
    ],
    [
        ListsCmsSlugs.variables_per_index_regions,
        {
            route: (params) => `${urlListModule}indice/${params.regional_index_code}/components${params.geo_unit_type ? '?unit_type=' + params.geo_unit_type : ''}`,
            rules: ['regional_index_code']
        }
    ],
    [
        ListsCmsSlugs.years_per_variable,
        {
            route: (params) => `${urlListModule}components/${params.component}/years`,
            rules: ['component']
        }
    ],
    [
        ListsCmsSlugs.variable_groups_per_index,
        {
            route: (params) => `${urlListModule}indice/${params.index_code}/variable-groups${params.hasDataTypeId ? '?type_id=' + params.type_id : ''}`,
            rules: ['index_code']
        }
    ],
    [
        ListsCmsSlugs.variable_groups_per_year,
        {
            route: (params) => `${urlListModule}indice/${params.index_code}/${params.year}/variable-groups${params.hasDataTypeId ? '?type_id=' + params.type_id : ''}`,
            rules: ['index_code', 'year']
        }
    ],
    [
        ListsCmsSlugs.variable_groups_per_component,
        {
            route: (params) => `${urlListModule}components/${params.component}/groups`,
            rules: ['component']
        }
    ],
    [
        ListsCmsSlugs.variables_per_index_group_year,
        {
            route: (params) => {
                const args = ['type_id'];
                const url = ListsCmsHelper.ConvertArgsToStringUrl(args, params);
                return `${urlListModule}indice/${params.index_code}/${params.year}/${params.variable_group_id}/components` + url;
            },
            rules: ['index_code', 'variable_group_id', 'year']
        }
    ],
    [
        ListsCmsSlugs.variables_per_index_group_year_levels,
        {
            route: (params) => `${urlListModule}indice/${params.index_code}/${params.year}/${params.variable_group_id}/components?levels=${params.levels}`,
            rules: ['index_code', 'variable_group_id', 'year']
        }
    ],
    [
        ListsCmsSlugs.child_variables_per_index_group_year,
        {
            route: (params) => `${urlListModule}indice/${params.index_code}/${params.year}/${params.variable_group_id}/components?root=${params.root}&type_id=${params.type_id}`,
            rules: ['index_code', 'variable_group_id', 'year', 'root', 'type_id']
        }
    ],
]);
