import { createFeatureSelector, createSelector } from '@ngrx/store';

import { CoinState } from '../index';
import * as fromLevels from './levels.reducers';

export const selectCoinState = createFeatureSelector<CoinState>('composite');

export const selectLevelsState = createSelector(
    selectCoinState,
    (state) => state.levels,
);

export const selectAllLevels = createSelector(
    selectLevelsState,
    fromLevels.selectAll,
);

export const selectLevelsMain = createSelector(
    selectAllLevels,
    levels => levels.filter(l => l.main)
);

export const selectLevelsMainIds = createSelector(
    selectLevelsMain,
    levels => levels.map(l => l.id)
);

export const selectAllLevelsByLevel = (level: number) => createSelector(
    selectAllLevels,
    levels => levels.filter(l => l.level === level)
);

export const selectAllLevelsIdsByLevel = (level: number) => createSelector(
    selectAllLevelsByLevel(level),
    levels => levels.map(l => l.id)
);

export const selectLevelsLoadedUrls = createSelector(
    selectLevelsState,
    (state) => state.loadedUrls,
);

export const selectEditionLevels = (indice: string, edition: number) => createSelector(
    selectAllLevels,
    (levels) => levels.filter((level) => level.indice === indice && level.edition === edition),
);

export const selectLevelById = (level_id: number) => createSelector(
    selectAllLevels,
    (levels) => levels.find((level) => level.id === level_id),
);

export const selectEditionLevelByLevel = (indice: string, edition: number, level: number) => createSelector(
    selectEditionLevels(indice, edition),
    (levels) => levels.find((lvl) => lvl.level === level),
);

export const selectLevelsUrlIsLoaded = (url: string) => createSelector(
    selectLevelsLoadedUrls,
    (loadedUrls) => loadedUrls.includes(url),
);

