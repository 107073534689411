import { Injectable } from '@angular/core';

import {Actions, createEffect, ofType} from '@ngrx/effects';
import { effect } from 'signal';
import { Store } from '@ngrx/store';
import { CountriesActionTypes, TopCountriesGroupedByIncomeLevelByComponentLoaded, TopCountriesGroupedByIncomeLevelByComponentRequested } from '@features/composite-indicator/state/countries/countries.actions';
import { selectCountriesGroupedByIncomeLevelByComponentId } from '@features/composite-indicator/state/countries/countries.selectors';
import { CountriesService } from '@features/composite-indicator/state/countries/countries.service';
import { concatMap, filter, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable()
export class CountriesEffects {

    topCountriesGroupedByIncomeLevelByComponentRequested$ = createEffect(() =>this.actions$
        .pipe(
            ofType<TopCountriesGroupedByIncomeLevelByComponentRequested>(CountriesActionTypes.TopCountriesGroupedByIncomeLevelByComponentRequested),
            map(request => request.payload.componentId),
            mergeMap(componentId => of(componentId)
                .pipe(
                    withLatestFrom(this.store.select(selectCountriesGroupedByIncomeLevelByComponentId(componentId))),
                    filter(([componentId, request]) => !request.length),
                    mergeMap(([componentId, request]) => this.countriesService.getCountriesGroupedByIncomeLevelByComponentId(componentId)),
                    concatMap((response) => {
                        const actions = [];
                        const countriesList = this.countriesService.prepareCountriesGroupedByIncomeLevelByComponentResponseAsCountriesList(componentId, response);

                        countriesList.map((item) => {
                            actions.push(new TopCountriesGroupedByIncomeLevelByComponentLoaded({ countriesList: item }));
                        });
                        return actions;
                    })
                )
            )
        ));

    constructor(private actions$: Actions, private countriesService: CountriesService,
                private store: Store<any>) {

    }
}
