import { Injectable } from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import { effect } from 'signal';
import { Store } from '@ngrx/store';

import {
    StatisticsActionTypes, StatisticsLoaded, StatisticsRescaledRequestedByType,
} from "@features/composite-indicator/state/statistics/statistics.actions";
import {map, filter, mergeMap, withLatestFrom} from "rxjs/operators";
import {of} from "rxjs";
import {selectStatisticsUrlIsLoaded} from "@features/composite-indicator/state/statistics/statistics.selectors";
import {StatisticsService} from "@features/composite-indicator/state/statistics/statistics.service";



@Injectable()
export class StatisticsEffects {

    statisticsRescaledRequestedByType$ = createEffect(() =>this.actions$
        .pipe(
            ofType<StatisticsRescaledRequestedByType>(StatisticsActionTypes.StatisticsRescaledRequestedByType),
            map(request => request.payload),
            map(payload => this.statisticsService.makeUrlForStatisticsRescaledRequestedByType(payload.type, payload.environment, payload.min, payload.max)),
            mergeMap(url => of(url)
                .pipe(
                    withLatestFrom(this.store.select(selectStatisticsUrlIsLoaded(url))),
                    filter(([action, loaded]) => !loaded),
                    mergeMap(() => this.statisticsService.getStatisticsByUrl(url)),
                    map(statistics => new StatisticsLoaded({statistics, url}))
                ))
        ));

    constructor(private actions$: Actions, private statisticsService: StatisticsService,
                private store: Store<any>) {}
}
