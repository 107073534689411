import { Action } from '@ngrx/store';
import { IC3UserCity } from '@features/cultural-creative-cities/shared/interfaces/c3.interfaces';
import { IScenario } from '@shared/interfaces';

export enum C3MyCityActionTypes {
    C3MyCityAddCity = '[C3 My city] Add city',
    C3MyCityUpdateCity = '[C3 My city] Update city',
    C3MyCityRemoveCity = '[C3 My city] Remove city',
    C3MyCitySetActiveCity = '[C3 My city] Set active city',
    C3MyCityAddScenario = '[C3 My city] Add scenario',
    C3MyCitySetActiveScenario = '[C3 My City] Set active scenario',
    C3MyCitySetOverallPerformanceGroupCode = '[C3 My City] Set overall performance active group code',
    C3MyCitySetOverallPerformanceComponentId = '[C3 My City] Set overall performance active component id',
    C3MyCitySetPerformanceByDimensionGroupCode = '[C3 My City] Set performance by dimension group code',
    C3MyCityAddPerformanceByDimensionActiveDimension = '[C3 My City] Add performance by dimension active dimension',
    C3MyCityRemovePerformanceByDimensionActiveDimension = '[C3 My City] Remove performance by dimension active dimension',
    C3MyCitySetLastScenarioActive = '[C3 My City] Set last scenario as active',
}

export class C3MyCityAddCity implements Action {
    readonly type = C3MyCityActionTypes.C3MyCityAddCity;
    constructor(public payload: { city: Partial<IC3UserCity>, peer_class_ids: number[] }) {}
}
export class C3MyCityUpdateCity implements Action {
    readonly type = C3MyCityActionTypes.C3MyCityUpdateCity;
    constructor(public payload: { city: Partial<IC3UserCity>, peer_class_ids: number[] }) {}
}
export class C3MyCityRemoveCity implements Action {
    readonly type = C3MyCityActionTypes.C3MyCityRemoveCity;
    constructor(public payload: {city: Partial<IC3UserCity>}) {}
}
export class C3MyCitySetActiveCity implements Action {
    readonly type = C3MyCityActionTypes.C3MyCitySetActiveCity;
    constructor(public payload: {id: number}) {}
}
export class C3MyCityAddScenario implements Action {
    readonly type = C3MyCityActionTypes.C3MyCityAddScenario;
    constructor(public payload: Partial<IScenario>) {}
}
export class C3MyCitySetActiveScenario implements Action {
    readonly type = C3MyCityActionTypes.C3MyCitySetActiveScenario;
    constructor(public payload: {scenario_id: number}) {}
}
export class C3MyCitySetOverallPerformanceGroupCode implements Action {
    readonly type = C3MyCityActionTypes.C3MyCitySetOverallPerformanceGroupCode;
    constructor(public payload: {code: string}) {}
}
export class C3MyCitySetOverallPerformanceComponentId implements Action {
    readonly type = C3MyCityActionTypes.C3MyCitySetOverallPerformanceComponentId;
    constructor(public payload: {id: number}) {}
}
export class C3MyCitySetPerformanceByDimensionGroupCode implements Action {
    readonly type = C3MyCityActionTypes.C3MyCitySetPerformanceByDimensionGroupCode;
    constructor(public payload: {code: string}) {}
}
export class C3MyCityAddPerformanceByDimensionActiveDimension implements Action {
    readonly type = C3MyCityActionTypes.C3MyCityAddPerformanceByDimensionActiveDimension;
    constructor(public payload: {id: number}) {}
}
export class C3MyCityRemovePerformanceByDimensionActiveDimension implements Action {
    readonly type = C3MyCityActionTypes.C3MyCityRemovePerformanceByDimensionActiveDimension;
    constructor(public payload: {id: number}) {}
}
export class C3MyCitySetLastScenarioActive implements Action {
    readonly type = C3MyCityActionTypes.C3MyCitySetLastScenarioActive;
}

export type C3MyCityActions = C3MyCityAddCity
    | C3MyCityUpdateCity
    | C3MyCityRemoveCity
    | C3MyCitySetActiveCity
    | C3MyCitySetActiveScenario
    | C3MyCityAddScenario
    | C3MyCitySetOverallPerformanceGroupCode
    | C3MyCitySetOverallPerformanceComponentId
    | C3MyCitySetPerformanceByDimensionGroupCode
    | C3MyCityAddPerformanceByDimensionActiveDimension
    | C3MyCityRemovePerformanceByDimensionActiveDimension
    | C3MyCitySetLastScenarioActive;
