import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { UserState } from '@eui/base';
import {
    CONFIG_TOKEN,
    EuiAppConfig,
    getAppState,
    getI18nState,
    getNotificationsState,
    getUserState,
    I18nService,
    UserService,
    UxLanguage,
    UxLink,
} from '@eui/core';
import {
    EclAppLanguageDismissEvent,
    EclMenuItemSelectEvent,
    EclSiteHeaderSearchEvent
} from '@eui/ecl';
import { Store } from '@ngrx/store';
import { AppBreadcrumbService } from 'app/app-breadcrumb.service';
import { selectRouterUrl } from 'app/core/selectors';
import { EuMimfRoutes } from '@features/eu-mimf/eu-mimf-routes';
import { selectCmsActiveContent } from '@features/general/cms/state/cms.selectors';
import { CmsService } from '@features/general/cms/state/cms.service';
import { ToolHelper } from '@features/general/tool/state/tool.helper';
import { ToolService } from '@features/general/tool/state/tool.service';
import { TranslationsService } from '@features/general/translations/translations.service';
import { GesMonitorRoutes } from '@features/ges-monitor/ges-monitor-routes';
import { SocialScoreboardRoutes } from '@features/social-scoreboard/social-scoreboard-routes';
import { BreadcrumbsResetCustomLastTitle } from '@shared/breadcrumbs/breadcrumbs.actions';
import { selectBreadcrumbCustomLastTitle } from '@shared/breadcrumbs/breadcrumbs.selectors';
import { LocalStorageKeys } from '@shared/enums';
import { BreadCrumb } from '@shared/interfaces';
import { GoogleAnalyticsService } from '@shared/services/google-analytics.service';
import { fromEvent, Observable } from 'rxjs';
import {debounceTime, delay, distinctUntilChanged, map, startWith, tap} from 'rxjs/operators';

import {ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router, Scroll} from '@angular/router';

import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { C3Routes } from '@features/cultural-creative-cities/cultural-creative-cities-routes';
import { DataHubRoutes } from '@features/data-hub/data-hub-routes';
import { Subscription } from 'rxjs';
// import { environment } from '../environments/environment';
import { AppStarterService } from './app-starter.service';
import { CustomI18nService } from './features/general/i18n/state/i18n.service';
import { AppSandboxService } from './shared/sandboxes/appSandbox';
import { MatomoService } from "@shared/services/matomo.service";
import {ViewportScroller} from "@angular/common";
import {EnvService} from "@shared/services/env.service";
import {HomeRoutes} from "@features/home/home-routes";
// declare const ga; // Google analytics
// declare const $wt; // European commission analytics plugin
// declare const _paq; // European commission analytics plugin Matomo

import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { ICmsPage } from '@features/general/cms/structure';
import {HeadSetDescription, HeadSetKeywords, HeadSetTitle} from "@features/general/head/state/head.actions";
import {EclSiteHeaderLanguageComponent} from "@eui/ecl/lib/components/ecl-site-header/language/ecl-site-header-language.component";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    HomeRoutes = HomeRoutes;
    DataHubRoutes = DataHubRoutes;
    EuMimfRoutes = EuMimfRoutes;
    GesMonitorRoutes = GesMonitorRoutes;
    C3Routes = C3Routes;
    SocialScoreboardRoutes = SocialScoreboardRoutes;
    // environment = environment;
    userState: Observable<UserState>;
    subs: Subscription[] = [];

    isLoggedIn = false;
    currentLanguage: UxLanguage = {label: 'English', code: 'en'};
    languages: UxLanguage[] = [];
    languageCodes: string[] = [];
    toolTitle: string;
    menuLinks: UxLink[] = [];
    relatedLinks: UxLink[] = [];
    breadcrumbs: BreadCrumb[] = [];
    lastUrl: string;
    lastToolId: string;
    loadingContent: boolean = true;
    showGlobanDropdown: boolean = false;

    private loadedExternalTranslationsLangs = [];
    private _resize$;
    private isMenuGenerationActive = true;

    private lastOpenedSite: string|null = null;

    protected _isBrowser: boolean = true;
    private _currentCmsPage?: ICmsPage;
    private _isFirstLoad = true;


    @ViewChild('languageSelectorContainerElement', { static: false }) languageSelectorContainerElement: any; // ElementRef
    @ViewChild('siteHeaderLanguageElement', { static: false }) siteHeaderLanguageElement: EclSiteHeaderLanguageComponent; // ElementRef

    constructor(
        public envService: EnvService,
        public translationsService: TranslationsService,
        public customI18nService: CustomI18nService,
        private store: Store<any>,
        private sb: AppSandboxService,
        // public uxAppShellService: UxAppShellService,
        private i18nService: I18nService,
        private appStarterService: AppStarterService,
        private appBreadcrumbService: AppBreadcrumbService,
        @Inject(CONFIG_TOKEN) private config: EuiAppConfig,
        private router: Router,
        private metaService: Meta,
        private cmsService: CmsService,
        private googleAnalyticsService: GoogleAnalyticsService,
        public matomoService: MatomoService,
        private toolService: ToolService,
        private viewportScroller: ViewportScroller,
        private translateService: TranslateService,
        private activeRoute: ActivatedRoute,
        @Inject(PLATFORM_ID) platformId: Object
    ) {
        // this.i18nService.init();
        this._isBrowser = isPlatformBrowser(platformId);

        // @ts-ignore
        window._isBrowser = !!this._isBrowser

        // if (this._isBrowser) {
            this._prepareSubscribes();
        // }
    }

    ngOnInit() {
        // Commented out for now until we work with users
        // this.userState = this.store.select(getUserState) as any;
        this._prepareAnalytics();

        if (this._isBrowser) {
            this._prepareFeedbackForm();
            this._setScreenSizeControl();
        }
    }

    /**
     * This is a straight way to have languages list container that we can manipulate ourselves
     */
    addLanguageListContainer() {
        if (! this.languageSelectorContainerElement) {
            setTimeout(() => this.addLanguageListContainer(), 0);
            return;
        }
        const nativeElement = this.languageSelectorContainerElement.nativeElement;
        nativeElement.classList.remove('ecl-u-d-none');
        document.querySelector('.ecl-site-header__action').prepend(nativeElement);
    }

    isEclLanguageListHidden: boolean = true;

    onLanguageClick(e) {
        e.preventDefault();

        this.isEclLanguageListHidden = false;

        return;
    }

    /**
     * @param {EclAppLanguageDismissEvent} event
     */
    onLanguageDismiss(event: EclAppLanguageDismissEvent) {
        // If we use page reload, no need for extra effort to process language change
        // const currentLocale = event.language.code;
        // this.currentLanguage = this.languages.find((lang) => lang.code === currentLocale);
        // this.translationsService.translateService.use(currentLocale);

        this.isEclLanguageListHidden = true;

        if (! event.language || this.currentLanguage.code === event.language.code) {
            return;
        }

        localStorage.setItem(LocalStorageKeys.userLang, event.language.code);
        window.location.reload();
    }

    onSearch(e: EclSiteHeaderSearchEvent) {
        window.open('https://ec.europa.eu/search/?QueryText=' + e.keyword, '_blank');
    }

    /**
     * @param {EclMenuItemSelectEvent} evt
     */
    onMenuItemSelected(evt: EclMenuItemSelectEvent) {
        const menuLink = this.findMenuLinkRecursive(this.menuLinks, evt.menuItem.id);
        if (menuLink) {
            this.router.navigate([menuLink.url]);
        }
        this.menuLinks = this.menuLinks.map((link) => {
            link.active = link.id === evt.menuItem.id ? evt.menuItem.isCurrent : false;
            return link;
        });
        return false;
    }

    onActivate(event: any) {
        this.loadingContent = false;
    }

    onDeactivate(event: any) {
        this.loadingContent = true;
    }

    private _prepareAnalytics() {
        if (this.envService.enableAnalyticsTracking) {
            this.matomoService.init();
        } else {
            // Ensure search engines to not crawl through the site if no analytics enabled
            this.metaService.updateTag({ name: 'robots', content: 'NOINDEX, NOFOLLOW' });
        }
    }

    private handleLanguageChange(locale: string, callback = () => {}): void {
        if (! this.loadedExternalTranslationsLangs.includes(locale)) {
            this.loadedExternalTranslationsLangs.push(locale);

            // Ensure translations from files
            // const translationObj = {};
            const translationObj = this.translationsService.generateTranslationsFromFilesForLocale(locale);
            this.translationsService.translateService.setTranslation(locale, translationObj);

            this.customI18nService.getExternalTranslations(locale, (result) => {
                if (result) {
                    for (const prop in result) {
                        if (! result.hasOwnProperty(prop)) {
                            continue;
                        }
                        translationObj[prop] = result[prop];
                    }

                    this.translationsService.translateService.setTranslation(locale, translationObj);
                    this._handleGeneration();

                    callback();
                }
            });
        } else {
            this._handleGeneration();

            callback();
        }
    }

    private handlePageHead() {
        let arr = [];
        arr.push(new BreadcrumbsResetCustomLastTitle());

        if (this.lastUrl) {
            if (this._currentCmsPage) {
                arr = arr.concat(this.cmsService.handleSEOFromCmsPage(this._currentCmsPage, this.lastUrl));
            } else {
                const tool = this.toolService.getToolIdentifierFromUrl(this.lastUrl);

                let titleSuffix = null;
                if (tool) {
                    titleSuffix = this.translateService.instant(tool + '.page.main');
                }

                let title = titleSuffix;

                const toolRoute = this.toolService.getToolRouteByUrl(this.lastUrl);

                const breadcrumbs = this.appBreadcrumbService.generate();

                const lastPartObj = breadcrumbs[breadcrumbs.length - 1];
                if (title !== lastPartObj.label) {
                    title = lastPartObj.label + ' | ' + title;
                }

                arr.push(new HeadSetTitle({ title }));
                arr.push(new HeadSetDescription({ description: null }));
                arr.push(new HeadSetKeywords({ keywords: null }));
            }
        }


        for (const item of arr) {
            this.store.dispatch(item);
        }
    }

    private _prepareSubscribes() {
        this.subs.push(this.appStarterService.start().subscribe(([i18nStatus, permissionStatus]) => {
            // if (i18nStatus && i18nStatus.success) {
            //     this.appStarterService.observeLanguageChangesAndUpdateUserPreferences();
            //     this.appStarterService.observeUserPrefChangesAndSavePreferences();
            // }
            this._handleGeneration();
        }));
        this.subs.push(this.translationsService.translateService.onLangChange
            .subscribe((event: LangChangeEvent) => {
                this.handleLanguageChange(event.lang);
                localStorage.setItem(LocalStorageKeys.userLang, event.lang);

            }));
        this.subs.push(this.store.select(getI18nState).subscribe((data) => {
            // TODO this might be a reason in the near future why we'll load data 2 times,
            //  because on intial load we have default language and only after X time we see what's user's browser or
            //  last selected language which we really should load
            // console.log(data);
        }));
        this.store.select(selectRouterUrl).subscribe((url) => {
            if (this.lastUrl === url) {
                return;
            }
            this.lastUrl = url;

            this._generateAvailableLanguages(() => {
                this._checkToolMaintenance(() => {
                    if (this._isFirstLoad) {
                        this.handlePageHead();
                        this._isFirstLoad = false;
                    }

                    // this.addLanguageListContainer();
                    this._handleGeneration();
                });
            });
        });
        this.store.select(selectCmsActiveContent).subscribe((data) => {
            this._currentCmsPage = data;

            this.appBreadcrumbService.setCmsPage(data);
            this._generateBreadcrumbs();
        });
        this.store.select(selectBreadcrumbCustomLastTitle).subscribe((string) => {
            this.appBreadcrumbService.setCustomLastBreadcrumb(string);
            this._generateBreadcrumbs();
        });
        this.subs.push(this.router.events.subscribe((event) => {

            this.loadingContent = true;
            if (event instanceof NavigationEnd) {
                this._generateBreadcrumbs();

                if (! this._isFirstLoad) {
                    this.handlePageHead();
                }
                this.loadingContent = false;
            }

            if (event instanceof Scroll) {
                this.loadingContent = false;

                if (! event.position && this.router.getCurrentNavigation()) {
                    event = {...event, position: this.router.getCurrentNavigation().extras?.state?.position};
                }

                if (event.position) {
                    // backward navigation
                    this.viewportScroller.scrollToPosition(event.position);
                } else if (event.anchor) {
                    // anchor navigation
                    this.viewportScroller.scrollToAnchor(event.anchor);
                } else {
                    // forward navigation
                    this.viewportScroller.scrollToPosition([0, 0]);
                }
            }
        }));
    }

    private _setScreenSizeControl() {
        this._resize$ = fromEvent(window, 'resize')
            .pipe(
                debounceTime(200),
                map(() => window.innerWidth),
                distinctUntilChanged(),
                startWith(window.innerWidth),
                tap((width) => this.sb.setWindowWidth(parseFloat(width.toString()))),
            );
        this.subs.push(this._resize$.subscribe());
    }

    private _handleGeneration(): void {
        this._generateMenuLinks();
        this._generateRelatedLinks();
        this._generateBreadcrumbs();
    }

    /**
     * @param {UxLink[]} menuLinks
     * @param {string} idToSearch
     */
    private findMenuLinkRecursive(menuLinks: UxLink[], idToSearch: string): UxLink {
        let menuLink = null;
        for (const item of menuLinks) {
            if (item.id === idToSearch) {
                menuLink = item;
                break;
            } else if (item.hasChildren) {
                menuLink = this.findMenuLinkRecursive(item.children, idToSearch);
                if (menuLink) {
                    break;
                }
            }
        }
        return menuLink;
    }

    private _generateMenuLinks() {
        // This is a fix to trigger translation of the key on every function call
        this.toolTitle = document.title;
        this.toolTitle = 'site.NAME';

        this.menuLinks = [];

        if (! this.lastUrl) {
            return;
        }

        const toolRoute = this.toolService.getToolRouteByUrl(this.lastUrl);

        // @ts-ignore
        if (toolRoute && toolRoute._loadedRoutes) {

            // @ts-ignore
            const loadedRoutes = toolRoute._loadedRoutes;

            if (loadedRoutes.length > 1) {
                let toolId = this.toolService.getToolIdentifierFromUrl(this.lastUrl);

                if (toolRoute.path === '') {
                    toolId = null;
                }
                let counter = 1;
                for (const route of loadedRoutes) {
                    if (route.data && route.data.menuItemTitle) {
                        if (! route.path.length && route.data.toolTitle) {
                            this.toolTitle = route.data.toolTitle;
                        }

                        if (! this.isMenuGenerationActive) {
                            continue;
                        }
                        const url = toolId ? '/' + toolId + '/' + route.path : route.path;

                        this.menuLinks.push(new UxLink({
                            id: counter,
                            label: this.translationsService.translateService.instant(route.data.menuItemTitle),
                            url,
                            active: this.lastUrl === url,
                        }));

                        counter++;
                    }
                }
            }
        }
    }

    /**
     * @param {function | null} callback
     * @private
     */
    private _generateAvailableLanguages(callback = () => {}): void {
        let toolId = this.toolService.getToolIdentifierFromUrl(this.lastUrl);

        // This is needed because so on the page load we wouldn't see the translation keys
        if (! toolId) {
            toolId = 'no_tool_id';
        }

        if ((! toolId || this.lastToolId === toolId) && this.lastUrl !== '/') {
            callback();
            return;
        }

        this.lastToolId = toolId;

        // Ensure tool languages to be available for selection
        const toolLanguages = this.toolService.getToolLocales(toolId);
        this.languages = this.translationsService.allLanguages.filter((lang) => toolLanguages.includes(lang.code));
        this.languageCodes = this.languages.map((item) => item.code);

        // Handle current locale
        const currentLocale = this.translationsService.getCurrentLocale();
        this.currentLanguage = this.languages.find((lang) => lang.code === currentLocale);
        // this.translationsService.translateService.setTranslation(currentLocale, translation); // Set translation from json file

        if (! localStorage.getItem(LocalStorageKeys.userLang)) {
            localStorage.setItem(LocalStorageKeys.userLang, currentLocale);
        }

        this.translationsService.translateService.setDefaultLang(currentLocale);
        this.translationsService.translateService.use(currentLocale);

        this.handleLanguageChange(currentLocale, () => callback());

        // Trigger all language codes and correct selected language on language selector
        setTimeout(() => {
            this.siteHeaderLanguageElement.languageCodes = this.languageCodes;
            this.siteHeaderLanguageElement.languageCode = this.currentLanguage.code;
            this.siteHeaderLanguageElement.ngOnInit();
        }, 1000);

    }

    private _generateRelatedLinks(): void {
        this.relatedLinks = [
           // ...
        ];
    }

    private _generateBreadcrumbs(): void {
        this.breadcrumbs = this.appBreadcrumbService.generate();
    }

    /**
     * @param {function} callback
     * @private
     */
    private _checkToolMaintenance(callback) {
        const toolId = this.toolService.getToolIdentifierFromUrl(this.lastUrl);
        const toolRoutesClass = ToolHelper.toolRouters.get(toolId);

        if (toolRoutesClass) {
            // @ts-ignore
            const maintenanceRoute = toolId + '/' + toolRoutesClass.routes.MAINTENANCE;
            // @ts-ignore
            const cmsSite = toolRoutesClass.CMS_SITE;

            if (this.lastOpenedSite !== cmsSite) {
                this.lastOpenedSite = cmsSite;

                this.cmsService.getSite(cmsSite).subscribe((data) => {
                    if (!data.length) {
                        callback();
                        return;
                    }
                    const site = data[0];

                    if (this.lastUrl !== '/' + maintenanceRoute && !site.active) {
                        this.router.navigate([maintenanceRoute]);
                        return;

                    } else if (this.lastUrl === '/' + maintenanceRoute && site.active) {
                        this.router.navigate([toolId]);
                        return;
                    }
                    this.isMenuGenerationActive = !!site.active;

                    callback();
                });

                return;
            }
        }

        callback();
    }

    private _prepareFeedbackForm(retry: number = 0) {
        const elem = document.querySelector('.dff.wt.wt-dff');

        // Timeout because this is loaded from external js and it's with defer so it takes time.
        // If not found repeat
        if (! elem) {
            if (retry < 100) {
                setTimeout(() => this._prepareFeedbackForm(retry++), 100);
            }
            return;
        }
        const elem2 = document.getElementById('feedback-form-placeholder');
        const outer = elem.parentElement;
        elem2.appendChild(elem);
        outer.remove();
    }

    // onLogin(evt: EclSiteHeaderLoginEvent) {
    //     this.isLoggedIn = true;
    //     console.log(evt);
    // }
    //
    // onLogout(evt: MouseEvent) {
    //     this.isLoggedIn = false;
    //     evt.preventDefault();
    //     console.log('logout');
    // }
}
