import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {
    ICharts2DataCategories,
    ICharts2DataCategoriesElem
} from '@features/charts2/interfaces/charts-2.data.interfaces';

import {
    CmsChartParamConversionType,
    ICmsChartParameter
} from '@features/general/charts/state/params/cms-chart-parameters.entity';
import {selectCmsChartId} from '@features/general/charts/state/main/entities/cms-chart.entity';
import {
    ICmsChart,
    ICmsChartsCategories,
    ICmsChartsData,
    ICmsChartSeries
} from '@features/general/charts/structure/charts.interfaces';
import {
    CmsChartsApiCategoriesRoutes,
    CmsChartsCategoriesSourceTypes,
    CmsChartsCategoriesTypes,
    CmsChartsLoadingState
} from '@features/general/charts/structure/cms-charts.structure';
import {CmsChartParametersHelper} from '@features/general/charts/state/params/cms-chart-parameter-helper';
import CmsChartParamsHelper from "@features/general/charts/state/params/cms-chart-params-helper";
import {CmsChartsSeriesHelper} from "@features/general/charts/state/series/cms-chart-series.entity";

export interface ICmsChartsCategoriesEntity extends ICmsChartsCategories {
    chart_id: string;
    url: string;
}

export function selectCmsChartCategoryId(category: Partial<ICmsChartsCategoriesEntity>): string {
    const type = category.type || CmsChartsCategoriesTypes.main;
    const idx = category.idx || 0;
    return category.chart_id + '_' + type + '_' + idx;
}

export const cmsChartCategoriesAdapter: EntityAdapter<ICmsChartsCategoriesEntity> =
    createEntityAdapter<ICmsChartsCategoriesEntity>({
        selectId: selectCmsChartCategoryId
    });

export interface CmsChartsCategoriesState extends EntityState<ICmsChartsCategoriesEntity> {}

export class CmsChartsCategoriesHelper {
    static categoriesConversor = (chart_id: string, categories: ICmsChartsCategories): ICmsChartsCategoriesEntity => {
        return {
            chart_id,
            name: categories.name,
            params: categories.params,
            source: categories.source,
            url: null,
            keys: categories.keys,
            type: categories.type || CmsChartsCategoriesTypes.main,
            idx: categories.idx || 0,
        };
    }

    static getIdFromChart = (chart_id: string, type = CmsChartsCategoriesTypes.main, idx = 0): string => {
        if (!type) {
            type = CmsChartsCategoriesTypes.main;
        }

        if (!idx) {
            idx = 0;
        }

        return selectCmsChartCategoryId({ chart_id, type, idx });
    }

    static chartCategoriesConversor = (chart_id: string, categories: ICmsChartsCategories): ICmsChartsCategoriesEntity => {
        return CmsChartsCategoriesHelper.categoriesConversor(chart_id, categories);
    }

    static chartMultipleCategoriesConversor = (chart_id: string, categories: ICmsChartsCategories[]): ICmsChartsCategoriesEntity[] => {
        return categories.map((category) => CmsChartsCategoriesHelper.categoriesConversor(chart_id, category));
    }

    static chartsCategoriesConversor = (charts: ICmsChart[]): ICmsChartsCategoriesEntity[] => {
        return charts.reduce((categories, chart) => {
            const cats: ICmsChartsCategories = chart?.data?.categories;
            if (cats) {
                const chart_id = selectCmsChartId(chart);
                const chart_categories = CmsChartsCategoriesHelper.chartCategoriesConversor(chart_id, cats);
                const series_categories = CmsChartsCategoriesHelper.getSeriesCategories(chart.data && chart.data.series || []);
                const converted_series_categories = CmsChartsCategoriesHelper.chartMultipleCategoriesConversor(chart_id, series_categories);
                categories.push(chart_categories);
                categories.push(...converted_series_categories);
            }
            return categories;
        }, []);
    }

    static getSeriesCategories = (series: ICmsChartSeries[]): ICmsChartsCategories[] => {
        return series.filter((s) => s.categories)
            .map((s, idx) => {
                return {
                    ...s.categories,
                    type: CmsChartsCategoriesTypes.series,
                    idx: s.order
                };
            });
    }

    static getApiCategoryRoute = (source: CmsChartsCategoriesSourceTypes, params = {}) => {
        return CmsChartsApiCategoriesRoutes.get(source);
    }

/*    static getCategoriesChartParams = (cat_params: string[], chart_params: ICmsChartParameter[], attr = 'name'): ICmsChartParameter[] => {
        return cat_params.map((param) => chart_params.find((cp) => cp.name === param)); // chart_params.filter((param)
        // => series_params.includes(param.name));
    }*/

    static validateCategoryParams = (params: ICmsChartParameter[]): boolean => {
        for (let param of params) {
            if (!param || param.value == null) {
                return false;
            }
        }

        return true;
    }

    static paramToString = (name: string, values: any): string => {
        let string_param = name + '=';
        if (Array.isArray(values)) {
            string_param += values.toString();
        } else {
            string_param += values || null;
        }

        return string_param;
    }

    static getParamsUrl = (params: ICmsChartParameter[]): string => {
        let url;
        if (CmsChartsCategoriesHelper.validateCategoryParams(params)) {
            const cms_params = CmsChartParametersHelper.convertToCmsParams(params, CmsChartParamConversionType.converted);
            url = Object.entries(cms_params).map(([name, values]) => CmsChartsCategoriesHelper.paramToString(name, values))
                .reduce((tostring, current, i) => { tostring += (i === 0 ? '' : '&') + current; return tostring; });
        }
        return url || null;
    }

    static getUrlFromParams = (categories: Partial<ICmsChartsCategoriesEntity>, chart_params: ICmsChartParameter[]): string => {
        let url = null;

        const apiCat = CmsChartsCategoriesHelper.getApiCategoryRoute(categories.source);

        if (!apiCat) {
            return url;
        }

        const category_params = categories && categories.params || [];

   //     const category_parameters = CmsChartsCategoriesHelper.getCategoriesChartParams(categories.params, chart_params);
        const relevant_category_parameters = CmsChartsSeriesHelper.getSeriesChartParams(categories.params, chart_params);

        const rules = (apiCat.rules || []);

        const base_parameters = CmsChartsSeriesHelper.getSeriesChartParams(rules, relevant_category_parameters, 'converted');

        if (!CmsChartsSeriesHelper.validateSeriesParams(base_parameters)) {
            return url;
        }
        // const params = CmsChartParamsHelper.ConvertParametersToParams(chart_params);
        const base_params = CmsChartParamsHelper.ConvertParametersToParams(base_parameters);

        const other_parameters = relevant_category_parameters.filter((param) => !rules.includes(param.converted));

//        const params_url = CmsChartsCategoriesHelper.getParamsUrl(categories_params);

        // need validation
        const base_url = apiCat.route(base_params);

        const optional_url = CmsChartsSeriesHelper.getParamsUrl(other_parameters);

        // merge urls
        if (optional_url && optional_url.length > 0) {
            const unionUrlSymbol = base_url.indexOf('?') >= 0 ? '' : '?';

            url = (optional_url && optional_url.length > 0) ? base_url + unionUrlSymbol + optional_url : base_url;
        } else {
            url = base_url;
        }
 //       console.log(chart_params, base_url, optional_url);
        return url;
    }

    static getUrlsFromParams = (cats: Partial<ICmsChartsCategoriesEntity>[], chart_params: ICmsChartParameter[]): { categories_id: string, url: string }[] => {
        return cats.map((elem) => {
            const c_params = chart_params.filter((p) => p.chart_id === elem.chart_id);
            return {
                categories_id: CmsChartsCategoriesHelper.getIdFromChart(elem.chart_id, elem.type, elem.idx),
                url: CmsChartsCategoriesHelper.getUrlFromParams(elem, c_params)
            };
        });
    }

    static convertToCategories = (categories: ICmsChartsCategoriesEntity, data: ICmsChartsData): ICharts2DataCategoriesElem => {
        return {
            keys: categories.keys,
            elements: data && data.data || [],
            name: categories.name || null,
            time_stamp: data && data.time_stamp || 0,
            status: data && data.status || CmsChartsLoadingState.initial
        };
    }

    static multipleConvertToCategories = (categories: ICmsChartsCategoriesEntity[], data: ICmsChartsData[]): ICharts2DataCategories => {
        return categories.reduce((cats, categories_elem) => {
            const categories_data = data.find((d) => d.url === categories_elem.url);
            const key = categories_elem.name || (categories_elem.type + '_' + categories_elem.idx);
            cats[key] = CmsChartsCategoriesHelper.convertToCategories(categories_elem, categories_data);
            return cats;
        }, {});
    }

    static cloneCategory = (category: ICmsChartsCategoriesEntity, newChartId: string): ICmsChartsCategoriesEntity => {
        return { ...category, chart_id: newChartId };
    }

    static cloneCategories = (categories: ICmsChartsCategoriesEntity[], newChartId: string): ICmsChartsCategoriesEntity[] => {
        return categories.map((category) => CmsChartsCategoriesHelper.cloneCategory(category, newChartId));
    }
}
