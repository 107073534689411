import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { CountriesActions, CountriesActionTypes } from '@features/composite-indicator/state/countries/countries.actions';
import { ICountriesList, ICountry } from '@shared/interfaces';

export interface CountriesState extends EntityState<ICountry> {
    countriesByIncomeLevelByComponent: ICountriesList[];
}

export const adapter: EntityAdapter<any> = createEntityAdapter();

export const initialCountriesState: CountriesState = adapter.getInitialState({
    countriesByIncomeLevelByComponent: [],
});

export function countriesReducer(state = initialCountriesState , action: CountriesActions): CountriesState {
    let arr = [];
    switch (action.type) {
    case CountriesActionTypes.TopCountriesGroupedByIncomeLevelByComponentLoaded:
        arr = [...state.countriesByIncomeLevelByComponent];
        const index = arr.findIndex((item) => {
            return item.key === action.payload.countriesList.key
                && item.ownerEntity === action.payload.countriesList.ownerEntity
                && item.ownerEntityId === action.payload.countriesList.ownerEntityId;
        });
        if (index > -1) {
            arr.splice(index, 1);
        }
        arr.push(action.payload.countriesList);
        return { ...state, countriesByIncomeLevelByComponent: arr };
    default: {
            return state;
        }
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal,

} = adapter.getSelectors();
