import {IComponent, IData, IScenarioData} from "@shared/interfaces";

export class DataHelpers
{
    /**
     * @param IData[]|IScenarioData[] data
     * @param IComponent component
     * @return IData|IScenarioData
     */
    static getComponentData(data: any[], component: IComponent): any {
        return data.find((d) => d.variable_id === component.variable_id);
    }

    static getComponentAllData(data: IData[], component: IComponent): IData[] {
        return data.filter((d) => d.variable_id === component.variable_id);
    }

    static filterDataByUnitId(data: IData[], unitIds: number[]): IData[] {
        return data.filter((d) => unitIds.indexOf(d.unit_id) >= 0);
    }

    static calcDataAverage(data: IData[], fractionDigits: number = 1): number {
        let total = data.reduce((total, dataItem) => {
            return total + dataItem.value;
        }, 0);
        return parseFloat((total / data.length).toFixed(fractionDigits));
    }

    static getDataItemByUnitId(data: IData[], id: number): IData|null {
        return data.find((d) => d.unit_id === id);
    }

    static getDataItemValueByUnitId(data: IData[], id: number, fractionDigits: number = 1, valueAttr = 'value'): number {
        let selectedCityDataItem = this.getDataItemByUnitId(data, id);
        return selectedCityDataItem ? parseFloat(selectedCityDataItem[valueAttr].toFixed(fractionDigits)) : 0;
    }
}




