import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ICategory } from '@shared/interfaces';
import { CategoriesActions, CategoriesActionTypes } from './categories.actions';

export interface CategoriesState extends EntityState<ICategory> {
    allCategoriesLoaded: boolean;
}

export const adapter: EntityAdapter<ICategory> = createEntityAdapter<ICategory>();

export const initialCategoriesState: CategoriesState = adapter.getInitialState({
    allCategoriesLoaded: false,
});

export function categoriesReducer(state = initialCategoriesState , action: CategoriesActions): CategoriesState {

    switch (action.type) {
    case CategoriesActionTypes.CategoryLoaded:
        return adapter.addOne(action.payload.category, state);
    case CategoriesActionTypes.AllCategoriesLoaded:
        return adapter.setAll(action.payload.categories, { ...state, allCategoriesLoaded: true });
    case CategoriesActionTypes.CategoryImageUpdate:
        return adapter.updateOne(action.payload.category, state);
    default: {
            return state;
        }
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal,

} = adapter.getSelectors();
