import { Action } from '@ngrx/store';
import { C3HomeStateStatus } from '@features/cultural-creative-cities/shared/constants/c3.constants';

export enum C3HomeActionTypes {
    C3HomeSetComponent = '[C3 Home] Set Home active component',
    C3HomeSetGroup = '[C3 Home] Set Home active group',
    C3HomeSetYear = '[C3 Home] Set Home active year',
    C3HomeSetRankSize = '[C3 Home] Set Home rankSize',
    C3HomeHighlightCity = '[C3 HOME] Highlight a city',
    C3HomeToggleActiveCity = '[C3 HOME] Toggle active city',
    C3HomeHighlightClass = '[C3 HOME] Highlight peer cities by group class',
    C3HomeToggleActiveClass = '[C3 HOME] Toggle the active class',
    C3HomeSetStatus = '[C3 HOME] Set home status',
    C3HomeResetStatus = '[C3 HOME] Reset status',
    C3HomeSetRelevantGroups = '[C3 HOME] Set cities grouping',
}

export class C3HomeSetComponent implements Action {
    readonly type = C3HomeActionTypes.C3HomeSetComponent;

    constructor(public payload: {id: number}) {}
}

export class C3HomeSetGroup implements Action {
    readonly type = C3HomeActionTypes.C3HomeSetGroup;

    constructor(public payload: {group: string}) {}
}

export class C3HomeSetYear implements Action {
    readonly type = C3HomeActionTypes.C3HomeSetYear;

    constructor(public payload: {year: number, data?: any}) {}
}

export class C3HomeSetRankSize implements Action {
    readonly type = C3HomeActionTypes.C3HomeSetRankSize;

    constructor(public payload: {rankSize: number}) {}
}

export class C3HomeHighlightCity implements Action {
    readonly type = C3HomeActionTypes.C3HomeHighlightCity;

    constructor(public payload: {code: string}) {}
}

export class C3HomeToggleActiveCity implements Action {
    readonly type = C3HomeActionTypes.C3HomeToggleActiveCity;

    constructor(public payload: {code: string}) {}
}

export class C3HomeHighlightClass implements Action {
    readonly type = C3HomeActionTypes.C3HomeHighlightClass;

    constructor(public payload: {id: number}) {}
}

export class C3HomeToggleActiveClass implements Action {
    readonly type = C3HomeActionTypes.C3HomeToggleActiveClass;

    constructor(public payload: {id: number}) {}
}

export class C3HomeSetStatus implements Action {
    readonly type = C3HomeActionTypes.C3HomeSetStatus;

    constructor(public payload: {status: C3HomeStateStatus}) {}
}

export class C3HomeResetStatus implements Action {
    readonly type = C3HomeActionTypes.C3HomeResetStatus;
}

export class C3HomeSetRelevantGroups implements Action {
    readonly type = C3HomeActionTypes.C3HomeSetRelevantGroups;

    constructor(public payload: {codes: string[]}) {}
}

export type C3HomeActions =
    | C3HomeSetComponent
    | C3HomeSetGroup
    | C3HomeSetYear
    | C3HomeSetRankSize
    | C3HomeHighlightCity
    | C3HomeToggleActiveCity
    | C3HomeHighlightClass
    | C3HomeToggleActiveClass
    | C3HomeSetStatus
    | C3HomeResetStatus
    | C3HomeSetRelevantGroups;
