import { Injectable } from '@angular/core';

import {Actions, createEffect, ofType} from '@ngrx/effects';
import { effect } from 'signal';
import { Store } from '@ngrx/store';
import {concatMap, filter, map, mergeMap, withLatestFrom} from 'rxjs/operators';
import {of} from "rxjs";
import {
    AllDevelopersLoaded, AllDevelopersRequested, DeveloperLoaded,
    DeveloperRequested,
    DevelopersActionTypes
} from "@features/composite-indicator/state/developers/developers.actions";
import {DevelopersService} from "@features/composite-indicator/state/developers/developers.service";
import {
    selectAllDevelopersLoaded,
    selectDeveloperById
} from "@features/composite-indicator/state/developers/developers.selectors";

@Injectable()
export class DevelopersEffects {

    developerRequested$ = createEffect(() =>this.actions$
        .pipe(
            ofType<DeveloperRequested>(DevelopersActionTypes.DeveloperRequested),
            map(request => request.payload.id),
            mergeMap(id => of(id)
                .pipe(
                    withLatestFrom(this.store.select(selectDeveloperById(id))),
                    filter(([id, request]) => !request),
                    mergeMap(([id, request]) => this.developersService.getDeveloper(id)),
                    concatMap((response) => {
                        let actions = [];
                        actions.push(new DeveloperLoaded({developer: response}));
                        return actions;
                    })
                )
            )
        ));

    loadAllCategories$ = createEffect(() =>this.actions$
        .pipe(
            ofType<AllDevelopersRequested>(DevelopersActionTypes.AllDevelopersRequested),
            withLatestFrom(this.store.select(selectAllDevelopersLoaded)),
            filter(([action, allCatLoaded]) => !allCatLoaded),
            mergeMap(() => this.developersService.loadDevelopers()),
            map((developers) => new AllDevelopersLoaded({ developers })),
        ));

    constructor(private actions$: Actions,
                private developersService: DevelopersService,
                private store: Store<any>) {

    }
}
