import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {ICharts2Series} from '@features/charts2/interfaces/charts-2.series.interface';
import {Charts2SeriesTypes} from '@features/charts2/types/charts.enums';
import {
    CmsChartParamConversionType,
    ICmsChartParameter
} from '@features/general/charts/state/params/cms-chart-parameters.entity';
import {selectCmsChartId} from '@features/general/charts/state/main/entities/cms-chart.entity';
import {
    CmsChartsApiDataRoutes,
    CmsChartsDataSourceTypes,
    CmsChartsLoadingState
} from '@features/general/charts/structure/cms-charts.structure';
import {ICmsChart, ICmsChartsData, ICmsChartSeries, ICmsDataElem} from '../../structure/charts.interfaces';
import {ArrayHelper} from '@shared/helpers/array-helper.class';
import {CmsChartParametersHelper} from '@features/general/charts/state/params/cms-chart-parameter-helper';
import CmsChartParamsHelper from '@features/general/charts/state/params/cms-chart-params-helper';

export interface ICmsChartSeriesEntity {
    chart_id: string;
    params: string[];
    optional_params?: string[];
    source: CmsChartsDataSourceTypes;
    url: string;

    name: string;
    order: number;
    type: Charts2SeriesTypes;
    values: ICmsDataElem[];
    keys: string[];
    group?: string;
    style?: {
        [_: string]: any;
    };
    isCopy?: boolean;
    other?: {
        [_: string]: any;
    };
    status: CmsChartsLoadingState
}

export function selectCmsChartSeriesId(series: Partial<ICmsChartSeriesEntity>): string {
    return series.chart_id + '_' + series.name;
}

export const cmsChartSeriesAdapter: EntityAdapter<ICmsChartSeriesEntity> =
    createEntityAdapter<ICmsChartSeriesEntity>({
        selectId: selectCmsChartSeriesId
    });

export interface CmsChartsSeriesState extends EntityState<ICmsChartSeriesEntity> {}

export class CmsChartsSeriesHelper {
    static seriesConversor = (chart_id: string, series: ICmsChartSeries): ICmsChartSeriesEntity => {
        return {
            chart_id,
            params: series.params || [],
            optional_params: series.optional_params || [],
            source: series.source,
            url: '',
            name: series.name,
            order: series.order,
            type: series.type,
            values: series.values || null,
            keys: series.keys,
            group: series.group || null,
            style: series.style || null,
            isCopy: false,
            other: series.other || {},
            status: series.status || CmsChartsLoadingState.initial
        };
    }

    static oneSeriesConversor = (chart_id: string, series: ICmsChartSeries[]): ICmsChartSeriesEntity[] => {
        return series.map((series_elem) => CmsChartsSeriesHelper.seriesConversor(chart_id, series_elem));
    }
    static chartSeriesConversor = (charts: ICmsChart[]): ICmsChartSeriesEntity[] => {
        return charts.reduce((series, chart) => {
            const chart_id = selectCmsChartId(chart);
            const chart_series = CmsChartsSeriesHelper.oneSeriesConversor(chart_id, chart.data && chart.data.series || []);
            series.push(...chart_series);
            return series;
        }, []);
    }

    static getIdFromChartAndName = (chart_id: string, name: string): string => {
        return selectCmsChartSeriesId({ chart_id, name });
    }

    static getApiDataRoute = (source: CmsChartsDataSourceTypes) => {
        return CmsChartsApiDataRoutes.get(source);
    }

    static getSeriesChartParams = (series_params: string[], chart_params: ICmsChartParameter[], attr = 'name'): ICmsChartParameter[] => {
        return chart_params.filter((param) => series_params.includes(param[attr]));
    }


    /**
     * Validate if all the mandatory params for requesting chart data are setup
     * @param params
     * @param rules
     */
    static validateChartCmsParams = (params: ICmsChartParameter[], rules?: string[]): boolean => {
        rules = rules || [];
        return rules.reduce((result, prop) => {
            const current_param = params.find((param) => param.converted === prop && param.value != null);
            return result && !!current_param;
        }, true);
    }

    static validateSeriesParams = (params: ICmsChartParameter[], rules: string[] = []): boolean => {

        if (!!rules.length) {
            return CmsChartsSeriesHelper.validateChartCmsParams(params, rules);
        }

        for (const param of params) {
            if (!param || param.value == null) {
                return false;
            }
        }

        return true;
    }

    static paramToString = (name: string, values: any): string => {
        let string_param = name + '=';
        if (Array.isArray(values)) {
            string_param += values.toString();
        } else {
            string_param += values || null;
        }

        return string_param;
    }

    /**
     * excludes the params in the rules, as they should be already integrated on the base_url
     * @param params
     */
    static getParamsUrl = (params: ICmsChartParameter[]): string => {
        let url = null;

        if (CmsChartsSeriesHelper.validateSeriesParams(params)) {
            const validparams = params.filter((param) => param.value !== null);

            const cms_params = CmsChartParametersHelper.convertToCmsParams(validparams, CmsChartParamConversionType.converted);
            url = Object.entries(cms_params)
                .map(([name, values]) => CmsChartsSeriesHelper.paramToString(name, values))
                .reduce((tostring, current, i) => { tostring += (i && '' || '&') + current; return tostring; }, '');
        }

        return url;
    }

    static getUrlFromParams = (series: Partial<ICmsChartSeriesEntity>, chart_parameters: ICmsChartParameter[]): string => {

        let url = null;

        const apiData = CmsChartsSeriesHelper.getApiDataRoute(series.source);

        if (!apiData) {
            return null;
        }

        // Filter the relevant params for the series
        const mandatory_params = series && series.params || [];
        const optional_params = series && series.optional_params || [];
        const series_params = Array.from(new Set(([...mandatory_params, ...optional_params])));

        // All the parameters relevant for the series
        const relevant_chart_parameters = CmsChartsSeriesHelper.getSeriesChartParams(series_params, chart_parameters);

        const rules = (apiData.rules || []);

        // parameters to use on the base url
        const base_parameters = CmsChartsSeriesHelper.getSeriesChartParams(rules, relevant_chart_parameters, 'converted');
   //     console.log(rules, relevant_chart_parameters, base_parameters, CmsChartsSeriesHelper.getSeriesChartParams);

        // validate if all needed base params are setup (not null)
        // all needed params are the mandatory ones
        const mandatory_chart_parameters = CmsChartsSeriesHelper.getSeriesChartParams(mandatory_params, chart_parameters);
        const mandatory_parameters = CmsChartsSeriesHelper.getSeriesChartParams(rules, mandatory_chart_parameters, 'converted');
        // if (!CmsChartsSeriesHelper.validateSeriesParams(base_parameters)) {
        if (!CmsChartsSeriesHelper.validateSeriesParams(mandatory_parameters)) {
            return null;
        }

        const base_params = CmsChartParamsHelper.ConvertParametersToParams(base_parameters);

        // parameters to use on the optional url
        const other_parameters = relevant_chart_parameters.filter((param) => !rules.includes(param.converted));

        // validate if all the series non base params are valid
        if (!CmsChartsSeriesHelper.validateSeriesParams(other_parameters)) {
            return null;
        }

  //      const other_params = CmsChartParamsHelper.ConvertParametersToParams(other_parameters);

        // base url
        const base_url = apiData.route(base_params);

        // optional url
        const optional_url = CmsChartsSeriesHelper.getParamsUrl(other_parameters);

        // merge urls
        if (optional_url && optional_url.length > 0) {
            const unionUrlSymbol = base_url.indexOf('?') >= 0 ? '&' : '?';

            url = (optional_url && optional_url.length > 0) ? base_url + unionUrlSymbol + optional_url : base_url;
        } else {
            url = base_url;
        }

//        console.log(relevant_chart_parameters, base_params, base_url);

        return url;
    }

    static getUrlsFromParams = (series: Array<Partial<ICmsChartSeriesEntity>>, chart_params: ICmsChartParameter[]): Array<{ series_id: string, url: string }> => {

        return series.map((elem) => {
            const s_params = chart_params.filter((p) => p.chart_id === elem.chart_id);
            return {
                series_id: CmsChartsSeriesHelper.getIdFromChartAndName(elem.chart_id, elem.name),
                url: CmsChartsSeriesHelper.getUrlFromParams(elem, s_params)
            };
        });
    }

    static convertToSeries = (series: ICmsChartSeriesEntity, data: ICmsChartsData): ICharts2Series => {
        return {
            name: series.name,
            order: series.order,
            type: series.type,
            style: series.style,
            values: series.values || data?.data || null,
            keys: series.keys,
            group: series.group,
            other: series.other || {},
            time_stamp: data && data.time_stamp || 0,
            status: series.status || data && data.status || CmsChartsLoadingState.initial
        };
    }

    static multipleConvertToSeries = (series: ICmsChartSeriesEntity[], data: ICmsChartsData[]): ICharts2Series[] => {
        return series.map((series_elem) => {
            const series_data = data.find((d) => d.url === series_elem.url);
   //         return !!series_data ? CmsChartsSeriesHelper.convertToSeries(series_elem, series_data) : null;
            return CmsChartsSeriesHelper.convertToSeries(series_elem, series_data);
        });
//            .filter((series_elem) => !!series_elem);
    }

    static cloneSerie = (serie: ICmsChartSeriesEntity, newChartId: string, isCopy = false): ICmsChartSeriesEntity => {
        return { ...serie, chart_id: newChartId, isCopy: serie.isCopy || isCopy };
    }

    static cloneSeries = (series: ICmsChartSeriesEntity[], newChartId: string): ICmsChartSeriesEntity[] => {
        return series.map((filter) => CmsChartsSeriesHelper.cloneSerie(filter, newChartId));
    }

    static copySeries = (series: ICmsChartSeriesEntity, param: ICmsChartParameter): ICmsChartSeriesEntity => {
        const name = series.name.replace(param.origin, param.name);
        const other = CmsChartsSeriesHelper.deepReplaceClonedSeriesParam(series.other, param);
        const params = series.params.map((sparam) => sparam === param.origin ? param.name : sparam);
        return { ...series, isCopy: true, name, params, other, status: CmsChartsLoadingState.initial, url: null };
    }

    static getSeriesWithSomeParams = (series: ICmsChartSeriesEntity[], params: string[]): ICmsChartSeriesEntity[] => {
        return series.filter((elem) => ArrayHelper.intersection([...elem.params, ...elem.optional_params], params).length > 0);
    }

    /**
     * return an unique set of series containing some of the params for each defined chart
     * @param series
     * @param args
     */
    static getSeriesWithSomeParamsMultiple = (series: ICmsChartSeriesEntity[], args: {chart_id: string, params: string[] | string}[]): ICmsChartSeriesEntity[] => {
        const unique = new Map();
        let names;
        for (const arg of args) {
            names = Array.isArray(arg.params) ? names = arg.params : names = [arg.params];

            const cSeries = series.filter((elem) =>
                elem.chart_id === arg.chart_id
                &&
                ArrayHelper.intersection([...elem.params, ...elem.optional_params], names).length > 0
            );
            for (const elem of cSeries) {
                unique.set(selectCmsChartSeriesId(elem), elem);
            }
        }
        return Array.from(unique.values());
    }

    static getSeriesByChartId = (series: ICmsChartSeriesEntity[], chart_id: string): ICmsChartSeriesEntity[] => {
        if (!series) { return series; }
        return series.filter((s) => s.chart_id === chart_id);
    }

    static getSeriesByChartIds = (series: ICmsChartSeriesEntity[], chart_ids: string[]): ICmsChartSeriesEntity[] => {
        if (!series) { return series; }
        return series.filter((s) => chart_ids.includes(s.chart_id));
    }

    static deepReplaceClonedSeriesParam = (o: { [_: string]: any }, param: ICmsChartParameter) => {
        const ro = {};

        for (const key in o) {
            if (typeof o[key] === 'object') {
                ro[key] = CmsChartsSeriesHelper.deepReplaceClonedSeriesParam(o[key], param);
            } else if (Array.isArray(o[key])) {
                ro[key] = o[key].map((item) => CmsChartsSeriesHelper.deepReplaceClonedSeriesParam(item, param));
            } else if (typeof o[key] === 'string') {
                ro[key] = o[key].replace(param.origin, param.name);
            } else {
                ro[key] = o[key];
            }
        }

        return ro;
    }
}
