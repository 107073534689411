import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {IUnit} from '@shared/interfaces';
import {UnitsActions, UnitsActionTypes} from './units.actions';

export interface UnitsState extends EntityState<IUnit> {
    typesLoaded: string[];
    urlsLoaded: string[];
}

export const adapter: EntityAdapter<IUnit> =
    createEntityAdapter<IUnit>({
        selectId: (unit) => unit.id,
    });

export const initialUnitsState: UnitsState = adapter.getInitialState({
    typesLoaded: [],
    urlsLoaded: []
});

export const addElement = <T>(array: T[], element: T): T[] => {
    return array.includes(element) ? array : [...array, element];
};

export function unitsReducer(state = initialUnitsState , action: UnitsActions): UnitsState {
    let newstate: UnitsState, typesLoaded;

    switch (action.type) {
    case UnitsActionTypes.UnitsLoaded:
        // adapter.removeAll(state);
        if (!!action.payload.type) {
            typesLoaded = [...state.typesLoaded.filter(type => type !== action.payload.type), action.payload.type];
        } else {
            typesLoaded = state.typesLoaded;
        }
        return adapter.setAll(action.payload.units, { ...state, typesLoaded: typesLoaded });
    case UnitsActionTypes.UnitsByUrlLoaded:
        return adapter.addMany(action.payload.units, { ...state, urlsLoaded: addElement<string>(state.urlsLoaded, action.payload.url) });
    case UnitsActionTypes.UnitsUpdated:
        typesLoaded = [...state.typesLoaded.filter(type => type !== action.payload.type), action.payload.type];
        return adapter.upsertMany(action.payload.units, {...state, typesLoaded: typesLoaded});
    default: {
            return state;
        }
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal,

} = adapter.getSelectors();
