import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import {ICmsChartsData} from '@features/general/charts/structure';

export function selectCmsChartDataId(data: ICmsChartsData): string {
    return data.url;
}

export const cmsChartDataAdapter: EntityAdapter<ICmsChartsData> =
    createEntityAdapter<ICmsChartsData>({
        selectId: selectCmsChartDataId
    });

export interface CmsChartsDataState extends EntityState<ICmsChartsData>{}
