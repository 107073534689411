import { Action } from '@ngrx/store';
import { IVariableGroup } from '@shared/interfaces';

export enum VariableGroupsActionTypes {
    VariableGroupsByEditionRequested = '[VARIABLEGROUPS] Requested all variable groups for an edition',
    VariableGroupsLoaded = '[VARIABLEGROUPS] Variable Groups loaded',
}

export class VariableGroupsByEditionRequested implements Action {
    readonly type = VariableGroupsActionTypes.VariableGroupsByEditionRequested;
}

export class VariableGroupsLoaded implements Action {
    readonly type = VariableGroupsActionTypes.VariableGroupsLoaded;

    constructor(public payload: { variableGroups: IVariableGroup[] }) {}
}

export type VariableGroupsActions =
    VariableGroupsByEditionRequested
    | VariableGroupsLoaded;
