import {Action} from '@ngrx/store';
import {IGenericCObject} from '@features/charts2/interfaces';

export enum CmsChartsTooltipActionTypes {
    CmsChartsSetTooltip = '[CmsChartsTooltip] Set a tooltip',
    CmsChartsTooltipDataLoaded = '[CmsChartsTooltip] Tooltip data loaded'
}

export class CmsChartsSetTooltip implements Action {
    readonly type = CmsChartsTooltipActionTypes.CmsChartsSetTooltip;
    constructor(public payload: {chart_id: string, chart_type: string, params: IGenericCObject<any>}) {}
}

export class CmsChartsTooltipDataLoaded implements Action {
    readonly type = CmsChartsTooltipActionTypes.CmsChartsTooltipDataLoaded;
    constructor(public payload: {url: string, data: any}) {}
}

export type CmsChartsTooltipActions = CmsChartsSetTooltip
    | CmsChartsTooltipDataLoaded;
