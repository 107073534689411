import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { GeneralService } from '@features/general/general.service';
import { TranslationsService } from '@features/general/translations/translations.service';
import {EnvService} from "@shared/services/env.service";

@Injectable()
export class HeadService extends GeneralService {

    constructor(
        protected envService: EnvService,
        protected http: HttpClient,
        protected translationsService: TranslationsService,
        @Inject(DOCUMENT) private dom
    ) {
        super(envService, http, translationsService);
    }

    createLinkForCanonicalURL(url?: string) {
        let elements = this.dom.querySelectorAll('link[rel="canonical"]');
        if (elements.length) {
            for (let prop in elements) {
                if (!elements.hasOwnProperty(prop)) {
                    continue;
                }
                elements[prop].remove();
            }
        }
        let link: HTMLLinkElement = this.dom.createElement('link');
        link.setAttribute('rel', 'canonical');
        link.setAttribute('href', url ? url : this.dom.URL);
        this.dom.head.appendChild(link);
    }
}
