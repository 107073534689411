import {Action} from '@features/charts/models/charts.interfaces';
import {AsyncRequestStatus, IAsyncRequest} from '@features/general/async_requests/async-requests.entity';

export enum AsyncRequestsActionTypes {
    ARSetRequest = '[ASYNC REQUESTS] set new request',
    ARSetRequestItems = '[ASYNC REQUESTS] Set items for request',
    ARSetStatus = '[ASYNC REQUESTS] Set status of request',
    ARRemoveAll = '[ASYNC REQUESTS] Remove all requests',
    ARRemoveOne = '[ASYNC REQUESTS] Remove one request',
    ARSetItem = '[ASYNC REQUESTS] Set an item with result',
    ARRequestComplete = '[ASYNC REQUESTS] A request is complete',
    ARSetRequestWithItems = '[ASYNC REQUESTS] Set a request with items included'
}

export class ARSetRequest implements Action {
    readonly type = AsyncRequestsActionTypes.ARSetRequest;
    constructor (public payload: IAsyncRequest) {}
}

export class ARSetRequestItems implements Action {
    readonly type = AsyncRequestsActionTypes.ARSetRequestItems;
    constructor (public payload: { request_id: string, items: any[] }) {}
}

export class ARSetStatus implements Action {
    readonly type = AsyncRequestsActionTypes.ARSetStatus;
    constructor (public payload: { request_id: string, status: AsyncRequestStatus }) {}
}

export class ARRemoveAll implements Action {
    readonly type = AsyncRequestsActionTypes.ARRemoveAll;
}

export class ARRemoveOne implements Action {
    readonly type = AsyncRequestsActionTypes.ARRemoveOne;
    constructor (public payload: { id: string }) {}
}

export class ARSetItem implements Action {
    readonly type = AsyncRequestsActionTypes.ARSetItem;
    constructor (public payload: { request_id, item_id: string, result: any }) {}
}

export class ARRequestComplete implements Action {
    readonly type = AsyncRequestsActionTypes.ARRequestComplete;
    constructor (public payload: { id: string }) {}
}

export class ARSetRequestWithItems implements Action {
    readonly type = AsyncRequestsActionTypes.ARSetRequestWithItems;
    constructor (public payload: { request_id: string, item_ids: string[] }) {}
}


export type AsyncRequestsActions = ARSetRequest
    | ARSetRequestItems
    | ARSetStatus
    | ARRemoveAll
    | ARRemoveOne
    | ARSetItem
    | ARRequestComplete
    | ARSetRequestWithItems;
