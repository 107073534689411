import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { filter, map, take } from 'rxjs/operators';
import { AppState } from 'app/core/reducers';
import { selectCoinLastEditionIsLoaded } from '@features/composite-indicator/state/status/composite-indicators.selectors';
import { Observable, of } from 'rxjs';
import { CoinLastEditionRequested } from '@features/composite-indicator/state/status/composite-indicators.actions';
import { CoinStatus } from '@features/composite-indicator/state/status/composite-indicators.reducer';
import {
    C3AllDataYearsRequested,
    C3HomeSetYear,
    C3SetLastEdition
} from '@features/cultural-creative-cities/state/actions';

@Injectable({
    providedIn: 'root',
})
export class CulturalCreativeCitiesGuard implements CanActivateChild {

    // private indice = 'C3';
    private indice = 'C3M';
    private year = 2019;

    constructor(private store: Store<AppState>) {}

    getFromStoreOrAPI(): Observable<any> {
        try {
            let param = window.location.href.split('?')[1];
            if (param) {
                this.year = parseInt(param.split('=')[1]);
            }

            return this.store.pipe(
                select(selectCoinLastEditionIsLoaded(this.indice)),
                map((loaded: boolean) => {
                    if (!loaded) {
                        this.store.dispatch(new C3SetLastEdition({ indice: this.indice }));
                        this.store.dispatch(new C3HomeSetYear({year: this.year, data: { indice: this.indice, allData: CoinStatus.REQUESTED, dataYear: this.year }}));
                        // this.store.dispatch(new CoinLastEditionRequested({ indice: this.indice, allData: CoinStatus.REQUESTED, dataYear: this.year }));
                    }
                    return loaded;
                }),
                filter((loaded: any) => !!loaded),
                take(1)
            );
        } catch (e) {
            return of(false);
        }
    }

    canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.getFromStoreOrAPI();
    }
}
