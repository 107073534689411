import { Injectable } from '@angular/core';
import { IResource } from '@shared/interfaces';
import { SharedService } from '@shared/shared.service';
import { Observable } from 'rxjs';

@Injectable()
export class ResourcesService extends SharedService {

    getResourcesByUrl(url: string): Observable<IResource[]> {
        return this.getByUrl<IResource[]>(url);
    }

}
