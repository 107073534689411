import { AfterViewInit, Component, ElementRef, Input, ViewEncapsulation } from '@angular/core';
import { BaseComponent } from '@shared/base-classes/base-component';
import { Subject } from 'rxjs';
import { MatSliderChange } from '@angular/material/slider';

@Component({
  selector: 'app-mat-slider',
  templateUrl: './mat-slider.component.html',
  styleUrls: ['./mat-slider.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MatSliderComponent extends BaseComponent<any, any, any> implements AfterViewInit {

    @Input() reset = new Subject();

    @Input() disabled = false;

    newValue: number;

    constructor(protected element: ElementRef) {
      super();
    }

    ngOnInit() {
      const sub = this.reset.subscribe(() => this.resetSlider());
      this.subscribe(sub);
    }

    ngAfterViewInit(): void {
        this.checkFormat();
    }

    configUpdate() {
  //      this.checkColor();
    }

    dataUpdate() {
    }

    initConfig() {
        this.initialConfig = {
            thumbLabel: true,
            handle: {
                color: '#004494',
            },
            label: {
                color: '#fff',
                size: '10px'
            }
        };

        return { ...this.initialConfig };
    }

    resetSlider() {

    }

    checkFormat(): void {
        let color;
        if (this.disabled === false) {
            color = this.data.node.color;
        } else {
            color = null;
        }

        this.element.nativeElement
            .getElementsByClassName('mat-slider-thumb')[0]
            .style.backgroundColor = color;

        this.element.nativeElement
            .getElementsByClassName('mat-slider-thumb-label')[0]
            .style.backgroundColor = color;

        this.element.nativeElement
            .getElementsByClassName('mat-slider-thumb-label-text')[0]
            .style.color = this.config.label.color;

        this.element.nativeElement
            .getElementsByClassName('mat-slider-thumb-label-text')[0]
            .style.fontSize = this.config.label.size;

        this.element.nativeElement
            .getElementsByClassName('mat-slider-track-fill')[0]
            .style.backgroundColor = color;
    }

    formatLabel = (value) => Math.round(100 * value) + '%';

    sliderChange(event: MatSliderChange|any) {
        this.newValue = event.value;
    }

    changeValue() {
        const weight = {
            id: this.data.weight.id,
            weight: this.newValue
        };

        this.changed(weight);
    }

    mouseDown(event) {}

}
