/*export const environment = {
    configFilePath: 'assets/openid-login-config.json',
    production: false,
    enableDevToolRedux: true,
    // apiUrl: 'http://api.coin.it/api/v1/en/',
    // cmsApiUrl: 'http://cms.coin.it/api/v1/en/cms',
    apiUrl: 'http://api.coin-dev.eu/api/v1/en/',
    cmsApiUrl: 'http://cms.coin-dev.eu/api/v1/en/cms',
    cmsStorageUrl: 'http://cms.coin-dev.eu/storage',
    enableAnalyticsTracking: false,
    matomoUrl: null,
    matomoSiteId: null,
};*/

export const environment = {
    title: 'local demo',
    showEnvTitleOnLogo: true,
    configFilePath: 'assets/openid-login-config.json',
    production: false,
    enableDevToolRedux: true,
    // apiUrl: 'http://api.coin.it/api/v1/en/',
    // cmsApiUrl: 'http://cms.coin.it/api/v1/en/cms',
    // apiUrl: 'http://api.coin-dev.eu/api/v1/{{locale}}/',
    apiUrl: 'https://demo.api.coin-dev.eu/api/v1/{{locale}}/',
    cmsApiUrl: 'https://demo.api.coin-dev.eu/api/v1/{{locale}}/cms',
    i18nUrl: 'https://demo.api.coin-dev.eu/api/v1/{{locale}}/cms/i18n',
    backendServicesStorage: 'https://demo.coin-backend-services.coin-dev.eu/storage/',
    backendServicesApi: 'https://demo.coin-backend-services.coin-dev.eu/services/dissemination/api/',
    cmsStorageUrl: 'https://demo.cms.coin-dev.eu/storage',
    enableAnalyticsTracking: false,
    matomoUrl: null,
    matomoSiteId: null,
    // matomoUrl: 'https://deverang.matomo.cloud/',
    // matomoSiteId: 1,
    // matomoUrl: 'https://stats.jrc.ec.europa.eu/',
    // matomoSiteId: 19,
    // matomoUrl: 'https://webanalytics.ec.europa.eu/',
    // matomoSiteId: 19,
    enableGoogleAnalyticsTracking: false,
    googleAnalyticsSiteId: null,
};
