import { Action } from '@ngrx/store';
import { ICmsChartSeriesEntity } from '@features/general/charts/state/series/cms-chart-series.entity';
import { ICmsChartParameter } from '@features/general/charts/state/params/cms-chart-parameters.entity';

export enum CmsChartsSeriesActionTypes {
    CmsChartsSeriesSetUrls = '[CmsCharts series] Set urls for multiple series',
    CmsChartsSeriesCopyWithNewParam = '[CmsCharts series] Copy a series with a new param',
    CmsChartsAddNewCopiedSeriesAndParams = '[CmsCharts series] Copy multiple series and params',
    CmsChartsSeriesRemoveCopied = '[CmsCharts series] Remove a copied series',
}

export class CmsChartsSeriesSetUrls implements Action {
    readonly type = CmsChartsSeriesActionTypes.CmsChartsSeriesSetUrls;
    constructor (public payload: { id: string, changes: { url: string } }[]) {}
}

export class CmsChartsSeriesCopyWithNewParam implements Action {
    readonly type = CmsChartsSeriesActionTypes.CmsChartsSeriesCopyWithNewParam;
    constructor (public payload: { series: ICmsChartSeriesEntity[], param: ICmsChartParameter, param_name: string, param_value: any }) {}
}

export class CmsChartsAddNewCopiedSeriesAndParams implements Action {
    readonly type = CmsChartsSeriesActionTypes.CmsChartsAddNewCopiedSeriesAndParams;
    constructor (public payload: { series: ICmsChartSeriesEntity[], params: ICmsChartParameter[]}) {}
}

export class CmsChartsSeriesRemoveCopied implements Action {
    readonly type = CmsChartsSeriesActionTypes.CmsChartsSeriesRemoveCopied;
    constructor (public payload: { chart_id: string, name: string }) {}
}

export type CmsChartsSeriesActions = CmsChartsSeriesSetUrls
    | CmsChartsSeriesCopyWithNewParam
    | CmsChartsSeriesRemoveCopied
    | CmsChartsAddNewCopiedSeriesAndParams;
