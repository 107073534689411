import {CmsChartsSetMultipleParamRequest,} from '@features/general/charts/state/params/cms-charts-params.actions';
import {CmsChartParametersHelper} from '@features/general/charts/state/params/cms-chart-parameter-helper';
import {CmsChartsSetParamsStrategy, ICmsChart, ICmsParams} from '@features/general/charts/structure';
import {ICmsChartParameter} from '@features/general/charts/state/params/cms-chart-parameters.entity';
import {ArrayHelper} from "@shared/helpers";

class CmsChartParamsHelper {

    static ConvertMultipleRequestToSetActiveValues = (actions: typeof CmsChartsSetMultipleParamRequest.prototype.payload, strategy = CmsChartsSetParamsStrategy.default) => {
        const id = (arg: { chart_id: string, name: string }) => arg.chart_id + '__' + arg.name;
        const new_actions_map = new Map();
        let new_actions;

        for (const action of actions) {
            const action_id = id(action);
            let values;
            if (new_actions_map.has(action_id)) {
                values = new_actions_map.get(action_id).values;
            } else {
                values = [];
            }
            new_actions_map.set(action_id, { chart_id: action.chart_id, name: action.name, values: [...values, action.value], strategy });
        }

        new_actions = Array.from(new_actions_map.values())
            .map((action) => {
                action.values = Array.from(new Set(action.values));
                return action;
            });
        return new_actions;
    }

    static ConvertParamToCopyAction = (param, strategy: CmsChartsSetParamsStrategy, new_value?: any, temporaryIgnore = false) => {
        let name: string;

        switch (strategy) {
            case CmsChartsSetParamsStrategy.add:
                name = CmsChartParametersHelper.calcCopyParamName(param);
                // CmsChartsAddCopyParam payload
                return { param, name, value: new_value };
            case CmsChartsSetParamsStrategy.remove:
                // CmsChartsRemoveCopyParam payload
                return { chart_id: param.chart_id, name: param.name };
            case CmsChartsSetParamsStrategy.set:
                // return new CmsChartsSetParams([{ chart_id: param.chart_id, name: param.name, value: param.value }]);
                return { chart_id: param.chart_id, name: param.name, value: new_value, temporaryIgnore };
        }
    }

    static CreateAddRemoveCopyActions = (params: ICmsChartParameter[], values: any[], strategy = CmsChartsSetParamsStrategy.default) => {
        const old_values = ArrayHelper.uniquePluck(params, 'value'); // Array.from(new Set(params.map((param) => param.value)));
        const new_values = ArrayHelper.unique(values); // Array.from(new Set(values));

        const original_param = params.find((param) => param.name === param.origin);
 //       console.log(params, values, strategy);
        let remove_params = [];
        let add_params = [];
        const setParams = [];

        switch (strategy) {
            case CmsChartsSetParamsStrategy.nodeps:
                [remove_params, add_params] = [[], []];
                break;
            case CmsChartsSetParamsStrategy.toggle:
                [remove_params, add_params] = CmsChartParamsHelper.CreateAddRemoveCopyActionsToggleStrategy(params, old_values, new_values);
                break;
            default:
                [remove_params, add_params] = CmsChartParamsHelper.CreateAddRemoveCopyActionsDefaultStrategy(params, old_values, new_values);
                break;
        }

        let originIsAvailable = original_param.value === null;

        const remove = remove_params
            .map((param) => {
                const isOrigin = param.name === param.origin;
                let p_strategy: CmsChartsSetParamsStrategy;
                let updated_param;

                if (isOrigin) {
   //                 console.log(param);
                    if (add_params.length > 0) {
                        originIsAvailable = true;
                        return null;
                    } else {
                        p_strategy = CmsChartsSetParamsStrategy.set;
//                        updated_param = { ...param, value: null };
                        setParams.push(CmsChartParamsHelper.ConvertParamToCopyAction({ ...original_param }, p_strategy, null, true));
                        return null;
                    }
                } else {
                    p_strategy = CmsChartsSetParamsStrategy.remove;
                    updated_param = param;
                }
                //console.log(updated_param);
                return CmsChartParamsHelper.ConvertParamToCopyAction(updated_param, p_strategy);
            })
            .filter((p) => !!p);
     //   console.log(originIsAvailable, remove_params, add_params, setParams);
        const add = add_params
            .map((value) => {
                let strategy;
                if (originIsAvailable) {
                    strategy = CmsChartsSetParamsStrategy.set;
                    originIsAvailable = false;
                    setParams.push(CmsChartParamsHelper.ConvertParamToCopyAction({ ...original_param }, strategy, value));
                    return null;
                } else {
                    strategy = CmsChartsSetParamsStrategy.add;
                }
                return CmsChartParamsHelper.ConvertParamToCopyAction({ ...original_param }, strategy, value);
            })
            .filter((p) => !!p);

        return {
            remove,
            add,
            setParams
        };
    }

    static CreateAddRemoveCopyActionsDefaultStrategy = (params: ICmsChartParameter[], old_values, new_values) => {
 //       console.log(params, old_values, new_values);
        const remove = params.filter((param) => !new_values.includes(param.value) && param.value !== null);

        const add = new_values.filter((value) => !old_values.includes(value) && value !== null);

        return [remove, add];
    }

    static CreateAddRemoveCopyActionsToggleStrategy = (params: ICmsChartParameter[], old_values, new_values) => {
        const remove = params.filter((param) => new_values.includes(param.value) && param.value !== null);

        const add = new_values.filter((value) => !old_values.includes(value) && value !== null);

        return [remove, add];
    }

    static ConvertParametersToParams = (params: ICmsChartParameter[]): ICmsParams => {
        const noNullParams = params.filter((param) => param.value != null);
        const cmsParams = {};

        noNullParams.map((param) => {
            if (cmsParams.hasOwnProperty(param.converted)) {
                cmsParams[param.converted] += ',' + param.value;
            } else {
                cmsParams[param.converted] = param.value;
            }
        });

        return cmsParams;
    }

    /**
     * Used in chart effects to convert an array of params into one with a unique chart_id
     * @param payload
     * @constructor
     */
    static ConvertSetParamsToParamsByChart = (payload: { chart_id: string, name: string, value: any }[]): { chart_id: string, params: { name: string, value: any }[] }[] => {
        return payload.reduce((result, elem) => {
            const current = result.find((d) => d.chart_id === elem.chart_id);
            if (current) {
                current.params.push({ name: elem.name, value: elem.value });
            } else {
                result.push({ chart_id: elem.chart_id, params: [{ name: elem.name, value: elem.value }] });
            }
            return result;
        }, []);
    }

    static ValidateRules = (rules: string[], paramsObject: any): boolean => {
        if (!paramsObject) {
            paramsObject = {};
        }
        return (rules || []).reduce((result, current) => current && paramsObject.hasOwnProperty(current) && paramsObject[current] != null, true);
    }

    static GetPageParamsFromChart = (chart: ICmsChart) => {
        return chart?.params?.page || { mock: null };
    }
}

export default CmsChartParamsHelper;
