import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ICategoryGroup } from '@shared/interfaces';
import { CategoryGroupsActions, CategoryGroupsActionTypes } from './category-groups.actions';

export interface CategoryGroupsState extends EntityState<ICategoryGroup> {
    allCategoryGroupsLoaded: boolean;
}

export const adapter: EntityAdapter<ICategoryGroup> =
    createEntityAdapter<ICategoryGroup>();

export const initialCategoriesState: CategoryGroupsState = adapter.getInitialState({
    allCategoryGroupsLoaded: false,
});

export function categoryGroupsReducer(state = initialCategoriesState , action: CategoryGroupsActions): CategoryGroupsState {

    switch (action.type) {
    case CategoryGroupsActionTypes.CategoryGroupLoaded:
        return adapter.addOne(action.payload.group, state);
    case CategoryGroupsActionTypes.AllCategoryGroupsLoaded:
        return adapter.setAll(action.payload.groups, { ...state, allCategoryGroupsLoaded: true });
    case CategoryGroupsActionTypes.CategoryGroupUpdate:
        return adapter.updateOne(action.payload.group, state);
    default: {
            return state;
        }
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal,

} = adapter.getSelectors();
