import { Injectable } from '@angular/core';
import { ApiBaseRoutes } from '@features/composite-indicator/shared/api_routes/api-base.routes';

export enum LastEditionUrls {
    STRUCTURE = 'structure',
    MAINSTRUCTURE = 'main-structure',
    MAINDATA = 'main-data',
    UNITS = 'units',
    EDITIONUNITS = 'edition-units'
}

@Injectable()
export class LastEditionRoutes extends ApiBaseRoutes {

    static urls = LastEditionUrls;

    generateUrl(indexCode: string, options: LastEditionUrls): string {
        return this.baseGenerate(this.optionsUrl(indexCode, options));
    }

    private optionsUrl = (code: string, options: LastEditionUrls): string => `indices/${code}/last-edition/${options}`;
}
