import {Injectable} from '@angular/core';
import {ICharts2Data} from '@features/charts2/interfaces';
import {
    CmsChartsCategoriesDefaultIds,
    CmsChartsLoadingState,
    ICmsChartsData
} from '@features/general/charts/structure';
import {GeneralService} from '@features/general/general.service';
// import {applyPlugin} from 'jspdf-autotable/dist/jspdf.plugin.autotable.min.js';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import * as JsPDF from 'src/assets/jspdf.min'; // TODO Using manual 2.1.1 version, because npm has only 1.5.3 so update this when newer version will be available
import * as svg2pdf from 'svg2pdf.js';
import * as XLSX from 'xlsx/dist/xlsx.full.min.js';
import {Charts2SeriesTypes} from '@features/charts2/types';
import {ContentHelpers} from '@shared/helpers';
import {FileHelpers} from '@shared/helpers/file.helpers';
import {IEclChartDataTable, IEclChartDataTableConfig, IPDFTable} from '@shared/interfaces';
import {PDFService} from '../../pdf/pdf.service';

// applyPlugin(JsPDF);

@Injectable()
export class CmsChartsService extends GeneralService {

    private getDataLoadingState = (data): CmsChartsLoadingState => {
        if (Array.isArray(data)) {
            return data.length > 0 ? CmsChartsLoadingState.loaded : CmsChartsLoadingState.nodata;
        } else {
            return data && Object.values(data).length > 0 ? CmsChartsLoadingState.loaded : CmsChartsLoadingState.nodata;
        }

        return CmsChartsLoadingState.loaded;
    }

    private chartTypesToIgnore = [
        Charts2SeriesTypes.line,
        Charts2SeriesTypes.horizontal,
    ];

    /**
     * END: Chart to table conversion
     */

    /**
     * BEGIN: Download
     */

    private chart = {
        object: null as any,
        htmlElement: null as HTMLElement,
        imageBase64: null as string,
        downloadType: null as string,
    };

    getChartDataByUrl = (params_url: string): Observable<ICmsChartsData> => {
        let full_url = this.getApiUrl();
        if (params_url.substr(0, 1) === '/') {
            full_url += params_url.substr(1);
        } else {
            full_url += params_url;
        }

        return this.getByUrl<Array<string | number>[]>(full_url)
            .pipe(
                map((data) => {
                    return { url: params_url, data, status: this.getDataLoadingState(data), time_stamp: Date.now() };
                })
            );
    }

    /**
     * BEGIN: Chart to table conversion
     */
    generateTable(data: ICharts2Data, config: Partial<IEclChartDataTableConfig>): IEclChartDataTable {
        const categories = data && data.categories && data.categories[CmsChartsCategoriesDefaultIds.primary];
        let preparedData = {
            title: null,
            thead: [],
            tbody: [],
        } as IEclChartDataTable;

        let colValues = [];
        for (let i = 0; i < data.series.length; i++) {
            let seriesItem = data.series[i];
            if (this.chartTypesToIgnore.includes(seriesItem.type)) {
                continue;
            }
            if (!preparedData.thead.length) {
                // Prepare first column
                preparedData.thead.push(ContentHelpers.capitalize(seriesItem.keys[0]));
                colValues.push(seriesItem.values.map((valuesItem) => {
                    let value = null;

                    // @ts-ignore
                    let category = categories.elements.find((cat) => cat[seriesItem.keys[0]] === valuesItem[0]);
                    if (category) {
                        value = category.name;
                    }

                    switch (seriesItem.type) {
                    case Charts2SeriesTypes.scatter:
                        value = value === null ? valuesItem[0] : null;
                        break;

                    default:
                        break;
                    }

                    return value;
                }));
            }
            preparedData.thead.push(this.handleSeriesName(seriesItem.name, config));
            colValues.push(seriesItem.values.map((valuesItem) => this.handleTableCellValue(valuesItem[1])));
        }
        // Convert column values to rows
        if (colValues.length) {
            for (let i = 0; i < colValues[0].length; i++) {
                let arr = [];
                for (let k = 0; k < colValues.length; k++) {
                    arr.push(colValues[k][i]);
                }
                preparedData.tbody.push(arr);
            }
        }
        return preparedData;
    }

    handleTableCellValue(value: any) {
        if (typeof value === 'number') {
            value = value.toFixed(2);
        }
        return value;
    }

    handleSeriesName(name: string, config: Partial<IEclChartDataTableConfig>): string {
        return ContentHelpers.extractAndReplaceVariablesInString(name, (props: string[]) => {
            // Extract variable value by props
            let value = null;
            let filter = config.filters ? config.filters.find((item) => item.param === props[0]) : null;
            if (filter) {
                value = filter[props[1]];
            }
            return value;
        });
    }

    handlePDFWithChartAsSVGDownload(chart, element: HTMLElement): void {
        this.chart.object = chart;
        this.chart.htmlElement = element;
        this.chart.downloadType = 'pdf_svg';
        this.generatePDF();
    }

    handlePDFWithChartAsPNGDownload(chart, base64: string): void {
        this.chart.object = chart;
        this.chart.imageBase64 = base64;
        this.chart.downloadType = 'pdf_png';
        this.generatePDF();
    }

    convertChartDataTableToPDFTable(chartDataTable: IEclChartDataTable): IPDFTable {
        let columns = chartDataTable.thead.map((item, index) => {
            return {
                title: item,
                dataKey: index.toString(),
            };
        });
        let rows = chartDataTable.tbody.map((row, index) => {
            let obj = {};
            for (let i = 0; i < row.length; i++) {
                obj[i] = row[i];
            }
            return obj;
        });
        return { columns, rows };
    }

    handleDataInXLSXDownload(chart) {
        let wb = XLSX.utils.book_new();
        wb.Props = {
            Title: this.getFileName(chart),
            CreatedDate: new Date()
        };
        let sheetName = 'Sheet';
        wb.SheetNames.push(sheetName);

        let table = this.generateTable(chart.data, { filters: chart.filters });
        let sheetData = [table.thead.map((item) => item)].concat(table.tbody);
        wb.Sheets[sheetName] = XLSX.utils.aoa_to_sheet(sheetData);

        FileHelpers.triggerBlobBasedFileDownload(
            new Blob([FileHelpers.stringToArrayBuffer(XLSX.write(wb, { bookType: 'xlsx', type: 'binary' }))], { type: 'application/octet-stream' }),
            this.getFileName(chart),
            'xlsx'
        );
    }

    handleDataInCSVDownload(chart): void {
        let table = this.generateTable(chart.data, { filters: chart.filters });
        let csvContent = table.thead.join(';') + '\n';
        csvContent += table.tbody.map((e) => e.join(';')).join('\n');
        FileHelpers.triggerStringBasedFileDownload(csvContent, this.getFileName(chart), 'csv');
    }

    handleDataInJSONDownload(chart): void {
        let table = this.generateTable(chart.data, { filters: chart.filters });
        let arr = [];
        for (let i = 0; i < table.tbody.length; i++) {
            let obj = {};
            for (let k = 0; k < table.thead.length; k++) {
                let value: string | number = table.tbody[i][k];
                if (value.length === parseFloat(value).toString().length) {
                    value = parseFloat(value);
                }
                obj[table.thead[k].toString().replace(' ', '_').toLowerCase()] = value;
            }
            arr.push(obj);
        }
        FileHelpers.triggerStringBasedFileDownload(JSON.stringify(arr), this.getFileName(chart), 'json');
    }

    private getFileName(chart) {
        return chart.data.title + (chart.data.sub_title ? '' + chart.data.sub_title : '');
    }

    private generatePDF(): void {
        let doc = new JsPDF({
            orientation: 'l',
            format: 'a4'
        });
        doc.text(this.chart.object.data.title, 10, 10);

        switch (this.chart.downloadType) {
        case 'pdf_svg':
            doc.advancedAPI((doc1) => {
                // svg2pdf(this.chart.htmlElement, doc1, { xOffset: 10, yOffset: 50, scale: 0.25 });
            });
            break;
        case 'pdf_png':
            doc.addImage(this.chart.imageBase64, 'PNG', 10, 10, 280, 80);
            break;
        }

        // Generate table
        doc.addPage('a4', 'p');
        let pdfTable = this.convertChartDataTableToPDFTable(this.generateTable(this.chart.object.data, { filters: this.chart.object.filters }));
        doc.compatAPI((doc1) => {
            doc1.autoTable(pdfTable.columns, pdfTable.rows, {
                styles: {
                    fillColor: [51, 51, 51],
                    lineColor: 240,
                    lineWidth: 1,
                },
                columnStyles: PDFService.generatePDFColumnStyles(pdfTable),
                margin: { top: 10 },
            });
        });

        doc.save(this.chart.object.data.title + '.pdf');
    }
    /**
     * END: Download
     */
}
