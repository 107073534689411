import { HeadActionTypes } from '@features/general/head/state/head.actions';
import { HeadActions } from '@features/general/head/state/head.actions';

export interface HeadState {
    title: string;
    description: string;
    keywords: string;
}

export const initialHeadReducerState = {
    title: null,
    description: null,
    keywords: null,
};

export function headReducer(state = initialHeadReducerState, action: HeadActions): HeadState {
    switch (action.type) {
        case HeadActionTypes.HeadSetTitle:
            return { ...state, title: action.payload.title };
        case HeadActionTypes.HeadSetDescription:
            return { ...state, description: action.payload.description };
        case HeadActionTypes.HeadSetKeywords:
            return { ...state, keywords: action.payload.keywords };
        default:
            return state;
    }
}
