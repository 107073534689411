export enum Charts2AxisTypes {
    left = 'left',
    right = 'right',
    top = 'top',
    bottom = 'bottom'
}

export enum Charts2VerticalPositions {
    top = 'top',
    topMargin = 'top-margin',
    topMiddleMargin = 'top-middle-margin',
    topMiddle = 'top-middle',
    middle = 'middle',
    bottomMiddle = 'bottom-middle',
    bottomMiddleMargin = 'bottom-middle-margin',
    bottomMargin = 'bottom-margin',
    bottom = 'bottom'
}

export enum Charts2HorizontalPositions {
    left = 'left',
    leftMargin = 'left-margin',
    leftMiddle = 'left middle',
    middle = 'middle',
    rightMiddle = 'right-middle',
    rightMargin = 'right-margin',
    right = 'right'
}

export enum Charts2Types {
    range = 'range',
    column = 'column',
    heatmap = 'heatmap'
}

export enum Charts2States {
    nodata = 'no data',
    loading = 'loading data',
//    printing = 'print',
    active = 'active'
}

/**
 * @enum Charts2DimensionStrategies
 * Defines how to size the chart dimension (width or height) according to the selected strategy
 * Strategies can be:
 *  - fixed, where the chart will always keep the fixed dimensions given on the config input,
 *  - data, where the chart dimension will be resized to fit the data
 *  - full, where the chart resizes to fill in all the parent component dimension
 */
export enum Charts2DimensionStrategies {
    fixed = 'fixed',
    data = 'data',
    full = 'full'
}

export enum Charts2TooltipAlignments {
    left = 'left',
    right = 'right',
    top = 'top',
    bottom = 'bottom',
    none = 'none'
}

export enum Charts2TooltipTypes {
    value = 'value',
    simple = 'simple',
    list = 'list'
}

export enum Charts2SeriesTypes {
    scatter = 'scatter',
    row = 'rowchart',
    bar = 'bar',
    rangecolumn = 'range-column',
    rangescatter = 'range-scatter',
    heatmap = 'heatmap',
    line = 'line',
    horizontal = 'horizontal-line',
    vertical = 'vertical-line',
    point = 'point',
    catline = 'category-line',
    map = 'map',
    matrix = 'matrix',
    range = 'range',
    internal = 'internal'
}

export enum Charts2StackTypes {
    none = 'none',
    normal = 'Normal stack',
    percent = 'stretched'
}

export enum RangeScatterChartClasses {
    series = 'background',
    stats = 'primary',
    highlight = 'highlighted'
}

export enum Chart2HoverElementTypes {
    cell = 'cell',
    row = 'row',
    col = 'column'
}
