
import {createEntityAdapter, EntityAdapter, EntityState, Update} from '@ngrx/entity';
import {IAsyncRequestItem} from '@features/general/async_requests/async-requests-items.entity';

export enum AsyncRequestTypes {
    single = 'single',
    multiple = 'multiple'
}

export enum AsyncRequestStatus {
    complete = 'complete',
    incomplete = 'incomplete'
}

export interface IAsyncRequest {
    id: string;
    type: AsyncRequestTypes;
    status?: AsyncRequestStatus;
    items: string[];
}

export function selectAsyncRequestId(request: Partial<IAsyncRequest>): string {
    return request.id;
}

export const asyncRequestsAdapter: EntityAdapter<IAsyncRequest> =
    createEntityAdapter<IAsyncRequest>({
        selectId: selectAsyncRequestId
    });

export interface AsyncRequestsState extends EntityState<IAsyncRequest> {}


export class AsyncRequestsHelper {
    static createRequest = (id: string, items: string[]): IAsyncRequest => {
        return { id, type: AsyncRequestTypes.multiple, status: AsyncRequestStatus.incomplete, items };
    };

    static setStatus = (request_id: string, status: AsyncRequestStatus): Update<IAsyncRequest> => {
        return {id: request_id, changes: { status }};
    };

    static addItems = (request_id: string, current_items: string[], new_items: IAsyncRequestItem[]): Update<IAsyncRequest> => {
        const new_ids = new_items.map((item) => item.id);
        const items = new Set([...current_items, ...new_ids]);
        return { id: request_id, changes: { items: Array.from(items) } };
    };
}
