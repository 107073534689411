import { RouterReducerState } from '@ngrx/router-store';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export const selectRouter = createFeatureSelector<RouterReducerState>('router');

export const selectRouterParams = createSelector(
    selectRouter,
    (router: any) => router ? router.state.params : []
);

export const selectRouterData = createSelector(
    selectRouter,
    (router: any) => router ? router.state.data : []
);

export const selectRouterChildren = createSelector(
    selectRouter,
    (router: any) => router ? router.state.children : []
);

export const selectRouterQueryParam = (param: string) => createSelector(
    selectRouterParams,
    selectRouterChildren,
    (params: any, children: any[]) => {
        let result = null;
        if (params) {
            result = getParamFromParams(params, param);
            if (!result) {
                result = searchParamBetweenChildren(children, param);
            }
        }
        return result ? result : null;
    }
);

function getParamFromParams(params: any[], param: string) {
    return Object.keys(params).includes(param) ? params[param] : null;
}

function searchParamBetweenChildren(children: any[], param: string) {
    let result = null;
    try {
        if (!children.length) {
            throw new Error();
        }
        for (let i = 0; i < children.length; i++) {
            const child = children[i];
            result = getParamFromParams(child.params, param);
            if (!result && child.children.length) {
                result = searchParamBetweenChildren(child.children, param);
            }
            if (result) {
                break;
            }
        }
    } catch (e) {
        // ....
    }
    return result ? result : null;
}

export const selectRouterUrl = createSelector(
    selectRouter,
    (router) => router ? router.state.url : ''
);

export const selectRouterUrlAsArray = createSelector(
    selectRouterUrl,
    (url) => url.split('/').filter((elem) => elem !== '')
);
