
import { selectCmsChartsState } from '@features/general/charts/state/main/selectors/cms-charts.selectors';
import { createSelector } from '@ngrx/store';

export const selectCmsChartsTooltipState = createSelector(
    selectCmsChartsState,
    (state) => state.tooltip
);

export const selectCmsChartsTooltipIsDataLoaded = createSelector(
    selectCmsChartsTooltipState,
    (state) => state.loaded.has(state.data_url)
)

export const selectCmsChartsTooltipDataUrl = createSelector(
    selectCmsChartsTooltipState,
    (state) => state.data_url
)

export const selectCmsChartsTooltipActiveData = createSelector(
    selectCmsChartsTooltipState,
    (state) => {
        const active_data = state.loaded.get(state.data_url);

        return {
            chart_id: state.chart_id,
            data: active_data || []
        };
    }
);
