import {
    CmsChartsActionTypes,
    CmsChartsCategoriesActionTypes,
    CmsChartsFiltersActionTypes,
    CmsChartsFullActions,
    CmsChartsFullActionTypes,
    CmsChartsParamsActionTypes,
    CmsChartsSeriesActionTypes,
    CmsChartsTooltipActionTypes
} from '@features/general/charts/state/main/actions';
import {
    cmsChartDataAdapter,
    cmsChartFilterAdapter,
    cmsChartParameterAdapter,
    CmsChartParametersState,
    CmsChartsDataState,
    cmsChartSeriesAdapter,
    CmsChartsFiltersState,
    CmsChartsSeriesHelper,
    CmsChartsSeriesState,
    CmsChartsTooltipHelper,
    CmsChartsTooltipState
} from '@features/general/charts/state/main/entities';
import {
    cmsChartCategoriesAdapter,
    CmsChartsCategoriesHelper,
    CmsChartsCategoriesState
} from '@features/general/charts/state/categories/cms-chart-categories.entity';
import {cmsChartAdapter, CmsChartsChartsState} from './main/entities/cms-chart.entity';
import {CmsChartParametersHelper} from '@features/general/charts/state/params/cms-chart-parameter-helper';
import {cmsChartGeoAdapter, CmsChartGeoState} from '@features/general/charts/state/geo/cms-chart-geo.entity';
import {CmsChartGeoHelper} from '@features/general/charts/state/geo/cms-chart-geo-helper';
import {CmsChartsGeoActionTypes} from '@features/general/charts/state/geo/cms-charts-geo.actions';
import {
    cmsChartHighlightAdapter,
    CmsChartHighlightState
} from "@features/general/charts/state/highlights/cms-chart-highlights.entity";
import {CmsChartsHighlightsHelper} from "@features/general/charts/state/highlights/cms-chart-highlights.helper";
import {
    CmsChartsHighlightsActionTypes
} from "@features/general/charts/state/highlights/cms-charts-highlights.actions";
import {CmsChartsLoadingState} from "@features/general/charts/structure";
import CmsChartsFiltersHelper from "@features/general/charts/state/filters/cms-charts-filters.helper";

export interface CmsChartsState {
    charts: CmsChartsChartsState;
    categories: CmsChartsCategoriesState;
    params: CmsChartParametersState;
    data: CmsChartsDataState;
    filters: CmsChartsFiltersState;
    series: CmsChartsSeriesState;
    requested: string[];
    tooltip: CmsChartsTooltipState;
    geo: CmsChartGeoState;
    highlights: CmsChartHighlightState;
}

export const initialCmsChartsReducerState: CmsChartsState = {
    charts: cmsChartAdapter.getInitialState(),
    categories: cmsChartCategoriesAdapter.getInitialState(),
    params: cmsChartParameterAdapter.getInitialState(),
    data: cmsChartDataAdapter.getInitialState(),
    filters: cmsChartFilterAdapter.getInitialState(),
    series: cmsChartSeriesAdapter.getInitialState(),
    requested: [],
    tooltip: CmsChartsTooltipHelper.GetInitialState(),
    geo: cmsChartGeoAdapter.getInitialState(),
    highlights: cmsChartHighlightAdapter.getInitialState()
};

const addRequested = (state: string[], elems: string[]): string[] => {
    const exclusions = removeRequested(state, elems);
    return [...exclusions, ...elems];
};

const removeRequested = (state: string[], elems: string[]): string[] => {
    return state.filter((elem) => !elems.includes(elem));
};

export function cmsChartsReducer(state = initialCmsChartsReducerState, action: CmsChartsFullActions): CmsChartsState {
    let updates: any;
    let id;
    let time_stamp;

    let cats;
    let series;

    const addTimeStampToChanges = (elems: {id, changes}[]) => {
        return elems.map((d) => ({ ...d, changes: { ...d.changes, time_stamp: Date.now() } }));
    }

    switch (action.type) {
    case CmsChartsFullActionTypes.CmsChartsLoaded:
        return {
            ...state,
            charts: cmsChartAdapter.addMany(action.payload.charts, state.charts),
            categories: cmsChartCategoriesAdapter.addMany(CmsChartsCategoriesHelper.chartsCategoriesConversor(action.payload.charts), state.categories),
            params: cmsChartParameterAdapter.addMany(CmsChartParametersHelper.chartsParamConvert(action.payload.charts), state.params),
            filters: cmsChartFilterAdapter.addMany(CmsChartsFiltersHelper.chartFiltersConversor(action.payload.charts), state.filters),
            series: cmsChartSeriesAdapter.addMany(CmsChartsSeriesHelper.chartSeriesConversor(action.payload.charts), state.series),
            geo: cmsChartGeoAdapter.addMany(CmsChartGeoHelper.chartLayersConversor(action.payload.charts), state.geo),
            highlights: cmsChartHighlightAdapter.addMany(CmsChartsHighlightsHelper.chartHighlightsConversor(action.payload.charts), state.highlights)
        };
    case CmsChartsFullActionTypes.CmsChartsUpdateChart:
        return { ...state, charts: cmsChartAdapter.updateOne(action.payload, state.charts) };
    case CmsChartsFullActionTypes.CmsChartsDataLoaded:
        updates = {
            ...action.payload.data,
            time_stamp: Date.now(),
            status: !!action.payload.data?.data?.length ? CmsChartsLoadingState.loaded : CmsChartsLoadingState.nodata
        };
        return { ...state, data: cmsChartDataAdapter.addOne(updates, state.data) };
    case CmsChartsActionTypes.CmsChartsMultiDataLoaded:
        time_stamp = Date.now();
        updates = action.payload.data.map((payload) => ({
            ...payload,
            time_stamp,
            status: !!payload.data?.length ? CmsChartsLoadingState.loaded : CmsChartsLoadingState.nodata
        }));
        return { ...state, data: cmsChartDataAdapter.addMany(updates, state.data) };
    case CmsChartsFullActionTypes.CmsChartsResetState:
        return {
            charts: cmsChartAdapter.removeAll(state.charts),
            categories: cmsChartCategoriesAdapter.removeAll(state.categories),
            params: cmsChartParameterAdapter.removeAll(state.params),
            data: cmsChartDataAdapter.removeAll(state.data),
            filters: cmsChartFilterAdapter.removeAll(state.filters),
            series: cmsChartSeriesAdapter.removeAll(state.series),
            requested: [],
            tooltip: CmsChartsTooltipHelper.GetInitialState(),
            geo: cmsChartGeoAdapter.removeAll(state.geo),
            highlights: cmsChartHighlightAdapter.removeAll(state.highlights)
        };
    case CmsChartsFullActionTypes.CmsChartsSetUrlsAsRequested:
        return { ...state, requested: addRequested(state.requested, action.payload.urls) };
    case CmsChartsFullActionTypes.CmsChartsRemoveUrlsFromRequested:
        return { ...state, requested: removeRequested(state.requested, action.payload.urls) };
    case CmsChartsActionTypes.CmsChartsSeriesAndCategoriesSetUrls:
    case CmsChartsActionTypes.CmsChartsSeriesAndCategoriesUpdates:
        return {
            ...state,
            series: cmsChartSeriesAdapter.updateMany(addTimeStampToChanges(action.payload.series), state.series),
            categories: cmsChartCategoriesAdapter.updateMany(addTimeStampToChanges(action.payload.categories), state.categories),
        };
    // params
    case CmsChartsParamsActionTypes.CmsChartsSetParam:
    case CmsChartsParamsActionTypes.CmsChartsSetPageParam:
        return { ...state, params: cmsChartParameterAdapter.updateOne({
            id: CmsChartParametersHelper.getIdFromChartAndName(action.payload.chart_id, action.payload.name),
            changes: { value: action.payload.value, temporaryIgnore: action.payload.temporaryIgnore || false }}, state.params)
        };
    case CmsChartsParamsActionTypes.CmsChartsSetParams:
    case CmsChartsParamsActionTypes.CmsChartsSetPageParams:
//    case CmsChartsParamsActionTypes.CmsChartsSetValuesParams:
        updates = action.payload.map((elem) => ({
            id: CmsChartParametersHelper.getIdFromChartAndName(elem.chart_id, elem.name),
            changes: { value: elem.value, temporaryIgnore: elem.temporaryIgnore || false }
        }));
        return { ...state, params: cmsChartParameterAdapter.updateMany(updates, state.params) };
    case CmsChartsParamsActionTypes.CmsChartsClearParams:
        updates = action.payload.map((elem) => ({
            id: CmsChartParametersHelper.getIdFromChartAndName(elem.chart_id, elem.name),
            changes: { value: null, temporaryIgnore: false }
        }));
        return { ...state, params: cmsChartParameterAdapter.updateMany(updates, state.params) };
    // filters
    case CmsChartsFiltersActionTypes.CmsChartsSetFiltersUrls:
        updates = action.payload.map((elem) => {
            const changes = elem.status ? { list_id: elem.url, status: elem.status } : { list_id: elem.url };
            return {
                id: CmsChartsFiltersHelper.getIdFromChartAndName(elem.chart_id, elem.param),
                changes
            };
        });
        return { ...state, filters: cmsChartFilterAdapter.updateMany(updates, state.filters) };
    case CmsChartsFiltersActionTypes.CmsChartsUpdateFilters:
    case CmsChartsFiltersActionTypes.CmsChartsUpdateFiltersUrls:
        return { ...state, filters: cmsChartFilterAdapter.updateMany(action.payload, state.filters) };
    // highlights
    case CmsChartsHighlightsActionTypes.CmsChartsSetHighlightsUrls:
        time_stamp = Date.now();
        updates = action.payload.map((elem) => {
            const changes = elem.status ? { list_id: elem.url, status: elem.status, time_stamp } : { list_id: elem.url, time_stamp };
            return {
                id: CmsChartsHighlightsHelper.getIdFromChartAndName(elem.chart_id, elem.param),
                changes
            };
        });
        return { ...state, highlights: cmsChartHighlightAdapter.updateMany(updates, state.highlights) };
    case CmsChartsHighlightsActionTypes.CmsCHartsUpdateHighlights:
    case CmsChartsHighlightsActionTypes.CmsChartsUpdateHighlightsUrls:
        return { ...state, highlights: cmsChartHighlightAdapter.updateMany(action.payload, state.highlights) };
    // series
    case CmsChartsSeriesActionTypes.CmsChartsSeriesSetUrls:
        updates = action.payload.map((d) => ({ ...d, changes: { ...d.changes, time_stamp: Date.now() } }));
        return { ...state, series: cmsChartSeriesAdapter.updateMany(updates, state.series) };
    case CmsChartsCategoriesActionTypes.CmsChartsCategoriesSetUrls:
        updates = action.payload.map((d) => ({ ...d, changes: { ...d.changes, time_stamp: Date.now() } }));
        return { ...state, categories: cmsChartCategoriesAdapter.updateMany(updates, state.categories) };
    case CmsChartsActionTypes.CmsChartsCopyChart:
        return {
            ...state,
            charts: cmsChartAdapter.addOne(action.payload.chart, state.charts),
            categories: cmsChartCategoriesAdapter.addMany(action.payload.categories, state.categories),
            params: cmsChartParameterAdapter.addMany(action.payload.params, state.params),
            filters: cmsChartFilterAdapter.addMany(action.payload.filters, state.filters),
            series: cmsChartSeriesAdapter.addMany(action.payload.series, state.series),
            geo: cmsChartGeoAdapter.addMany(action.payload.layers, state.geo),
            // todo: add highlights
        };
    case CmsChartsTooltipActionTypes.CmsChartsSetTooltip:
        return {
            ...state,
            tooltip: CmsChartsTooltipHelper.SetTooltip(action.payload, state.tooltip)
        };
    case CmsChartsTooltipActionTypes.CmsChartsTooltipDataLoaded:
        return {
            ...state,
            tooltip: CmsChartsTooltipHelper.AddNewData(state.tooltip, action.payload)
        };
    /*case CmsChartsParamsActionTypes.CmsChartsAddCopyParam:
        updates = CmsChartParametersHelper.copyParam(action.payload.param, action.payload.name, action.payload.value);
        return {
            ...state,
            params: cmsChartParameterAdapter.addOne(updates, state.params)
        };*/
    case CmsChartsSeriesActionTypes.CmsChartsSeriesCopyWithNewParam:
        const param = CmsChartParametersHelper.copyParam(action.payload.param, action.payload.param_name, action.payload.param_value);
        series = action.payload.series.map((s) => CmsChartsSeriesHelper.copySeries(s, param));
        return {
            ...state,
            series: cmsChartSeriesAdapter.addMany(series, state.series),
            params: cmsChartParameterAdapter.addOne(param, state.params)
        };
    case CmsChartsSeriesActionTypes.CmsChartsAddNewCopiedSeriesAndParams:
        return {
            ...state,
            series: cmsChartSeriesAdapter.addMany(action.payload.series, state.series),
            params: cmsChartParameterAdapter.addMany(action.payload.params, state.params)
        };
    case CmsChartsParamsActionTypes.CmsChartsRemoveCopyParam:
        id = CmsChartParametersHelper.getIdFromChartAndName(action.payload.chart_id, action.payload.name);
        return {
            ...state,
            params: cmsChartParameterAdapter.removeOne(id, state.params)
        };

    case CmsChartsActionTypes.CmsChartsRemoveParamsAndSeriesByIds:
        return {
            ...state,
            params: cmsChartParameterAdapter.removeMany(action.payload.paramsIds, state.params),
            series: cmsChartSeriesAdapter.removeMany(action.payload.seriesIds, state.series)
        };
    case CmsChartsSeriesActionTypes.CmsChartsSeriesRemoveCopied:
        id = CmsChartsSeriesHelper.getIdFromChartAndName(action.payload.chart_id, action.payload.name);
        return {
            ...state,
            series: cmsChartSeriesAdapter.removeOne(id, state.series)
        };
    case CmsChartsActionTypes.CmsChartsSetLoadingState:
        return {
            ...state,
            charts: cmsChartAdapter.updateOne({ id: action.payload.chart_id, changes: { loadingState: action.payload.state } }, state.charts)
        };
    case CmsChartsActionTypes.CmsChartsSetLoadingStates:
        const changes = action.payload.map((request) => ({ id: request.chart_id, changes: { loadingState: request.state } }));
        return {
            ...state,
            charts: cmsChartAdapter.updateMany(changes, state.charts)
        };
    case CmsChartsGeoActionTypes.CmsChartsGeoSetLayersUrl:
        updates = action.payload.map((request) => ({ id: request.layer_id, changes: { url: request.url, keys: request.keys } }));
        return {
            ...state,
            geo: cmsChartGeoAdapter.updateMany(updates, state.geo)
        };
    case CmsChartsGeoActionTypes.CmsChartsGeoSetLayerUrl:
        updates = { id: action.payload.layer_id, changes: { url: action.payload.url, keys: action.payload.keys } };
        return {
            ...state,
            geo: cmsChartGeoAdapter.updateOne(updates, state.geo)
        };
    case CmsChartsGeoActionTypes.CmsChartsGeoSetLayersUrls:
        updates = action.payload.map((request) => ({ id: request.layer_id, changes: { urls: request.urls } }));
        return {
            ...state,
            geo: cmsChartGeoAdapter.updateMany(updates, state.geo)
        };
    case CmsChartsGeoActionTypes.CmsChartsGeoSetLayerUrls:
        updates = { id: action.payload.layer_id, changes: { urls: action.payload.urls } };
        return {
            ...state,
            geo: cmsChartGeoAdapter.updateOne(updates, state.geo)
        };
/*    case CmsChartsHighlightsActionTypes.CmsChartsSetHighlight:
        return {
            ...state,
            highlights: cmsChartHighlightAdapter.upsertOne(action.payload, state.highlights)
        }*/
    default:
        return state;
    }
}

export const {
    selectAll: selectAlLCharts,
    selectEntities: selectEntitiesCharts,
} = cmsChartAdapter.getSelectors();

export const {
    selectAll: selectAlLChartsParameters,
    selectEntities: selectEntitiesChartsParameters,
} = cmsChartParameterAdapter.getSelectors();

export const {
    selectAll: selectAlLChartsData,
    selectEntities: selectEntitiesChartsData,
} = cmsChartDataAdapter.getSelectors();

export const {
    selectAll: selectAlLChartsFilters,
    selectEntities: selectEntitiesChartsFilters,
} = cmsChartFilterAdapter.getSelectors();

export const {
    selectAll: selectAlLChartsSeries,
    selectEntities: selectEntitiesChartsSeries,
} = cmsChartSeriesAdapter.getSelectors();

export const {
    selectAll: selectAllChartsCategories,
    selectEntities: selectEntitiesChartsCategories,
} = cmsChartCategoriesAdapter.getSelectors();

export const {
    selectAll: selectAllChartsGeo,
    selectEntities: selectEntitiesChartsGeo,
} = cmsChartGeoAdapter.getSelectors();

export const {
    selectAll: selectAllChartsHighlights,
    selectEntities: selectEntitiesChartsHighlights,
} = cmsChartHighlightAdapter.getSelectors();
