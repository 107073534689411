import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';
import {EnvService} from "@shared/services/env.service";

declare const gtag; // Google analytics

@Injectable()
export class GoogleAnalyticsService {
    protected isEnabled: boolean = false;

    constructor(
        private titleService: Title,
        private envService: EnvService,
    ) {
        if (envService.enableGoogleAnalyticsTracking) {
            gtag('config', envService.googleAnalyticsSiteId, { 'send_page_view': false });
            this.isEnabled = true;
        }
    }

    /**
     * @param {string} url
     */
    public trackPageView(url: string): void {
        if (!this.isEnabled) {
            return;
        }
        gtag('config', this.envService.googleAnalyticsSiteId, {
            'page_title' : this.titleService.getTitle(),
            'page_path': url
        });
    }
}
