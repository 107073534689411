import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {CompositeIndicatorsActions, CompositeIndicatorsActionTypes} from './composite-indicators.actions';

export enum CoinStatus {
    NOT_REQUESTED = 'Not requested',
    REQUESTED = 'Requested',
    LOADED = 'Loaded',
    LOADING = 'Loading',
    STRUCTURE = 'Only structure loaded',
    MAINSTRUCTURE = 'Only main structure loaded',
    DATA = 'Only data loaded',
}

const initialStatus = {
    allData: CoinStatus.NOT_REQUESTED,
    isLastEdition: false,
    status: CoinStatus.REQUESTED,
    dataUrl: null,
};

export interface ICoinStateElem {
    indice: string;
    edition: number;
    isLastEdition: boolean;
    status: CoinStatus;
    allData: CoinStatus;
    dataUrl: string;
    dataYear?: number;
}

export interface CompositeIndicatorsState extends EntityState<ICoinStateElem> {}

function createId(indice: string, edition: number): string {
    return indice + '_' + edition;
}

export const adapter: EntityAdapter<ICoinStateElem> =
    createEntityAdapter<ICoinStateElem>({
        selectId: (elem) => createId(elem.indice, elem.edition),
    });

const stateHasLastEdition = (state: CompositeIndicatorsState, indice: string): boolean => {
    const ids = (state.ids as string[]).filter((d) => d.includes(indice));
    return !!ids.find((id) => state.entities[id] && state.entities[id].isLastEdition);
};

const stateHasEdition = (state: CompositeIndicatorsState, id: string): boolean => {
    return !!state.entities[id];
};

const stateHasDataLoaded = (state: CompositeIndicatorsState, id: string): boolean => {
    return state.entities[id] && state.entities[id].allData === CoinStatus.LOADED;
};

const stateHasDataRequest = (state: CompositeIndicatorsState, id: string): boolean => {
    return state.entities[id] && state.entities[id].allData === CoinStatus.REQUESTED;
};

export const initialCompositeIndicatorsState: CompositeIndicatorsState = adapter.getInitialState();

export function compositeIndicatorsReducer(state = initialCompositeIndicatorsState , action: CompositeIndicatorsActions): CompositeIndicatorsState {
    let id;
    switch (action.type) {
    case CompositeIndicatorsActionTypes.CoinEditionRequested:
        return adapter.addOne({ ...initialStatus, ...action.payload }, state);
    case CompositeIndicatorsActionTypes.CoinLastEditionRequested:
        id = stateHasLastEdition(state, action.payload.indice);
        if (id) {
            return state;
        }
        return adapter.addOne({ ...initialStatus, ...action.payload, edition: 0, isLastEdition: true }, state);
    case CompositeIndicatorsActionTypes.CoinLastEditionUpdated:
        const lastEdId = createId(action.payload.indice, 0);
        id = createId(action.payload.indice, action.payload.edition);
        if (!stateHasEdition(state, id)) {
            id = lastEdId;
        } else {
            state = adapter.removeOne(lastEdId, state);
        }
        return adapter.updateOne({ id, changes: { edition: action.payload.edition, isLastEdition: true } }, state);
    case CompositeIndicatorsActionTypes.CoinLastEditionLoaded:
        id = createId(action.payload.indice, action.payload.edition);
        return adapter.updateOne({ id, changes: { status: CoinStatus.LOADED, isLastEdition: true } }, state);
    case CompositeIndicatorsActionTypes.CoinEditionLoaded:
        id = createId(action.payload.indice, action.payload.edition);
        return adapter.updateOne({ id, changes: { status: CoinStatus.LOADED } }, state);
    case CompositeIndicatorsActionTypes.CoinEditionDataLoading:
        id = createId(action.payload.indice, action.payload.edition);
        return adapter.updateOne({ id, changes: { allData: CoinStatus.LOADING, dataUrl: action.payload.url } }, state);
    case CompositeIndicatorsActionTypes.CoinEditionAllDataLoaded:
        id = createId(action.payload.indice, action.payload.edition);
        return adapter.updateOne({ id, changes: { allData: CoinStatus.LOADED } }, state);
    case CompositeIndicatorsActionTypes.CoinEditionAllDataRequested:
        id = createId(action.payload.indice, action.payload.edition);
        if (stateHasDataLoaded(state, id)) { return state; }
        return adapter.updateOne({ id, changes: { allData: CoinStatus.REQUESTED } }, state);
        case CompositeIndicatorsActionTypes.CoinStatusReset:
            console.log('CoinStatusReset action', action);
            console.log('CoinStatusReset state', state);
            return state;
    default: {
        return state;
    }
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal,

} = adapter.getSelectors();
