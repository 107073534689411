import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { IIndice } from '@shared/interfaces';
import { IndicesActions, IndicesActionTypes } from './indices.actions';

export interface IndicesState extends EntityState<IIndice> {
    allIndicesLoaded: boolean;
}

export const adapter: EntityAdapter<IIndice> =
    createEntityAdapter<IIndice>({
        selectId: (indice) => indice.code,
    });

export const initialIndicesState: IndicesState = adapter.getInitialState({
    allIndicesLoaded: false,
});

export function indicesReducer(state = initialIndicesState , action: IndicesActions): IndicesState {

    switch (action.type) {
    case IndicesActionTypes.IndicesLoaded:
        return adapter.addMany(action.payload.indices, state);
    case IndicesActionTypes.AllIndicesLoaded:
        return { ...state, allIndicesLoaded: true };
    case IndicesActionTypes.RemoveIndiceByCode:
        console.log('RemoveIndiceByCode', state);
        console.log('RemoveIndiceByCode code', action.payload.code);
        let entities = {...state.entities};
        console.log('true?', Object.keys(entities).includes(action.payload.code));
        if (Object.keys(entities).includes(action.payload.code)) {
            delete entities[action.payload.code];
        }
        console.log('RemoveIndiceByCode after', entities);
        return { ...state, entities };
    default: {
            return state;
        }
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal,

} = adapter.getSelectors();
