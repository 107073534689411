import { EventEmitter, Input, OnDestroy, OnInit, Output, Directive } from '@angular/core';
import { ObjectHelper } from '@shared/helpers';
import { Observable, Subscription } from 'rxjs';

@Directive()
export abstract class BaseComponent<D, C, A> implements OnInit, OnDestroy {

    get config() {
        return this._config;
    }

    @Input('config') set config(values: Partial<C>) {
        if (!values) {
            values = {};
        }
        this._config = ObjectHelper.UpdateObjectWithPartialValues(this.initialConfig, values);
        this.configUpdate();
    }

    get data() {
        return this._data;
    }

    @Input('data') set data(values: D) {
        this._data = values;
        this.dataUpdate();
    }

    protected initialConfig: C = {} as C;

    protected _config: C;

    private subscriptions: Subscription[] = [];

    protected _isBrowser: boolean = true;

    _data: D;

    @Input() requests = new Observable<any>();
    @Output() changeEvent = new EventEmitter<A>();

    constructor() {
        this.config = this.initConfig();

        // @ts-ignore
        this._isBrowser = window._isBrowser;
    }

    changed(event: A) {
        this.changeEvent.emit(event);
    }

    ngOnInit(): void {
        this.setSubscriptions();
    }

    ngOnDestroy(): void {
        this.subscriptions.map((s) => s.unsubscribe());
    }

    abstract configUpdate();
    abstract dataUpdate();
    abstract initConfig(): C;

    protected setSubscriptions() {
        const sub = this.requests.subscribe((request) => this.resolveRequest(request));
        this.subscribe(sub);
    }

    protected subscribe(sub: Subscription) {
        this.subscriptions.push(sub);
    }

    protected resolveRequest(request: any) {}

}
