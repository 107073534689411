import { Action } from '@ngrx/store';
import { IList, ListsCmsSlugs, ListsScopes } from '@features/general/lists/structure';

export enum ListsActionTypes {
    ListsLoaded = '[LISTS] Load Lists',
    ListsLoadRequested = '[LISTS] Request lists loading by scope',
    ListsRequestedByScope = '[LISTS] Request lists by scope',
    ListsRequestedByUrl = '[LISTS] Request list by url',
    ListsRequestedByUrls = '[LISTS] Request lists by urls',
    ListsReset = '[LISTS] Reset lists',
    ListsResetScope = '[LISTS] Reset lists by scope',
    ListsResetScopeId = '[LISTS] Reset lists by scope id',
    ListsResetNotScopeId = '[LISTS] Reset lists different from scope id',
    ListsRemove = '[LISTS] Remove lists by key',
    ListsSetFeatureScope = '[LISTS] set feature scope',
    ListsSetPageScope = '[LISTS] set page scope',
    ListsCmsRequestedBySlug = '[LISTS] Request cms lists by slug',
    ListsSetUrlAsRequested = '[LISTS] Set url as requested',
    ListsRemoveUrlsFromRequested = '[LISTS] Remove url from requested',
    ListsLoadedAtRequestTime = '[LISTS] Lists requested were already loaded'
}

export class ListsLoaded implements Action {
    readonly type = ListsActionTypes.ListsLoaded;
    constructor(public payload: {lists: IList[]}) {}
}

export class ListsLoadRequested implements Action {
    readonly type = ListsActionTypes.ListsLoadRequested;
    constructor(public payload: {type: ListsScopes, lists: IList[]}) {}
}

export class ListsRequestedByScope implements Action {
    readonly type = ListsActionTypes.ListsRequestedByScope;
    constructor(public payload: {type: ListsScopes, lists: any}) {}
}

export class ListsRequestedByUrl implements Action {
    readonly type = ListsActionTypes.ListsRequestedByUrl;
    constructor(public payload: {type: ListsScopes, slug: string, url: string}) {}
}

export class ListsRequestedByUrls implements Action {
    readonly type = ListsActionTypes.ListsRequestedByUrls;
    constructor(public payload: {type: ListsScopes, slug: string, url: string}[]) {}
}

export class ListsReset implements Action {
    readonly type = ListsActionTypes.ListsReset;
}

export class ListsResetScope implements Action {
    readonly type = ListsActionTypes.ListsResetScope;
    constructor(public payload: {scope: ListsScopes}) {}
}

export class ListsResetScopeId implements Action {
    readonly type = ListsActionTypes.ListsResetScopeId;
    constructor(public payload: {scope_id: string}) {}
}

export class ListsResetNotScopeId implements Action {
    readonly type = ListsActionTypes.ListsResetNotScopeId;
    constructor(public payload: {scope_id: string}) {}
}

export class ListsRemove implements Action {
    readonly type = ListsActionTypes.ListsRemove;
    constructor(public payload: {keys: string[]}) {}
}

export class ListsSetFeatureScope implements Action {
    readonly type = ListsActionTypes.ListsSetFeatureScope;
    constructor(public payload: {feature: string, page: string}) {}
}

export class ListsSetPageScope implements Action {
    readonly type = ListsActionTypes.ListsSetPageScope;
    constructor(public payload: {page: string}) {}
}

export class ListsCmsRequestedBySlug implements Action {
    readonly type = ListsActionTypes.ListsCmsRequestedBySlug;
    constructor(public payload: {type: ListsScopes, slug: ListsCmsSlugs, params: {[_: string]: string | number}}) {}
}

export class ListsSetUrlAsRequested implements Action {
    readonly type = ListsActionTypes.ListsSetUrlAsRequested;
    constructor(public payload: { url: string, scope: ListsScopes, slug: any }) {}
}

export class ListsRemoveUrlsFromRequested implements Action {
    readonly type = ListsActionTypes.ListsRemoveUrlsFromRequested;

    constructor(public payload: { urls: string[] }) {}
}

export class ListsLoadedAtRequestTime implements Action {
    readonly type = ListsActionTypes.ListsLoadedAtRequestTime;
    constructor(public payload: {type: ListsScopes, slug: string, url: string}) {}
}

export type ListsActions = ListsLoaded
    | ListsLoadRequested
    | ListsRequestedByScope
    | ListsRequestedByUrl
    | ListsRequestedByUrls
    | ListsReset
    | ListsResetScope
    | ListsResetScopeId
    | ListsResetNotScopeId
    | ListsRemove
    | ListsSetFeatureScope
    | ListsSetPageScope
    | ListsCmsRequestedBySlug
    | ListsSetUrlAsRequested
    | ListsRemoveUrlsFromRequested
    | ListsLoadedAtRequestTime;
