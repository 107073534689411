import {ICmsModuleContentFull, ICmsModuleFull} from "@features/general/cms/structure";
import {IList, IListElem, ListsScopes} from "@features/general/lists/structure";

export class CmsHelper {
    static ModuleContent = (module: ICmsModuleFull): ICmsModuleContentFull => {
        return module && module.api;
    }

    static ModulesContent = (modules: ICmsModuleFull[]): ICmsModuleContentFull[] => {
        return (modules || []).map((module) => CmsHelper.ModuleContent(module));
    }

    static ModuleContentAsArray = (module: ICmsModuleFull) => {
        const content = CmsHelper.ModuleContent(module);
        const keys = CmsHelper.ModuleContentKeys(content);

        return keys && keys[0] && content[keys[0]] || [];
    }

    static ModuleContentKeys = (content: ICmsModuleContentFull): Array<string> => {
        return Object.keys(content);
    }

    static ModuleContentBySlug = (module: ICmsModuleFull, slug: string,  unique = true, contains_slug = false): ICmsModuleContentFull => {
        const content: any = CmsHelper.ModuleContent(module);
        return content && ((unique && content[slug]) || content);
    }

    static ConvertModuleToList = (module: ICmsModuleFull): IList => {
        return {
            id: module?.json_field_1?.id || module.slug,
            slug: module.slug,
            scope: module?.json_field_1?.scope || ListsScopes.page,
            scope_id: null,
            values: CmsHelper.ModuleContentAsArray(module) as IListElem[]
        };
    }
}
