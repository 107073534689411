import { Action } from '@ngrx/store';
import { IHighlight } from '@features/cultural-creative-cities/shared/interfaces/c3.interfaces';

export enum C3CountryActionTypes {
    C3CountryRequested = '[C3 Country] Country Data Requested',
    C3CountrySetActiveCode = '[C3 Country] set active code',
    C3CountryProfileDataLoaded = '[C3 Country] profile data loaded',
    C3CountrySetComponent = '[C3 Country] Set Country active component',
    C3CountrySetActiveGroup = '[C3 Country] Set active group on the country page',
}

// Country profile page actions
export class C3CountryRequested implements Action {
    readonly type = C3CountryActionTypes.C3CountryRequested;
    constructor(public payload: { indice: string, country: string }) {}
}
export class C3CountrySetActiveCode implements Action {
    readonly type = C3CountryActionTypes.C3CountrySetActiveCode;
    constructor(public payload: {code: string}) {}
}
export class C3CountryProfileDataLoaded implements Action {
    readonly type = C3CountryActionTypes.C3CountryProfileDataLoaded;
    constructor(public payload: { code: string, scoresOverviewDescription: string, highlights: IHighlight[], mapImageUrl: string, factSheetUrl: string, infoMessage: string }) {}
}
export class C3CountrySetComponent implements Action {
    readonly type = C3CountryActionTypes.C3CountrySetComponent;
    constructor(public payload: {id: number}) {}
}
export class C3CountrySetActiveGroup implements Action {
    readonly type = C3CountryActionTypes.C3CountrySetActiveGroup;
    constructor(public payload: { group: string }) {}
}

export type C3CountryActions = C3CountryRequested
    | C3CountrySetActiveCode
    | C3CountryProfileDataLoaded
    | C3CountrySetComponent
    | C3CountrySetActiveGroup;
