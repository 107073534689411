import { Action } from '@ngrx/store';
import { ScreenState } from '../interfaces/index';

export enum ScreenStateActionTypes {
    SET_SCREEN = '[Screen] Set screen',
}

const MOBILE_MAX_WIDTH = 767;
const TABLET_MAX_WIDTH = 991;

export class SetScreenState implements Action {
    readonly type = ScreenStateActionTypes.SET_SCREEN;
    payload: Readonly<ScreenState>;

    constructor(width: number) {
        const mobile = width <= MOBILE_MAX_WIDTH;
        const tablet = !mobile && width <= TABLET_MAX_WIDTH;
        const desktop = !mobile && !tablet;

        this.payload = {
            mobile,
            tablet,
            desktop,
        };
    }
}

export type ScreenStateActions = SetScreenState;
