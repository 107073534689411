
import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromComponentVariables from './component-variables.reducers';

import { CoinState } from '../index';

export const selectCoinState = createFeatureSelector<CoinState>('composite');

export const selectComponentVariablesState = createSelector(
    selectCoinState,
    (state) => state.componentVariables,
);

export const selectAllComponentVariables = createSelector(
    selectComponentVariablesState,
    fromComponentVariables.selectAll,
);

export const selectComponentVariablesByIds = (ids: number[]) => createSelector(
    selectAllComponentVariables,
    (variables) => variables ? variables.filter((item) => ids.includes(item.id)) : []
);
