
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { LayerTypes } from '@features/geo/structure/geo.structure';
import { IGenericCObject } from '@features/charts2/interfaces';

export interface ICmsChartGeoLayer {
    slug: string;
    type: string;
    source: LayerTypes;
    level?: number;
    version?: number;
    params?: string[];
    resolution?: string;
    rules?: IGenericCObject<string>;
}

export interface IChartGeoLayer extends ICmsChartGeoLayer {
    chart_id: string;
    url: string;
    urls?: { url: string, keys: { id: string, name: string } }[];
    order?: number;
    keys: {
        parent: string;
        id: string;
        name: string;
    };
}

export function selectCmsChartGeoLayerId(param: Partial<IChartGeoLayer>): string {
    return param.chart_id + '_' + param.slug;
}

export const cmsChartGeoAdapter: EntityAdapter<IChartGeoLayer> =
    createEntityAdapter<IChartGeoLayer>({
        selectId: selectCmsChartGeoLayerId
    });

export interface CmsChartGeoState extends EntityState<IChartGeoLayer> {}
