import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ICmsChart } from '@features/general/charts/structure';

export function selectCmsChartId(chart: ICmsChart): string {
    return chart.id || chart.uid;
}

export const cmsChartAdapter: EntityAdapter<ICmsChart> =
    createEntityAdapter<ICmsChart>({
        selectId: selectCmsChartId
    });

export interface CmsChartsChartsState extends EntityState<ICmsChart>{}

export class CmsChartsEntityHelper {
    static cloneChart = (chart: ICmsChart): ICmsChart => {
        const order = Date.now();
        const id = chart.uid + '_' + order;
        return { ...chart, id, order };
    }
}
