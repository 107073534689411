import { Action } from '@ngrx/store';

export enum C3CityActionTypes {
    C3CityRequested = '[C3 City] City Data Requested',
    C3CityProfileDataLoaded = '[C3 City] City Profile data loaded',
    C3CitySetComponent = '[C3 City] Set City active component',
    C3CitySetActiveCode = '[C3 City] Set active city',
    C3CitySetOverallPerformanceGroupCode = '[C3 City] Set overall performance active group code',
    C3CitySetOverallPerformanceComponentId = '[C3 City] Set overall performance active component id',
    C3CitySetPerformanceByDimensionGroupCode = '[C3 City] Set performance by dimension group code',
    C3CityAddPerformanceByDimensionActiveDimension = '[C3 City] Add performance by dimension active dimension',
    C3CityRemovePerformanceByDimensionActiveDimension = '[C3 City] Remove performance by dimension active dimension',
}

// City page actions
export class C3CityRequested implements Action {
    readonly type = C3CityActionTypes.C3CityRequested;
    constructor(public payload: { indice: string, city: string }) {}
}
export class C3CitySetActiveCode implements Action {
    readonly type = C3CityActionTypes.C3CitySetActiveCode;
    constructor(public payload: {code: string}) {}
}
export class C3CityProfileDataLoaded implements Action {
    readonly type = C3CityActionTypes.C3CityProfileDataLoaded;
    constructor(public payload: { descriptions: string[], code: string, selectionCriteriaType: string, mainImage: any }) {}
}
export class C3CitySetComponent implements Action {
    readonly type = C3CityActionTypes.C3CitySetComponent;
    constructor(public payload: {id: number}) {}
}
export class C3CitySetOverallPerformanceGroupCode implements Action {
    readonly type = C3CityActionTypes.C3CitySetOverallPerformanceGroupCode;
    constructor(public payload: {code: string}) {}
}
export class C3CitySetOverallPerformanceComponentId implements Action {
    readonly type = C3CityActionTypes.C3CitySetOverallPerformanceComponentId;
    constructor(public payload: {id: number}) {}
}
export class C3CitySetPerformanceByDimensionGroupCode implements Action {
    readonly type = C3CityActionTypes.C3CitySetPerformanceByDimensionGroupCode;
    constructor(public payload: {code: string}) {}
}
export class C3CityAddPerformanceByDimensionActiveDimension implements Action {
    readonly type = C3CityActionTypes.C3CityAddPerformanceByDimensionActiveDimension;
    constructor(public payload: {id: number}) {}
}
export class C3CityRemovePerformanceByDimensionActiveDimension implements Action {
    readonly type = C3CityActionTypes.C3CityRemovePerformanceByDimensionActiveDimension;
    constructor(public payload: {id: number}) {}
}

export type C3CityActions = C3CityRequested
    | C3CityProfileDataLoaded
    | C3CitySetComponent
    | C3CitySetActiveCode
    | C3CitySetOverallPerformanceGroupCode
    | C3CitySetOverallPerformanceComponentId
    | C3CitySetPerformanceByDimensionGroupCode
    | C3CityAddPerformanceByDimensionActiveDimension
    | C3CityRemovePerformanceByDimensionActiveDimension;
