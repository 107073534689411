import { Injectable } from '@angular/core';
import { IEdition } from '@shared/interfaces';
import { SharedService } from '@shared/shared.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class EditionsService extends SharedService {

    // indices base url
    private baseUrl = this.getApiUrl() + 'indices';

    getEditionByCode(code: string, year: number): Observable<IEdition[]> {
        const url = this.createUrl([code, year]);
        return this.getEditionByUrl(url);
    }

    getEditionByUrl(url: string): Observable<IEdition[]> {
        return this.getByUrl<IEdition>(url)
          .pipe(
              map((res) => [res]),
          );
    }

    getEditionsByUrl(url: string): Observable<IEdition[]> {
        return this.getByUrl<IEdition[]>(url);
    }

    getEditionsByIndice(indice: string): Observable<IEdition[]> {
        const url = this.editionsUrl(indice);
        return this.getByUrl<IEdition[]>(url);
    }

    getLastEdition(code: string): Observable<IEdition[]> {
        const url = this.lastEditionUrl(code);
        return this.getEditionByUrl(url);
    }

    private lastEditionUrl(code: string): string {
        return this.createUrl([code, 'lastEdition']);
    }

    private editionsUrl(code: string): string {
        return this.createUrl([code, 'editions']);
    }

    private createUrl(params: Array<string | number>): string {
        return [this.baseUrl, ...params].join('/');
    }

}
