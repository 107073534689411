
export enum DHUnitIndicesSorting {
    Median = 'Median',
    National = 'National Score',
}

export enum DataHubSearchFilterOptions {
    ALL = '',
    Indicators = 'Indicators',
    Scoreboards = 'Scoreboards',
    Authors = 'Authors',
    Categories = 'Categories',
    Countries = 'Countries',
    Regions = 'Regions',
    Cities = 'Cities'
}

export enum DataHubSearchResultTypes {
    Coin = 'Composite Indicator',
    Scoreboard = 'Scoreboard',
    Country = 'Country',
    Dev = 'Developer',
    Category = 'Category',
    Region = 'Region',
    City = 'City'
}

export enum DataHubChartsTypes {
    range = 'range',
    column = 'column',
    columnstat = 'column with stat line',
    heatmap = 'heatmap',
    heatmapDot = 'heatmap-dot',
    heatmapDotGradient = 'heatmap-dot-gradient',
    scatter = 'scatterplot',
    ordinalScatter = 'ordinal-scatter',
    rangeScatter = 'range-scatter',
    map = 'map',
    horizontalBar = 'horizontal-bar',
    correlationMatrix = 'correlation-matrix',
    dotPlot = 'dot-plot',
    dotRange = 'dot-range',
    layeredMap = 'layered-map',
    line = 'line',
    mapWithMenu = 'map-with-menu',
    lollipop = 'lollipop',
    swarm = 'swarm',
    lineBackground = 'line-background'
}

export enum DataHubStatisticsTypes {
    medianEU = 'EU median',
    median = 'Overall median'
}
