import { Action } from '@ngrx/store';
import { IResource } from '@shared/interfaces';

export enum ResourcesActionTypes {
    ResourcesRequestedForOneElement = '[Resource] Resources Requested for One Element',
    ResourcesRequestedForMultipleElements = '[Resource] Resources Requested for Multiple Elements',
    ResourcesLoaded = '[API Resource] Resources Loaded',
}

export class ResourcesRequestedForOneElement implements Action {
    readonly type = ResourcesActionTypes.ResourcesRequestedForOneElement;

    constructor(public payload: { resourceId: number, resourceType: string, url?: string }) {}
}

export class ResourcesRequestedForMultipleElements implements Action {
    readonly type = ResourcesActionTypes.ResourcesRequestedForMultipleElements;

    constructor(public payload: Array<{ resourceId: number, resourceType: string, url?: string }>) {}
}

export class ResourcesLoaded implements Action {
    readonly type = ResourcesActionTypes.ResourcesLoaded;

    constructor(public payload: { resources: IResource[], resourcesLoaded: string }) {}
}

export type ResourcesActions =
    ResourcesRequestedForOneElement
    | ResourcesRequestedForMultipleElements
    | ResourcesLoaded;
