<div class="ecl-row">
    <!-- BEGIN TABLE LAYOUT-->
    <div class="ecl-col-m-12 {{config.mobileLayout === TableMultilevelComponentMobileLayoutTypes.LIST_TYPE  ? 'ecl-u-d-none ecl-u-d-m-block' : ''}}" *ngIf="!mobileOnly">
        <div [ngClass]="{'table-multilevel-wide-screen': true, 'mobile-layout': config.mobileLayout === TableMultilevelComponentMobileLayoutTypes.TABLE_TYPE && level === 0 && isMobileWidth()}">
            <div>
                <div class="table-multilevel-wide-screen__head" *ngIf="this.data.head">
                    <div class="table-multilevel-wide-screen__head__data-item" *ngFor="let headItem of this.data.head; let i = index;" [ngStyle]="{width: calcCellWidth(i, this.data.head.length)}">
                        <!--                    TODO same generated element as for desktop-->
                        <div class="ecl-u-type-heading-5">
                            <div class="table-multilevel-wide-screen__cell-layout">
                                <app-table-multilevel-cell [data]="headItem"></app-table-multilevel-cell>
                            </div>
                        </div>
                    </div>
                    <div class="clearfix"></div>
                </div>
                <div class="table-multilevel-wide-screen__body">
                <div [ngClass]="{'table-multilevel-wide-screen__body__data-item': true, 'active': bodyItem.child ? bodyItem.child.expanded : false}"
                     *ngFor="let bodyItem of this.data.body">
                    <div class="table-multilevel-wide-screen__body__data-item__values-container">
                        <div class="table-multilevel-wide-screen__body__data-item__values-container__value-container"
                             *ngFor="let dataItem of bodyItem.data; let i = index;"
                             [ngStyle]="{width: calcCellWidth(i, bodyItem.data.length)}"
                             (click)="bodyItem.child && i === 0 ? bodyItem.child.expanded = !bodyItem.child.expanded : null"
                             [ngClass]="{'toggleable': bodyItem.child && i === 0}"
                             (mouseenter)="bodyItem.child ? bodyItem.child.highlight = true : null"
                             (mouseleave)="bodyItem.child ? bodyItem.child.highlight = false : null">
                            <div [ngStyle]="{'margin-left': (level > 0 && i === 0 ? 5 * level : 0) + 'px'}"
                                 class="table-multilevel-wide-screen__cell-layout">
                                <ng-container *ngIf="bodyItem.child && i === 0">
                                    <span class="table-multilevel-wide-screen__body__data-item__values-container__value-container__child-toggle">
                                        <span *ngIf="!bodyItem.child.expanded" class="small"><i class="fa fa-plus"></i></span>
                                        <span *ngIf="bodyItem.child.expanded" class="small"><i class="fa fa-minus"></i></span>
                                    </span>&nbsp;
                                </ng-container>
                                <app-table-multilevel-cell [data]="dataItem"></app-table-multilevel-cell>
                            </div>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                    <ng-container *ngIf="bodyItem.child">
                        <div class="table-multilevel-wide-screen__body__data-item__child-container {{level > 0 ? 'level level-' + (level) : ''}}" *ngIf="bodyItem.child.expanded">
                            <div [ngClass]="{
                                'table-multilevel-wide-screen__body__data-item__child-container__highlight': true,
                                'ecl-u-d-none': !bodyItem.child.highlight || isMobileWidth()
                            }"></div>
                            <app-table-multilevel [data]="bodyItem.child" [config]="config" [level]="level + 1" [wideScreenOnly]="true"></app-table-multilevel>
                        </div>
                    </ng-container>
                </div>
            </div>
            </div>
        </div>
    </div>
    <!-- END TABLE LAYOUT-->

    <!-- BEGIN TABLE AS LIST LAYOUT-->
    <div class="ecl-col-m-12 d-block ecl-u-d-m-none" *ngIf="!wideScreenOnly && config.mobileLayout === TableMultilevelComponentMobileLayoutTypes.LIST_TYPE">
        <div class="table-multilevel-mobile">
            <div *ngFor="let bodyItem of this.data.body; let i = index;" [ngClass]="{'table-multilevel-mobile__item-container': true, 'children-expanded': bodyItem.child ? bodyItem.child.expanded : false}">
                <table class="table">
                    <tbody>
                        <tr *ngFor="let dataItem of bodyItem.data; let k = index;">
                            <th *ngIf="this.data.head || head">
                                <div class="ecl-u-type-heading-5">
                                    <app-table-multilevel-cell [data]="this.data.head ? this.data.head[k] : head ? head[k] : null"></app-table-multilevel-cell>

                                </div>
                            </th>
                            <td>
                                <app-table-multilevel-cell [data]="dataItem"></app-table-multilevel-cell>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div *ngIf="bodyItem.child">
                    <button type="button" class="btn btn-outline-primary ecl-u-width-100 ecl-u-mt-s"
                         *ngIf="!bodyItem.child.expanded"
                         (click)="bodyItem.child.expanded = !bodyItem.child.expanded;">
                        <div class="pull-left">
                            Show children ({{bodyItem.child.body.length}})
                        </div>
                        <div class="pull-right">
                            <i class="fa fa-plus"></i>
                        </div>
                        <div class="clearfix"></div>
                    </button>
                    <button type="button" class="btn btn-outline-primary ecl-u-width-100 ecl-u-mt-s" *ngIf="bodyItem.child.expanded" (click)="bodyItem.child.expanded = !bodyItem.child.expanded;">
                        <div class="pull-left">
                            Hide children ({{bodyItem.child.body.length}})
                        </div>
                        <div class="pull-right">
                            <i class="fa fa-minus"></i>
                        </div>
                        <div class="clearfix"></div>
                    </button>

                    <div *ngIf="bodyItem.child.expanded">
                        <app-table-multilevel [data]="bodyItem.child" [head]="data.head ? data.head : head" [config]="config" [level]="level + 1" [mobileOnly]="true"></app-table-multilevel>
                    </div>

                    <button type="button" class="btn btn-outline-primary ecl-u-width-100 ecl-u-mt-s" *ngIf="bodyItem.child.expanded" (click)="bodyItem.child.expanded = !bodyItem.child.expanded;">
                        <div class="pull-left" >
                            Hide children ({{bodyItem.child.body.length}})
                        </div>
                        <div class="pull-right">
                            <i class="fa fa-minus"></i>
                        </div>
                        <div class="clearfix"></div>
                    </button>
                </div>

                <hr *ngIf="this.data.body[i + 1]">
            </div>
        </div>
    </div>
    <!-- END TABLE AS LIST LAYOUT-->
</div>
