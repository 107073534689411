
import { environment } from 'src/environments/environment';
import {IChartGeoLayer, ICmsChartGeoLayer} from '@features/general/charts/state/geo/cms-chart-geo.entity';
import CmsChartParamsHelper from '@features/general/charts/state/params/cms-chart-params-helper';
import { ListsCmsHelper } from '@features/general/lists/structure';
import {Polygon, Rectangle} from "@features/geo/structure/geo.types";
import {EnvService} from "@shared/services/env.service";

export enum GeoBasePathType {
    local = 'local',
    api = 'api'
}

export enum GeoGiscoScales {
    S1 = '01M',
    S3 = '03M',
    S10 = '10M',
    S20 = '20M',
    S60 = '60M'
}

export const GeoBasePath = new Map<string, string>([
    [
        GeoBasePathType.local,
        'assets/geo/'
    ],
    [
        GeoBasePathType.api,
        (new EnvService()).apiUrl
    ]
]);

export enum MapScope {
    world = 'world',
    europe = 'europe',
    organization = 'organization',
    unit = 'unit',
    data = 'units-with-data'
}

// todo:
export enum LayerTypes {
    countries = 'countries',
    nuts = 'Nuts',
    lau = 'LAU',
    urbanAudit = 'UrbanAudit',
    country = 'country',
    indice = 'indice',
    point = 'point',
    site = 'site'
}

export const GeoLayerOrder = new Map<any, number>([
    ['base', 0],
    [LayerTypes.countries, 1],
    [LayerTypes.country, 2],
    [LayerTypes.nuts + '0', 3],
    [LayerTypes.nuts + '1', 4],
    [LayerTypes.nuts + '2', 5],
    [LayerTypes.nuts + '3', 6],
    [LayerTypes.lau, 7],
    [LayerTypes.urbanAudit, 8],
    [LayerTypes.point, 10]
]);

export const GeoLayer = new Map<LayerTypes, any>([
    [
        LayerTypes.countries,
        {
            url: (params) => `CNTR_RG_${params.resolution || GeoGiscoScales.S20}_2020_4326.json`,
            base: GeoBasePathType.local,
            id: 'CNTR_ID',
            name: 'NAME_ENGL',
            keys: {
                id: 'CNTR_ID',
                name: 'NAME_ENGL',
                parent: null
            }
        }
    ],
    [
        LayerTypes.lau,
        {
            url: (params?) => 'lau/all/shapes',
            base: GeoBasePathType.api,
            id: 'id',
            name: 'name',
            keys: {
                id: 'id',
                name: 'name',
                parent: 'country'
            }
        }
    ],
    [
        LayerTypes.nuts,
        {
            url: (params) => `NUTS_RG_${params.resolution || GeoGiscoScales.S10}_${params.version || 2016}_4326_LEVL_${params.level}.json`,
            base: GeoBasePathType.local,
            id: 'NUTS_ID',
            name: 'NUTS_NAME',
            keys: {
                id: 'NUTS_ID',
                name: 'NUTS_NAME',
                parent: 'CNTR_CODE'
            },
            rules: ['level'],
        }
    ],
    [
        LayerTypes.country,
        {
            url: (params) => '/countries/shapes/' + params.code,
            base: GeoBasePathType.api,
            id: 'code',
            name: 'name',
            keys: {
                id: 'code',
                name: 'name',
                parent: null
            },
            rules: ['code']
        }
    ],
    [
        LayerTypes.indice,
        {
            url: (params) => 'list/indice/' + params.index_code + '/shapes',
            base: GeoBasePathType.api,
            id: 'code',
            name: 'name',
            keys: {
                id: 'code',
                name: 'name',
                parent: null
            },
            rules: ['index_code']
        }
    ],
    [
        LayerTypes.urbanAudit,
        {
            url: (params) => `URAU_LB_${params.version || 2018}_4326.json`,
            base: GeoBasePathType.local,
            id: 'URAU_CODE',
            name: 'URAU_NAME',
            keys: {
                id: 'URAU_CODE',
                name: 'URAU_NAME',
                parent: 'CNTR_CODE'
            },
        }
    ]
]);

export const GeoLayerTypes = new Map([
    [
        LayerTypes.site,
        {
            url: (params) => 'indices/site/' + params.index_code.toLowerCase() + '/unit-types?unit_type=' + params.geo_unit_type,
            base: GeoBasePathType.api,
            id: 'code',
            name: 'name',
            rules: ['index_code', 'geo_unit_type']
        }
    ],
    [
        LayerTypes.indice,
        {
            url: (params) => 'indices/' + params.index_code + '/unit-type',
            base: GeoBasePathType.api,
            id: 'id',
            name: 'name',
            rules: ['index_code']
        }
    ]
]);

export class GeoHelper {
    static ValidateRules = (rules: string[], paramsObject: any): boolean => {
        return CmsChartParamsHelper.ValidateRules(rules, paramsObject);
    }

    private static AbstractGetLayerUrl = (type: LayerTypes, params, GeoMap, conversionRules = null): string => {
        const geoMap = GeoMap.get(type);
        let url: string = null;
        const convertedParams = ListsCmsHelper.ConvertParams(params, conversionRules);

        if (!!geoMap && GeoHelper.ValidateRules(geoMap.rules, convertedParams)) {
            url = geoMap.url(convertedParams);
        }

        return url;
    }

    static GetLayerTypeUrl = (type: LayerTypes, params, conversionRules = null): string => {
        return GeoHelper.AbstractGetLayerUrl(type, params, GeoLayerTypes, conversionRules);
    }

    static GetLayerUrl = (type: LayerTypes, params, conversionRules = null): string => {
        return GeoHelper.AbstractGetLayerUrl(type, params, GeoLayer, conversionRules = null);
    }

    static GetLayerOrder = (layer: IChartGeoLayer): number => {
        let id: string;
        switch (layer.type) {
            case LayerTypes.nuts:
                id = LayerTypes.nuts + layer.level;
                break;
            default:
                id = layer.type;
                break;
        }
        return GeoLayerOrder.get(id) || null;
    }

    static IsInsideRectangle = (rectangle: Rectangle, coords: Polygon): boolean => {
        let isInside = true;

        const [[minX, maxY], [maxX, minY]] = rectangle;

        for (let coord of coords) {
            if (coord[0] > maxX || coord[0] < minX || coord[1] < minY || coord[1] > maxY) {
                isInside = false;
                break;
            }
        }

        return isInside;
    }
}
