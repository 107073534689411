import { Injectable } from '@angular/core';
import {IAnswer, IUnit} from '@shared/interfaces';
import { SharedService } from '@shared/shared.service';
import { Observable } from 'rxjs';

@Injectable()
export class UnitsService extends SharedService {

    unitsUrl = this.getApiUrl() + 'units/';

    getUnitsByUrl(url: string): Observable<IUnit[]> {
        return this.getByUrl<IUnit[]>(url);
    }

    getUnitsByType(type: string): Observable<IAnswer<IUnit[]>> {
        const url = this.getApiUrl() + type;
        return this.getFullAnswerByUrl<IUnit[]>(url);
    }

    getUnitsWithIndicesByType(type: string): Observable<IAnswer<IUnit[]>> {
        const url = this.getApiUrl() + type + '/with-indices';
        return this.getFullAnswerByUrl<IUnit[]>(url);
    }

    getUnitByCode(code: string): Observable<IUnit> {
        const url = this.unitsUrl + code;
        return this.getByUrl<IUnit>(url);
    }

}
