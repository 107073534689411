import { Action } from '@ngrx/store';
import {ListsCmsSlugs} from "@features/general/lists/structure";
import {CmsChartsLoadingState} from "@features/general/charts/structure";
import {ICmsChartHighlight} from "@features/general/charts/state/highlights/cms-chart-highlights.entity";

export enum CmsChartsHighlightsActionTypes {
    CmsChartsSetHighlight = '[CmsCharts highlights] Set highlight for one chart',
    CmsChartsResetHighlight = '[CmsCharts highlights] reset highlight',
    CmsChartsResetHighlights = '[CmsCharts highlights] reset all highlights for one chart',
    CmsChartsAddHighlight = '[CmsCharts highlights] Add highlight',
    CmsChartRemoveHighlight = '[CmsCharts highlights] remove highlight',
    CmsChartToggleHighlight = '[CmsCharts highlights] toggle highlight',
    CmsChartsSetHighlightsUrls = '[CmsCharts highlights] set highlights urls',
    CmsCHartsUpdateHighlights = '[CmsCharts highlights] update highlights',
    CmsChartsUpdateHighlightsUrls = '[CmsCharts highlights][CmsChartsUpdateHighlightsUrls] update highlights urls',
}

export class CmsChartsSetHighlight implements Action {
    readonly type = CmsChartsHighlightsActionTypes.CmsChartsSetHighlight;
    constructor (public payload: { chart_id: string, param: string, values: any[], status?: CmsChartsLoadingState }) {}
}

export class CmsChartsResetHighlight implements Action {
    readonly type = CmsChartsHighlightsActionTypes.CmsChartsResetHighlight;
    constructor (public payload: { chart_id: string, param: string, status?: CmsChartsLoadingState }) {}
}

export class CmsChartsResetHighlights implements Action {
    readonly type = CmsChartsHighlightsActionTypes.CmsChartsResetHighlights;
    constructor (public payload: { chart_id: string }) {}
}

export class CmsChartsAddHighlight implements Action {
    readonly type = CmsChartsHighlightsActionTypes.CmsChartsAddHighlight;
    constructor (public payload: { chart_id: string, param: string, value: any }) {}
}

export class CmsChartRemoveHighlight implements Action {
    readonly type = CmsChartsHighlightsActionTypes.CmsChartRemoveHighlight;
    constructor (public payload: { chart_id: string, param: string }) {}
}

export class CmsChartToggleHighlight implements Action {
    readonly type = CmsChartsHighlightsActionTypes.CmsChartToggleHighlight;
    constructor (public payload: { chart_id: string, param: string, value: any }) {}
}

export class CmsChartsSetHighlightsUrls implements Action {
    readonly type = CmsChartsHighlightsActionTypes.CmsChartsSetHighlightsUrls;
    constructor(public payload: {chart_id: string, param: string, slug: ListsCmsSlugs, url: string, status?: CmsChartsLoadingState }[]) {}
}

export class CmsCHartsUpdateHighlights implements Action {
    readonly type = CmsChartsHighlightsActionTypes.CmsCHartsUpdateHighlights;
    constructor(public payload: {id: string, changes: Partial<ICmsChartHighlight>}[]){}
}

export class CmsChartsUpdateHighlightsUrls implements Action {
    readonly type = CmsChartsHighlightsActionTypes.CmsChartsUpdateHighlightsUrls;
    constructor(public payload: {id: string, changes: Partial<ICmsChartHighlight>}[]){}
}

export type CmsChartsHighlightsActions = CmsChartsSetHighlight
    | CmsChartsResetHighlight
    | CmsChartsResetHighlights
    | CmsChartsAddHighlight
    | CmsChartRemoveHighlight
    | CmsChartToggleHighlight
    | CmsChartsSetHighlightsUrls
    | CmsCHartsUpdateHighlights
    | CmsChartsUpdateHighlightsUrls;
