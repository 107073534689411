import { Injectable } from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import { effect } from 'signal';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { concatMap, filter, map, mergeMap, withLatestFrom } from 'rxjs/operators';

import {
    AllIndicesLoaded,
    AllIndicesRequested,
    IndiceByCodeRequested,
    IndiceByUrlRequested,
    IndicesActionTypes,
    IndicesByCategoryRequested,
    IndicesLoaded,
} from './indices.actions';

import {
    selectAllIndices,
    selectAllIndicesLoaded,
    selectIndiceByCode,
} from './indices.selectors';
import { IndicesService } from './indices.service';

@Injectable()
export class IndicesEffects {

    loadIndiceByUrl$ = createEffect(() =>this.actions$
        .pipe(
            ofType<IndiceByUrlRequested>(IndicesActionTypes.IndiceByUrlRequested),
            map((request) => request.payload.url),
            mergeMap((url) => this.indicesService.getIndiceByUrl(url)),
            map( (indices) => new IndicesLoaded( { indices })),
        ));

    loadIndicesByCategory$ = createEffect(() =>this.actions$
        .pipe(
            ofType<IndicesByCategoryRequested>(IndicesActionTypes.IndicesByCategoryRequested),
            map((request) => request.payload.category_id),
        //    mergeMap(url => this.indicesService.getIndiceByUrl(url)),
        //    map( indices => new IndicesLoaded( { indices }))
        ), { dispatch: false });

    loadIndiceByCode$ = createEffect(() =>this.actions$
        .pipe(
            ofType<IndiceByCodeRequested>(IndicesActionTypes.IndiceByCodeRequested),
            map((request) => {
                return request.payload.code
            }),
            mergeMap( (code) => {
                return of(code)
                    .pipe(
                        withLatestFrom(this.store.select(selectIndiceByCode(code))),
                        filter(([action, res]) => {
                            return !res
                        }),
                        mergeMap(() => {
                            return this.indicesService.getIndiceByCode(code)
                        }),
                        map((indices) => new IndicesLoaded({indices})),
                    )
            }),
        ));

    allIndicesRequested$ = createEffect(() =>this.actions$
        .pipe(
            ofType<AllIndicesRequested>(IndicesActionTypes.AllIndicesRequested),
            withLatestFrom(this.store.select(selectAllIndicesLoaded)),
            filter(([action, loaded]) => !loaded),
            mergeMap(() => this.indicesService.getAllIndices()),
            concatMap((indices) => [
                new IndicesLoaded( { indices }),
                new AllIndicesLoaded(),
            ]),
        ));

    constructor(private actions$: Actions, private indicesService: IndicesService,
                private store: Store<any>) {}
}
