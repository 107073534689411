import {Component, Input, OnInit} from '@angular/core';
import {BaseComponent} from "@shared/base-classes/base-component";
import {IComponent, IWeightedNode} from "@shared/interfaces";
import {IConPanelActionTypes} from "@shared/components/icon-panels/icon-panels.component";

@Component({
  selector: 'app-icon-panel',
  templateUrl: './icon-panel.component.html',
  styleUrls: ['./icon-panel.component.scss']
})
export class IconPanelComponent extends BaseComponent<IWeightedNode<IComponent>, any, any> implements OnInit{

    @Input('isOpen') set isOpen(open: boolean) {
        this._isOpen = open;
    };

    get isOpen() {
        return this._isOpen;
    }

    private _isOpen = true;

    configUpdate() {

    }

    dataUpdate() {
    }

    initConfig() {
        this.initialConfig = {
            title: {
                color: '#fff'
            }
        };
        this.config = this.initialConfig;
    }

    ngOnInit(): void {
    }

    changeSlider(event) {
        const action = {
            type: IConPanelActionTypes.ChangeSlide,
            payload: event
        };
        this.changed(action);
    }

    togglePanel() {
        const action = {
            type: IConPanelActionTypes.togglePanel,
            payload: !this.isOpen
        };
        this.changed(action);
    }
}
