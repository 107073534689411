import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromData from './statistics.reducers';

import { CoinState } from '../index';

export const selectCoinState = createFeatureSelector<CoinState>('composite');

export const selectStatisticsState = createSelector(
    selectCoinState,
    (state) => state.statistics,
);

export const selectStatisticsStateLoadedUrls = createSelector(
    selectStatisticsState,
    state => state.loadedUrls
);

export const selectAllStatistics = createSelector(
    selectStatisticsState,
    fromData.selectAll,
);

export const selectStatisticsUrlIsLoaded = (url: string) => createSelector(
    selectStatisticsStateLoadedUrls,
    urls => urls.includes(url)
);

