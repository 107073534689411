import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { IUnitEdition } from '@shared/interfaces';
import {
    UnitEditionsActions,
    UnitEditionsActionTypes
} from "@features/composite-indicator/state/unit-editions/unit-editions.actions";

export interface UnitEditionsState extends EntityState<IUnitEdition> {
    loaded: string[];
}

export const adapter: EntityAdapter<IUnitEdition> =
    createEntityAdapter<IUnitEdition>({
        selectId: (ued) => `${ued.indice}_${ued.edition}_${ued.unit_id}`,
    });

export const initialUnitEditionsState: UnitEditionsState = adapter.getInitialState({
    loaded: []
});

const addLoaded = (loaded: string[], newLoad: string): string[] => {
    const loads = loaded.filter(l => l !== newLoad);
    return [...loads, newLoad];
};

const removeLoaded = (loaded: string[], load2rem: string): string[] => {
    const loads = loaded.filter(l => l !== load2rem);
    return loads;
};

export function unitEditionsReducer(state = initialUnitEditionsState , action: UnitEditionsActions): UnitEditionsState {

    switch (action.type) {
    case UnitEditionsActionTypes.UnitEditionsLoaded:
        // adapter.removeAll(state);
        return adapter.setAll(action.payload.data, { ...state, loaded: addLoaded(state.loaded, action.payload.loaded)});
    default: {
            return state;
        }
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal,

} = adapter.getSelectors();
