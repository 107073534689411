import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromCategoryGroups from './category-groups.reducers';

import { CoinState } from '../index';
import {ArrayHelper} from "@shared/helpers";
import {selectAllCategoriesForDesktop} from "@features/composite-indicator/state/status/composite-indicators.selectors";

export const selectCoinState = createFeatureSelector<CoinState>('composite');

export const selectCategoryGroupsState = createSelector(
    selectCoinState,
    (state) => state.category_groups,
);

export const selectAllCategoryGroups = createSelector(
    selectCategoryGroupsState,
    fromCategoryGroups.selectAll,
);

export const selectAllCategoryGroupsFlattened = createSelector(
    selectAllCategoryGroups,
    (groups) => ArrayHelper.flatten(groups, 'id')
);

export const selectCategoryGroupById = (id: number) => createSelector(
    selectAllCategoryGroupsFlattened,
    (groups) => groups.hasOwnProperty(id) ? groups[id] : null,
);

export const selectAllCategoryGroupsLoaded = createSelector(
    selectCategoryGroupsState,
    (categoryGroupsState) => categoryGroupsState.allCategoryGroupsLoaded,
);

export const selectAllCategoryGroupsFlattenedWithCategories = createSelector(
    selectAllCategoryGroupsFlattened,
    selectAllCategoriesForDesktop,
    (groups, categories) => {
        let arr = [];
        try {
            for (let id in groups) {
                if (!groups.hasOwnProperty(id)) {
                    continue;
                }
                arr[id] = categories.filter(cat => cat.group_id === parseInt(id));
            }
        } catch (e) {
        }
        return arr;
    }
);

export const selectCategoryGroupCategoriesById = (id: number) => createSelector(
    selectAllCategoryGroupsFlattenedWithCategories,
    (categoryGroups) => categoryGroups ? categoryGroups[id] || null : null
);
