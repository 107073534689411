import {Action} from '@ngrx/store';

export enum CmsChartsCategoriesActionTypes {
    CmsChartsCategoriesSetUrls = '[CmsCharts categories] Set urls for a chart categories',
}

export class CmsChartsCategoriesSetUrls implements Action {
    readonly type = CmsChartsCategoriesActionTypes.CmsChartsCategoriesSetUrls;
    constructor (public payload: { id: string, changes: { url: string } }[]) {}
}



export type CmsChartsCategoriesActions = CmsChartsCategoriesSetUrls;
