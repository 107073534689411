import { Action } from '@ngrx/store';
import { IChartGeoLayer, ICmsChartGeoLayer } from '@features/general/charts/state/geo/cms-chart-geo.entity';

export enum CmsChartsGeoActionTypes {
    CmsChartsGeoSetLayers = '[CmsCharts geo] Set layers for one chart',
    CmsChartsGeoSetLayerUrl = '[CmsCharts geo] Set url for a layer',
    CmsChartsGeoSetLayerUrls = '[CmsCharts geo] Set urls for a layer',
    CmsChartsGeoSetLayersUrl = '[CmsCharts geo] Set url for multiple layers',
    CmsChartsGeoSetLayersUrls = '[CmsCharts geo] Set urls for multiple layers',
    CmsChartsGeoRemoveLayer = '[CmsCharts geo] Removes a layer for a chart',
    CmsChartsGeoUpdateLayer = '[CmsCharts geo] Update one layer',
    CmsChartsGeoUpdateLayers = '[CmsCharts geo] Update multiple layers'
}

export class CmsChartsGeoSetLayers implements Action {
    readonly type = CmsChartsGeoActionTypes.CmsChartsGeoSetLayers;
    constructor (public payload: { chart_id: string, layers: ICmsChartGeoLayer[] }) {}
}

export class CmsChartsGeoSetLayerUrl implements Action {
    readonly type = CmsChartsGeoActionTypes.CmsChartsGeoSetLayerUrl;
    constructor (public payload: { layer_id: string, url: string, keys: { id: string, name: string } }) {}
}

export class CmsChartsGeoSetLayerUrls implements Action {
    readonly type = CmsChartsGeoActionTypes.CmsChartsGeoSetLayerUrls;
    constructor (public payload: { layer_id: string, urls: { url: string, keys: { id: string, name: string }, order: number }[] }) {}
}

export class CmsChartsGeoSetLayersUrl implements Action {
    readonly type = CmsChartsGeoActionTypes.CmsChartsGeoSetLayersUrl;
    constructor (public payload: { layer_id: string, url: string, keys: { id: string, name: string } }[]) {}
}

export class CmsChartsGeoSetLayersUrls implements Action {
    readonly type = CmsChartsGeoActionTypes.CmsChartsGeoSetLayersUrls;
    constructor (public payload: { layer_id: string, urls: { url: string, keys: { id: string, name: string }, order: number }[] }[]) {}
}

export class CmsChartsGeoRemoveLayer implements Action {
    readonly type = CmsChartsGeoActionTypes.CmsChartsGeoRemoveLayer;
    constructor (public payload: { id: string }) {}
}

export class CmsChartsGeoUpdateLayer implements Action {
    readonly type = CmsChartsGeoActionTypes.CmsChartsGeoUpdateLayer;
    constructor(public payload: { id: string, changes: Partial<IChartGeoLayer>}) {}
}

export class CmsChartsGeoUpdateLayers implements Action {
    readonly type = CmsChartsGeoActionTypes.CmsChartsGeoUpdateLayers;
    constructor(public payload: { id: string, changes: Partial<IChartGeoLayer>}[]) {}
}

export type CmsChartsGeoActions = CmsChartsGeoSetLayers
    | CmsChartsGeoSetLayerUrl
    | CmsChartsGeoSetLayerUrls
    | CmsChartsGeoSetLayersUrl
    | CmsChartsGeoSetLayersUrls
    | CmsChartsGeoRemoveLayer
    | CmsChartsGeoUpdateLayer
    | CmsChartsGeoUpdateLayers;
