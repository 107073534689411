import { Action } from '@ngrx/store';
import { C3PoliciesActiveTypes } from '@features/cultural-creative-cities/state/policies/c3.policies.structure';
import { IC3PolicyCategory, IC3PolicyMeasure } from '@features/cultural-creative-cities/state/policies/c3.policies.interfaces';

export enum C3PoliciesActionTypes {
    C3PoliciesRequestData = '[C3 Policies] Request data',
    C3PoliciesRequestCategories = '[C3 Policies] Request categories',
    C3PoliciesSetData = '[C3 Policies] Set data',
    C3PoliciesSetCategories = '[C3 Policies] Set categories',
    C3PoliciesSetUnitFilter = '[C3 Policies] Set unit filter',
    C3PoliciesSetActiveFilter = '[C3 Policies] Set active filter',
    C3PoliciesResetFilters = '[C3 Policies] Reset filters',
}

export class C3PoliciesRequestData implements Action {
    readonly type = C3PoliciesActionTypes.C3PoliciesRequestData;
}

export class C3PoliciesRequestCategories implements Action {
    readonly type = C3PoliciesActionTypes.C3PoliciesRequestCategories;
}

export class C3PoliciesSetData implements Action {
    readonly type = C3PoliciesActionTypes.C3PoliciesSetData;
    constructor(public payload: {data: IC3PolicyMeasure[]}) {}
}

export class C3PoliciesSetCategories implements Action {
    readonly type = C3PoliciesActionTypes.C3PoliciesSetCategories;
    constructor(public payload: {categories: IC3PolicyCategory[]}) {}
}

export class C3PoliciesSetUnitFilter implements Action {
    readonly type = C3PoliciesActionTypes.C3PoliciesSetUnitFilter;
    constructor(public payload: {unit: string}) {}
}

export class C3PoliciesSetActiveFilter implements Action {
    readonly type = C3PoliciesActionTypes.C3PoliciesSetActiveFilter;
    constructor(public payload: { active: string }) {}
}

export class C3PoliciesResetFilters implements Action {
    readonly type = C3PoliciesActionTypes.C3PoliciesResetFilters;
}

export type C3PoliciesActions = C3PoliciesRequestData
    | C3PoliciesRequestCategories
    | C3PoliciesSetData
    | C3PoliciesSetCategories
    | C3PoliciesSetUnitFilter
    | C3PoliciesSetActiveFilter
    | C3PoliciesResetFilters;
