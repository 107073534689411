export const environment = {
    title: 'oc-nginx-production',
    showEnvTitleOnLogo: true,
    configFilePath: 'assets/openid-login-config.json',
    production: false,
    enableDevToolRedux: true,
    apiUrl: 'https://coin-nginx-reverse-proxy-main-coin.apps.ocp.jrc.ec.europa.eu/api/v1/{{locale}}/',
    cmsApiUrl: 'https://coin-nginx-reverse-proxy-main-coin.apps.ocp.jrc.ec.europa.eu/api/v1/{{locale}}/cms',
    i18nUrl: 'https://coin-nginx-reverse-proxy-main-coin.apps.ocp.jrc.ec.europa.eu/api/v1/{{locale}}/cms/i18n',
    cmsStorageUrl: 'https://coin-nginx-reverse-proxy-main-coin.apps.ocp.jrc.ec.europa.eu/assets/storage',
    enableAnalyticsTracking: false,
    matomoUrl: null,
    matomoSiteId: null,
    enableGoogleAnalyticsTracking: false,
    googleAnalyticsSiteId: null,
};
