import { Action } from '@ngrx/store';

export enum HeadActionTypes {
    HeadSetTitle = '[HEAD] Set head title',
    HeadSetDescription = '[HEAD] Set head description',
    HeadSetKeywords = '[HEAD] Set head keywords',
}

export class HeadSetTitle implements Action {
    readonly type = HeadActionTypes.HeadSetTitle;
    constructor(public payload: {title: string}) {}
}

export class HeadSetDescription implements Action {
    readonly type = HeadActionTypes.HeadSetDescription;
    constructor(public payload: {description: string}) {}
}

export class HeadSetKeywords implements Action {
    readonly type = HeadActionTypes.HeadSetKeywords;
    constructor(public payload: {keywords: string}) {}
}

export type HeadActions = HeadSetTitle
    | HeadSetDescription
    | HeadSetKeywords;
