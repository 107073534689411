
export interface IChartV3 {
    title: string;
    key: string;
    baseUrl: string;
    uid: string;

    api: string;
}

/**
 * This helper converts cms modules into standalone charts, providing methods to help with the integration in angular
 */
export class ChartV3 implements IChartV3 {
    title: string = '';
    key: string;
    baseUrl = '';
    uid = '';

    api = '';

    params: string[] = [];

    chartParams = {};

    separator = '';
    paramsSeparator = '?';

    convert: any = {};

    private isValid: boolean = undefined;

    url = () => {
        if (this.paramsAreValid) {
            return this.api + this.separator + this.uid + this.paramsSeparator + this.paramsUrl;
        } else {
            return null;
        }

    }

    constructor(chart: Partial<IChartV3>) {
        Object.assign(this, chart);

        this.separator = this.api.slice(-1) === '/' ? '' : '/';
        this.paramsSeparator = this.uid.indexOf('?') === -1 ? '?' : '&';
        this.isValid = undefined;
    }

    setParam = (param: string, value: string | number | null) => {
        this.chartParams[param] = value;
        this.isValid = undefined;
    }

    /**
     * use when cms parameters are different from the chart parameters
     * eg: index_code = code
     * @param cmsParam
     * @param value
     */
    setCmsParam = (cmsParam: string, value: string | number | null) => {
        const param = this.convert.hasOwnProperty(cmsParam) ? this.convert[cmsParam] : cmsParam;
        this.setParam(param, value);
    }

    get paramsAreValid() {
        if (this.isValid === undefined) {
            let valid = true;
            for (const param of this.params) {
                const chartParam = this.chartParams?.[param];
                if (chartParam === undefined || chartParam === null) {
                    valid = false;
                    break;
                }
            }
            this.isValid = valid;
        }
        return this.isValid;
    }

    get paramsUrl() {
        if (!this.paramsAreValid) {
            return null;
        }

        return this.params.reduce((url, param) => {
            const paramUrl = this.convertParamToString(param);
            return url + paramUrl + '?';
        }, '').slice(0, -1);
    }

    private convertParamToString = (param: string): string => {
        if (this.chartParams.hasOwnProperty(param)) {
            return param + '=' + this.chartParams[param];
        } else {
            return '';
        }

    }
}
