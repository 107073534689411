import {ICmsChartParameter} from '@features/general/charts/state/main/entities';

import {
    CmsChartsCategoriesHelper,
    ICmsChartsCategoriesEntity
} from '@features/general/charts/state/categories/cms-chart-categories.entity';

import {
    ICmsChartFilterElem
} from '@features/general/charts/state/filters/cms-chart-filters.entity';
import {
    CmsChartsSeriesHelper,
    ICmsChartSeriesEntity
} from '@features/general/charts/state/series/cms-chart-series.entity';
import {selectCmsChartId} from '@features/general/charts/state/main/entities/cms-chart.entity';
import {ICmsChart, ICmsChartFilter, ICmsChartsData} from '@features/general/charts/structure/charts.interfaces';
import {IList, IListElem} from '@features/general/lists/structure/lists.interfaces';

import {ArrayHelper, ContentHelpers} from '@shared/helpers';
import {
    CmsChartLoadStrategies,
    CmsChartsLoadingState
} from '@features/general/charts/structure/cms-charts.structure';
import {IGeoElem} from '@features/geo/structure/geo.entity';
import {IChartGeoLayer} from '@features/general/charts/state/geo/cms-chart-geo.entity';
import {ICmsChartHighlight} from "@features/general/charts/state/highlights/cms-chart-highlights.entity";
import {CmsChartsHighlightsHelper} from "@features/general/charts/state/highlights/cms-chart-highlights.helper";
import CmsChartsFiltersHelper from "@features/general/charts/state/filters/cms-charts-filters.helper";

/**
 * @class CmsChartsHelper
 * The class contains methods to deal with charts an their properties, such as filters and params
 */
export class CmsChartsHelper {

    static cmsToEclCharts = (charts: ICmsChart[], series: ICmsChartSeriesEntity[], filters: ICmsChartFilterElem[],
                             categories: ICmsChartsCategoriesEntity[], data: ICmsChartsData[], lists: IList[],
                             params: ICmsChartParameter[], geo: IGeoElem[], layers: IChartGeoLayer[],
                             highlights: ICmsChartHighlight[]) => {
        return charts.map((chart) => CmsChartsHelper.cmsToEclChart(chart, series, filters, categories, data, lists, params, geo, layers, highlights));
    }

    static chartIsLoaded = (loadingState: CmsChartsLoadingState): boolean => {
        let res: boolean;

        if (loadingState === CmsChartsLoadingState.loaded || loadingState === CmsChartsLoadingState.initial) {
            res = true;
        } else {
            res = false;
        }

        return res;
    }

    static chartPartialStatus = (elems): CmsChartsLoadingState => {
        let status: CmsChartsLoadingState;

        for (const elem of elems) {
            if (elem.status === CmsChartsLoadingState.error) {
                status = CmsChartsLoadingState.error;
                break;
            }
            if (elem.status !== CmsChartsLoadingState.loaded) {
                status = elem.status;
                break;
            }
        }
 //       console.log(elems);
        return status || CmsChartsLoadingState.loaded;
    }

    static chartParamsStatus = (params): CmsChartsLoadingState => {
        let status: CmsChartsLoadingState;

        for (const param of params) {
            if (param.value == null && param.strategy === CmsChartLoadStrategies.none) {
                status = CmsChartsLoadingState.awaiting;
                break;
            } else if (param.value == null) {
                status = CmsChartsLoadingState.loading;
            }
        }

        return status || CmsChartsLoadingState.loaded;
    }

    static chartLoadingState = (data, params_id = '', params): CmsChartsLoadingState => {
        let status: CmsChartsLoadingState;

        const cat_status = CmsChartsHelper.chartPartialStatus(Object.values(data.categories || {}));
        const series_status = CmsChartsHelper.chartPartialStatus(data.series);
        const params_status = CmsChartsHelper.chartParamsStatus(params);

        if (params_status !== CmsChartsLoadingState.loaded) {
            status = params_status;
          //  status = CmsChartsLoadingState.loading;
        } else if ((cat_status === CmsChartsLoadingState.error) || (series_status === CmsChartsLoadingState.error)) {
            status = CmsChartsLoadingState.error;
        } else if ((cat_status === CmsChartsLoadingState.loading) || (series_status === CmsChartsLoadingState.loading)) {
            status = CmsChartsLoadingState.loading;
        } else if (cat_status !== CmsChartsLoadingState.loaded) {
            status = cat_status;
        } else if (series_status !== CmsChartsLoadingState.loaded) {
            status = series_status;
        }/* else if (params_status === CmsChartsLoadingState.awaiting) {
            status = params_status;
        }*/
          else {
            status = CmsChartsLoadingState.loaded;
        }

        if (status === CmsChartsLoadingState.initial && params_id !== '') {
            status = CmsChartsLoadingState.loading;
        }

        return status || CmsChartsLoadingState.loaded;
    }

    static ParamsToId = (params: ICmsChartParameter[]): string => {
        let id = '';

        for (const elem of (params || [])) {
            if (elem.value !== null) {
                id += elem.value + '-';
            }
        }

        return id.slice(0, -1);
    }

    static cmsToEclChart = (chart: ICmsChart, series: ICmsChartSeriesEntity[], filters: ICmsChartFilterElem[],
                            categories: ICmsChartsCategoriesEntity[], data: ICmsChartsData[], lists: IList[],
                            params: ICmsChartParameter[], geo: IGeoElem[], layers: IChartGeoLayer[], highlights: ICmsChartHighlight[]) => {

        if (!chart) { return; }

        const chart_id = selectCmsChartId(chart);

        const c_params = params.filter((param) => param.chart_id === chart_id);
        const param_id = CmsChartsHelper.ParamsToId(c_params);

        // series
        const c_series = series.filter((series_elem) => series_elem.chart_id === chart_id);
        const chart_series = CmsChartsSeriesHelper.multipleConvertToSeries(c_series, data);
        const series_time_stamp = chart_series.reduce((max, current) => Math.max(max, current.time_stamp), 0);
//        console.log(c_series, chart_series);
        // categories
        const c_categories = categories.filter((categories_elem) => categories_elem.chart_id === chart_id);
        const chart_categories = CmsChartsCategoriesHelper.multipleConvertToCategories(c_categories, data);
        const categories_time_stamp = Object.values(chart_categories).reduce((max, current) => Math.max(max, current.time_stamp), 0);

        // urls
        const c_urls = ArrayHelper.uniquePluck([...c_categories, ...c_series], 'url');

        const c_data = data.filter((d) => c_urls.includes(d.url));
        let time_stamp = Math.max(...ArrayHelper.pluck(c_data, 'time_stamp'));

        // filters
        const c_filters = filters.filter((filter_elem) => filter_elem.chart_id === chart_id);
        const chart_filters = CmsChartsFiltersHelper.multipleConvertToFilters(c_filters, lists, c_params);

        // highlights
        const c_highlights = highlights.filter((highlight) => highlight.chart_id === chart_id);
        const chart_highlights = CmsChartsHighlightsHelper.multipleConvertToHighlights(c_highlights, lists, c_params);

        time_stamp = Math.max(series_time_stamp, categories_time_stamp, time_stamp);

        // Add a layer for each url (layers with more than one url are split into multiple layers with one url)
        const chart_layers = (layers || []).reduce((res, layer: any) => {
            let layer_urls = layer.urls && layer.urls || [];
            // remove duplicated urls
            layer_urls = Array.from((new Map(layer_urls.map((d) => [d.url, d]))).values());

            const current_layers = layer_urls.map((url) => {
                const file = geo.find((d) => d.url === url.url);
                return {
                    ...layer,
                    file: file && file.file || null,
                    keys: url.keys,
                    order: url.order,
                    url
                };
            });
            return [...res, ...current_layers];
        }, []);

        const chart_data = { ...chart.data, series: chart_series, categories: chart_categories, time_stamp, param_id, loadingState: null };

        const loadingState = CmsChartsHelper.chartLoadingState(chart_data, param_id, c_params.filter((p) => !p.loadStatusIgnore));
 //      console.log(chart_id, loadingState);
        chart_data.loadingState = loadingState;

        const chartConfig = chart.config || null;

        return {
            ...chart,
            id: chart_id,
            loaded: loadingState === CmsChartsLoadingState.loaded,
            loadingState,
            data: CmsChartsHelper.replaceChartsLabelsWithValues(chart_data, chart_filters, c_params),
            config: chartConfig,
            filters: chart_filters,
            highlights: chart_highlights,
            layers: chart_layers,
            isDuplicatable: chartConfig?.isDuplicatable || false
        };
    }

    static replaceChartsLabelsWithValues = (data, filters, params) => {

        if (!data) { return; }

        const filterVals = [];
        const vals = [];

        for (const filter of filters) {
            filterVals[filter.param] = CmsChartsHelper.getActiveValuesFromFilter(filter, params);
            vals[filter.param] = CmsChartsHelper.getActiveValuesFromFilter(filter)[0];
        }

        for (const param of params) {
            const elem = filterVals[param.origin];
            const val = elem?.find((d) => d.key === param.value);

            vals[param.name] = val || '';
        }

        data = CmsChartsHelper.stripDoubleQuotesFromObjectStringsRecursively(data);

        const replaceValues = CmsChartsHelper.replaceValues(vals);

        const sdata = JSON.stringify(data);

        // @ts-ignore
        return JSON.parse(ContentHelpers.extractAndReplaceVariablesInString(sdata, replaceValues).replaceAll('()', ''));
    }

    static replaceValues = (vals) => (res) => vals[res[0]] && vals[res[0]][res[1]] || '';

    /**
     * @param {any} data
     */
    private static stripDoubleQuotesFromObjectStringsRecursively = (data): any => {
        for (const key in data) {
            if (! data.hasOwnProperty(key)) {
                continue;
            }
            let item = data[key];

            if (Array.isArray(item) || typeof item === 'object') {
                item = CmsChartsHelper.stripDoubleQuotesFromObjectStringsRecursively(item);

            } else if (typeof item === 'string') {
                // @ts-ignore
                item = item.replaceAll('"', '\\"');

                data = { ...data, [key]: item };
            }
        }
        return data;
    }

    private static seriesOtherReplaceVariablesInString = (o: { [_: string]: any; }, replaceValues) => {
        const other = {};

        for (const key in o) {
            if (typeof o[key] === 'object') {
                other[key] = CmsChartsHelper.seriesOtherReplaceVariablesInString(o[key], replaceValues);
            } else if (Array.isArray(o[key])) {
                other[key] = o[key].map((item) => CmsChartsHelper.seriesOtherReplaceVariablesInString(item, replaceValues));
            } else if (typeof o[key] === 'string') {
                other[key] = ContentHelpers.extractAndReplaceVariablesInString(o[key] || '', replaceValues);
            } else {
                other[key] = ContentHelpers.extractAndReplaceVariablesInString(o[key] || '', replaceValues);
            }
        }

        return other;
    }

    private static getActiveValuesFromFilter = (filter: ICmsChartFilter, params?: ICmsChartParameter[]): IListElem[] => {
        params = params || [];
        const fparams = params.filter((param) => param.origin === filter.param);
        const param_keys = fparams.map((param) => param.value);
        const search = (values: IListElem[], keys) => {
            if (!(values && values.length)) { return []; }
            return values.reduce((res, current) => {
                if (keys.includes(current.key)) { res.push(current); }
                return [...res, ...search(current.children, keys)];
            }, []);
        };

        if (!filter) { return null; }
        return search(filter.values, [filter.active, ...param_keys]);

    }

    static setParamsFromList = (params: any[], values: IListElem[]) => {
        const chart_idx = {};
        let idx;
        let value = null;

        const values_with_data = values.filter((v) => !v?.hasOwnProperty('hasData') || v?.hasData);

        const paramsWithValue = params.map((param) => {
            const p_values = CmsChartsFiltersHelper.FilterValuesFromOptions(values_with_data, param.options);
            if (param.value !== null) {
                return param;
            }

            if (p_values.length === 1) {
                return {
                    ...param,
                    value: p_values[0].key
                };
            }

            switch (param.strategy) {
                case CmsChartLoadStrategies.last:
                    if (!chart_idx.hasOwnProperty(param.chart_id)) {
                        chart_idx[param.chart_id] = CmsChartsHelper.getPrevElementWithData(p_values);
                    }

                    idx = chart_idx[param.chart_id];

                    if (idx >= 0) {
                        value = p_values && p_values[idx] && p_values[idx].key || null;
                        chart_idx[param.chart_id] = CmsChartsHelper.getPrevElementWithData(p_values, --idx);
                    }
                    break;
                case CmsChartLoadStrategies.origin:
                    if (param?.default_param?.value != null) {
                        value = param.default_param.value;
                    }
                    break;
                case CmsChartLoadStrategies.random:
                    const selected_idx = Math.round(p_values.length * Math.random());
                    value = p_values[selected_idx]?.key || null;
                    break;
                case CmsChartLoadStrategies.none:
                    value = null;
                    break;
                case CmsChartLoadStrategies.all:
                    value = p_values.map((v) => v.key);
                    break;
                default:
                    if (!chart_idx.hasOwnProperty(param.chart_id)) {
                        chart_idx[param.chart_id] = CmsChartsHelper.getNextElementWithData(p_values);
                    }

                    idx = chart_idx[param.chart_id];

                    if (idx >= 0) {
                        value = p_values && p_values[idx] && p_values[idx].key || null;
                        chart_idx[param.chart_id] = CmsChartsHelper.getNextElementWithData(p_values, ++idx);
                    }
            }

            return {
                ...param,
                value
            };
        });
        return paramsWithValue;
    }

    static getNextElementWithData = (values: IListElem[], firstIndex = 0): number => {
        if (!values || values.length <= firstIndex) {
            return -1;
        } else {
            const value = values[firstIndex];
            if (value && (!value.hasOwnProperty('hasData') || value.hasData)) {
                return firstIndex;
            } else {
                return CmsChartsHelper.getNextElementWithData(values, ++firstIndex);
            }
        }
    }

    static getPrevElementWithData = (values: IListElem[], firstIndex = null): number => {
        if (!firstIndex) {
            firstIndex = (values || []).length - 1;
        }

        if (!values || firstIndex < 0 || firstIndex >= values.length) {
            return -1;
        } else {
            const value = values[firstIndex];
            if (value && (!value.hasOwnProperty('hasData') || value.hasData)) {
                return firstIndex;
            } else {
                return CmsChartsHelper.getNextElementWithData(values, --firstIndex);
            }
        }
    }

    static completeChartWithExtraParams = (charts: any[], params) => {
        const replaceValues = (res) => {
            return params[res[0]] && params[res[0]][res[1]] || '';
        };
        return charts.map((chart) => ({
            ...chart,
            data: { ...chart.data, series: chart.data.series.map((s) => ({
                    ...s,
                    name: ContentHelpers.extractAndReplaceVariablesInString(s.name || '', replaceValues)
                }))
            }
        }));
    }

    static getInitialLoadingState = (chart: ICmsChart): CmsChartsLoadingState => {
        if (chart && chart.options && chart.options.loadStrategy) {
            return chart.options.loadStrategy === CmsChartLoadStrategies.none ? CmsChartsLoadingState.initial : CmsChartsLoadingState.loading;
        } else {
            return CmsChartsLoadingState.loading;
        }
    }

    static getChartLoadStrategies = (chart: ICmsChart) => {
        return chart?.options?.loadStrategies || {};
    }

    /**
     * Get General load strategy.
     * By default returns: first
     * @param chart
     * @return CmsChartLoadStrategies
     */
    static getChartLoadStrategy = (chart: ICmsChart): CmsChartLoadStrategies => {
        return chart?.options?.loadStrategy || CmsChartLoadStrategies.first;
    }

    static getChartParamLoadStrategy = (chart: ICmsChart, param: string): CmsChartLoadStrategies => {
        const strategies = CmsChartsHelper.getChartLoadStrategies(chart);

        const strategy = CmsChartsHelper.getChartLoadStrategy(chart);

        return strategies[param] || strategy;
    }

    static getUrlFromFilterAndParam = (filter, param) => {

    }

}
