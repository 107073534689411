
export enum APIFilters {
    indices_per_unit = 'indices per unit',
    years_per_index = 'years per index'
}

export enum APIBestPracticesScenarioKeys {
    key_best_practice_gdp = 'gdp',
    key_best_practice_hdi = 'hdi'
}
