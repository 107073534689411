export class RequestHelpers {

    public static prepareResponse(response: any) {
        if (response) {
            response = RequestHelpers.ensureResponseToBeWithoutAdditionalDataPropsInside(response);
        }
        return response;
    }

    /**
     * Reason why we are using this method:
     * This is needed because fractal serializer adds "data" for relations on API (Laravel) and any request with data
     * and relations will have this additional "data" that we want to ignore to have clean objects that matches our
     * interfaces without any additional transformation level.
     *
     * Method description:
     * Filters object all props and loops all array items and check for objects with only one property -> "data" which
     * is an array and if it matches these conditions it's replaced with the array itself to remove not needed "data"
     * props.
     *
     * @param obj
     */
    protected static ensureResponseToBeWithoutAdditionalDataPropsInside(obj: any): any {
        obj = RequestHelpers.actualAdditionalDataPropCheckAndReplace(obj);
        if (Array.isArray(obj)) {
            const array = [];
            for (let i = 0; i < obj.length; i++) {
                array.push(RequestHelpers.ensureResponseToBeWithoutAdditionalDataPropsInside(obj[i]));
            }
            obj = array;
        } else if (typeof obj === 'object') {
            const tempObj = {};
            let looped = false;

            let metadata = null;
            let countNumericProps = 0;
            for (const prop in obj) {
                if (!obj.hasOwnProperty(prop)) {
                    continue;
                }
                if (prop === 'metadata') {
                    metadata = obj[prop];
                    continue;
                }
                if (typeof prop === 'number') {
                    countNumericProps++;
                }
                tempObj[prop] = RequestHelpers.ensureResponseToBeWithoutAdditionalDataPropsInside(obj[prop]);
                looped = true;
            }
            if (looped) {
                if (Object.keys(tempObj).length === countNumericProps) {
                    const array = [];
                    for (const prop in tempObj) {
                        if (!tempObj.hasOwnProperty(prop)) {
                            continue;
                        }
                        array.push(obj[prop]);
                    }
                    obj = array;
                } else {
                    obj = Object.assign({}, tempObj);
                }
            }

            if (metadata) {
                obj = {...obj, metadata};
            }
        }
        return obj;
    }

    protected static actualAdditionalDataPropCheckAndReplace(item: any) {
        if (item && typeof item === 'object') {
            if (item.hasOwnProperty('data')) {
                if (Array.isArray(item.data) || typeof item.data === 'object') {
                    let counter = 0;
                    let metadata;
                    for (const prop1 in item) {
                        if (!item.hasOwnProperty(prop1)) {
                            continue;
                        }
                        if (prop1 === 'metadata') {
                            metadata = item[prop1];
                        } else {
                            counter++;
                        }
                    }
                    if (counter === 1) {
                        item = !!metadata ? { ...item.data, metadata } : item.data;
                    }
                }
            }
        }
        return item;
    }
}

