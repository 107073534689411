
import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromGeo from './geo.reducers';
import { GeoState } from './geo.reducers';
import { GeoBaseUrls, GeoLocalFiles } from '@features/geo/structure';

export const selectGeoState = createFeatureSelector<GeoState>('geodata');

export const selectGeoAllData = createSelector(
    selectGeoState,
    fromGeo.selectAll
);

export const selectGeoFilesLoaded = createSelector(
    selectGeoState,
    state => state.urlsLoaded
);

export const selectGeoUrlIsLoaded = (url: string) => createSelector(
    selectGeoFilesLoaded,
    (files) => files.includes(url)
);

export const selectGeoType = (type: GeoLocalFiles) => createSelector(
    selectGeoAllData,
    data => {
        const elem = data.find(d => d.url === GeoBaseUrls.local + type);
        return elem && elem.file;
    }
);

export const selectGeoByType = () => createSelector(
    selectGeoAllData,
    (data) => {
        const id = 'assets/geo/countries.json';
        return data.find((d: any) => d.id === id);
    }
);
