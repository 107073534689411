import { Update } from '@ngrx/entity/src/models';
import { Action } from '@ngrx/store';
import { ICategory } from '@shared/interfaces';

export enum CategoriesActionTypes {
    CategoryRequested = '[Category Page] Category requested',
    CategoryLoaded = '[Category API] Category loaded',
    AllCategoriesRequested = '[All Categories] All Categories requested',
    AllCategoriesLoaded = '[Category API] All lCategories loaded',
    CategoryImageUpdate = '[Category API] Category Image Update',
}

export class AllCategoriesRequested implements Action {
    readonly type = CategoriesActionTypes.AllCategoriesRequested;
}

export class AllCategoriesLoaded implements Action {
    readonly type = CategoriesActionTypes.AllCategoriesLoaded;

    constructor(public payload: { categories: ICategory[]}) {}
}

export class CategoryRequested implements Action {
    readonly type = CategoriesActionTypes.CategoryRequested;

    constructor(public payload: { categoryId: number}) {}
}

export class CategoryLoaded implements Action {
    readonly type = CategoriesActionTypes.CategoryLoaded;

    constructor(public payload: { category: ICategory }) {}
}

export class CategoryImageUpdate implements Action {
    readonly type = CategoriesActionTypes.CategoryImageUpdate;

    constructor(public payload: { category: Update<ICategory> }) {}
}

export type CategoriesActions =
    AllCategoriesLoaded
    | AllCategoriesRequested
    | CategoryLoaded
    | CategoryRequested
    | CategoryImageUpdate;
