import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { IResource } from '@shared/interfaces';
import { ResourcesActions, ResourcesActionTypes } from './resources.actions';

export interface ResourcesState extends EntityState<IResource> {
    resourcesLoaded: string[];
}

export const adapter: EntityAdapter<IResource> =
    createEntityAdapter<IResource>({
        selectId: (resource) => resource.resourceType + resource.id + resource.rel,
    });

export const initiaResourcesState: ResourcesState = adapter.getInitialState({
    resourcesLoaded: [],
});

export function resourcesReducer(state = initiaResourcesState , action: ResourcesActions): ResourcesState {

    switch (action.type) {
    case ResourcesActionTypes.ResourcesLoaded:
        // adapter.removeAll(state);
        const resourcesLoaded = [
                ...state.resourcesLoaded.filter((res) => res !== action.payload.resourcesLoaded),
                action.payload.resourcesLoaded,
            ];
        return adapter.setAll(action.payload.resources, { ...state, resourcesLoaded });
    default: {
            return state;
        }
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal,

} = adapter.getSelectors();
