import { Injectable } from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import { effect } from 'signal';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, filter, map, mergeMap, withLatestFrom } from 'rxjs/operators';

import { AppState } from 'app/core/reducers';
import { getEditionUrlByRel } from '../editions/editions.selectors';
import { IndiceByCodeRequested, IndicesActionTypes, IndicesLoaded } from '../indices/indices.actions';
import {
    CoinCategoriesWResourcesRequested,
    CoinEditionAllDataLoaded,
    CoinEditionAllDataRequested,
    CoinEditionDataLoading,
    CoinEditionLoaded,
    CoinEditionRequested,
    CoinEditionStructureByUrlRequested, CoinLastEditionMainDataRequested,
    CoinLastEditionMainStructureRequested,
    CoinLastEditionUnitsRequested,
    CoinLastEditionUpdated,
    CoinMainStructureByUnitRequested,
    CompositeIndicatorsActionTypes,
} from './composite-indicators.actions';

import { EditionRequested, EditionsActionTypes, EditionsLoaded } from '../editions/editions.actions';

import { CompositeService } from '../../composite.service';
import { AllCategoriesLoaded } from '../categories/categories.actions';
import { selectAllCategoriesLoaded } from '../categories/categories.selectors';
import { ClassesLoaded } from '../classes/classes.actions';
import { ComponentsLoaded } from '../components/components.actions';
import { DataTypesLoaded } from '../data-types/data-types.actions';
import { DataActionTypes, DataByUrlRequested, DataLoaded } from '../data/data.actions';
import { GroupsLoaded } from '../groups/groups.actions';
import {selectAllIndicesLoaded, selectIndiceByCode} from '../indices/indices.selectors';
import { LevelsLoaded } from '../levels/levels.actions';
import { ResourcesLoaded } from '../resources/resources.actions';
import { selectAllResourcesLoaded } from '../resources/resources.selectors';
import { UnitGroupsLoaded } from '../unit-groups/unit-groups.actions';
import { UnitsByUrlRequested, UnitsLoaded } from '../units/units.actions';
import { VariablesLoaded } from '../variables/variables.actions';
import { CoinStatus } from './composite-indicators.reducer';
import {
    selectCoinEditionAllDataLoadedOrLoading,
    selectCoinEditionByDataUrl,
    selectCoinEditionDataIsLoading,
    selectCoinEditionIsBeingRequested,
    selectCoinEditionIsLoaded,
    selectCoinIndiceAfterLoading,
    selectCoinIndiceRequests,
    selectCoinLastEditionIsLoaded,
} from './composite-indicators.selectors';
import { VariableGroupsLoaded } from '@features/composite-indicator/state/variable-groups/variable-groups.actions';
import {
    UnitEditionsByUrlRequested,
    UnitEditionsLoaded
} from '@features/composite-indicator/state/unit-editions/unit-editions.actions';
import { AllCategoryGroupsRequested } from '@features/composite-indicator/state/category-groups/category-groups.actions';
import { LastEditionRoutes, LastEditionUrls } from '@features/composite-indicator/shared/api_routes';
import { selectDataUrlIsLoaded } from '@features/composite-indicator/state/data/data.selectors';
import {selectC3HomeStateYear} from "@features/cultural-creative-cities/state/home/c3.home.selectors";

export class EffectError implements Action {
    readonly type = '[Error] Effect Error';

    constructor(public payload?: { message: string }) {}
}

@Injectable()
export class CompositeIndicatorsEffects {
    private dependencies = {
/*        'indices': IndiceByCodeRequested,
  //      'edition': LastEditionRequested,
        'data': ComponentsByUrlRequested,
        'variables': VariablesByUrlRequested,
        'units': UnitsByUrlRequested,
        'levels': LevelsByUrlRequested
        */
    };

    coinLastEditionRequested$ = createEffect(() =>this.actions$
        .pipe(
            ofType<CoinEditionRequested>(CompositeIndicatorsActionTypes.CoinLastEditionRequested),
            map((request) => {
                return request.payload;
            }),
            mergeMap((request) => of(request)
                .pipe(
                    // withLatestFrom(this.store.select(selectCoinLastEditionIsLoaded(request.indice))),
                    // filter(([request, loaded]) => !loaded),
                    withLatestFrom(this.store.select(selectIndiceByCode(request.indice))),
                    concatMap(([request, indice]) => {
                        let actions = [];
                        if (indice) {
                            actions = [new CoinLastEditionUpdated({ indice: request.indice, edition: indice.latest_edition, allData: request.allData, dataYear: request.dataYear })];
                        } else {
                            /*
                            const qitem = {
                                id: request.indice + '_' + request.edition,
                                type: request.indice + '_' + request.edition,
                                payload: new CoinLastEditionUpdated({ indice: request.indice, edition: indice.latest_edition, allData: request.allData })
                            };

                             */
                            actions = [
                                //    new CoinAddToQueue([qitem]),
                                new IndiceByCodeRequested({ code: request.indice, dataYear: request.dataYear })
                            ];
                        }

                        return actions;
                    }),
                )),
        ));

    coinIndicesLoaded$ = createEffect(() =>this.actions$
        .pipe(
            ofType<IndicesLoaded>(IndicesActionTypes.IndicesLoaded),
            map((request) => {
                return request.payload.indices
            }),
            mergeMap((indices) => indices),
            mergeMap((indice) => of(indice)
                .pipe(
                    withLatestFrom(this.store.select(selectCoinIndiceRequests(indice.code))),
                    filter(([action, requests]) => {
                        return !!requests.length
                    }),
                    map(([action, requests]) => requests),
                    mergeMap((requests) => requests),
                    map((request) => {
                        let action;
                        if (request.isLastEdition) {
                            action = new CoinLastEditionUpdated({ indice: request.indice, edition: indice.latest_edition, dataYear: request.dataYear });
                        } else {
                            action = new EditionRequested(request);
                        }
                        return action;
                    }),
                )),
        ));

    coinLastEditionUpdated$ = createEffect(() =>this.actions$
        .pipe(
            ofType<CoinLastEditionUpdated>(CompositeIndicatorsActionTypes.CoinLastEditionUpdated),
            map((action) => action.payload),
            // mergeMap((update) => of(update)
            //     .pipe(
            //         withLatestFrom(this.store.select(selectCoinEditionIsBeingRequested(update.indice, update.edition))),
            //         filter(([action, requested]) => {
            //             console.log('CoinLastEditionUpdated action', action);
            //             console.log('CoinLastEditionUpdated requested', requested);
            //             return requested;
            //         }),
            //     )),
            mergeMap((action) => of(action)
                .pipe(
                    withLatestFrom(this.store.select(getEditionUrlByRel(action.indice, action.edition, action.allData && action.allData === CoinStatus.MAINSTRUCTURE ? 'mainStructure' : 'structure'))),
                    concatMap(([edition, url]) => {
                        let actions = [];
              //          console.log('CoinLastEditionRequested action', action);
                //        console.log('CoinLastEditionRequested edition, link', edition, url);

                        if (url) {
                            url += 'c3?year=' + action.dataYear;

                            actions.push(new CoinEditionStructureByUrlRequested({ url }));

                            if (action.allData === CoinStatus.REQUESTED) {
                                actions.push(new CoinEditionAllDataRequested({ ...edition, dataYear: action.dataYear }));
                            }
     //                       actions.push(new CoinEditionLoaded(edition));
                        } else {
                            actions = [
                                new EditionRequested(edition)
                            ];
                        }
                        return actions;
                    }),
                )),
        ));

    coinEditionsLoaded$ = createEffect(() =>this.actions$
        .pipe(
            ofType<EditionsLoaded>(EditionsActionTypes.EditionsLoaded),
            map((request) => request.payload.editions),
            mergeMap((editions) => editions),
            map((editions) => of(editions)
                .pipe(
                    withLatestFrom(this.store.select(selectCoinEditionIsBeingRequested(editions.indice, editions.year))),
                    filter(([ed, requested]) => requested),
                    map(([ed, requested]) => ed),
                )),
            mergeMap((editions) => editions),
            mergeMap((edition) => of(edition)
                .pipe(
                    withLatestFrom(this.store.select(selectCoinIndiceAfterLoading(edition.indice, edition.year))),

                )),
            concatMap(([request, status]) => {
                const actions = [];
                actions.push(new CoinEditionStructureByUrlRequested({ url: status.url }));
   //            actions.push(new CoinEditionLoaded({ indice: request.indice, edition: request.year }));
                let dataYear = parseInt(status.url.split('?')[1].split('=')[1]);

                if (status.dataIsRequested) {
                    actions.push(new CoinEditionAllDataRequested({ indice: request.indice, edition: request.year, dataYear: dataYear}));
                }
                return actions;
            }),
        ));

    coinEditionRequested$ = createEffect(() =>this.actions$
        .pipe(
            ofType<CoinEditionRequested>(CompositeIndicatorsActionTypes.CoinEditionRequested),
            map((request) => request.payload),
            mergeMap((request) => of(request)
                .pipe(
                    withLatestFrom(this.store.select(selectCoinEditionIsLoaded(request.indice, request.edition))),
                    filter(([action, loaded]) => !loaded),
                    map(([action, loaded]) => action),
                    withLatestFrom(this.store.select(selectIndiceByCode(request.indice))),
                    withLatestFrom(this.store.select(getEditionUrlByRel(request.indice, request.edition, request.allData && request.allData === CoinStatus.MAINSTRUCTURE ? 'mainStructure' : 'structure'))),
                    map(([[request, indice], url]) => {
                        let action;
                        console.log('CoinEditionRequested', [[request, indice], url]);
                        if (!indice) {
                            action = new IndiceByCodeRequested({ code: request.indice });
                        } else {
                            if (!url) {
                                action = new EditionRequested(request);
                            } else {
                                action = new CoinEditionStructureByUrlRequested({ url });
                            }
                        }
                        return action;
                    }),
                )),
        ));

    coinEditionStructurebyUrlRequested = createEffect(() =>this.actions$
        .pipe(
            ofType<CoinEditionStructureByUrlRequested>(CompositeIndicatorsActionTypes.CoinEditionStructureByUrlRequested),
            map((request) => request.payload.url),
//            map(res => { console.log(res); return res; }),
            mergeMap((url) => this.compositeService.getEditionStructureByUrl(url)
                .pipe(
                    concatMap((answer) => {
                        return [
                            new ComponentsLoaded({ components: answer.components }),
                            new VariablesLoaded({ variables: answer.variables }),
                            new UnitsLoaded({ units: answer.units }),
                            new UnitEditionsLoaded({ data: answer.edition_units, loaded: url }),
                            new LevelsLoaded({ url, levels: answer.levels }),
                            new GroupsLoaded({ groups: answer.groups }),
                            new ClassesLoaded({ classes: answer.classes }),
                            new DataTypesLoaded({ dataTypes: answer.dataTypes }),
                            new UnitGroupsLoaded({ groups: answer.unitGroups }),
                            new VariableGroupsLoaded({ variableGroups: answer.variableGroups } ),
                            new ResourcesLoaded({ resources: answer.resources, resourcesLoaded: url }),
                            new CoinEditionLoaded({ indice: answer.edition.indice, edition: answer.edition.year }),
                        ];
                    }),
                    catchError((err) => of (new EffectError({ message: 'CoinEditionStructureByUrlRequested' }))),
                ),
            ),
        ));

    coinMainStructurebyUnitRequested = createEffect(() =>this.actions$
        .pipe(
            ofType<CoinMainStructureByUnitRequested>(CompositeIndicatorsActionTypes.CoinMainStructureByUnitRequested),
            map((request) => request.payload.code),
//            map(res => { console.log(res); return res; }),
            mergeMap((code) => this.compositeService.getMainStructureByUnit(code)
                .pipe(
                    concatMap((answer) => {
                        return [
                            new IndicesLoaded({ indices: answer.data.indices }),
                            new EditionsLoaded({ editions: answer.data.editions }),
                            new ComponentsLoaded({ components: answer.data.components }),
                            new VariablesLoaded({ variables: answer.data.variables }),
                            new LevelsLoaded({ url: answer.url, levels: answer.data.levels }),
//                            new StatisticsLoaded({ statistics: answer.data.statistics, url: answer.url})
                        ];
                    }),
                    catchError(() => of (new EffectError({ message: 'CoinMainStructureByUnitRequested' }))),
                ),
            ),
        ));

    coinMainStructureRequested = createEffect(() =>this.actions$
        .pipe(
            ofType<CoinMainStructureByUnitRequested>(CompositeIndicatorsActionTypes.CoinMainStructureRequested),
            mergeMap((code) => this.compositeService.getMainStructureAllIndices()
                .pipe(
                    concatMap((answer) => {
                        return [
                            new IndicesLoaded({ indices: answer.data.indices }),
                            new EditionsLoaded({ editions: answer.data.editions }),
                            new ComponentsLoaded({ components: answer.data.components }),
                            new VariablesLoaded({ variables: answer.data.variables }),
                            new LevelsLoaded({ url: answer.url, levels: answer.data.levels }),
                        ];
                    }),
                    catchError(() => of (new EffectError({ message: 'CoinMainStructureRequested' }))),
                ),
            ),
        ));

    lastEditionMainStructureRequested = createEffect(() =>this.actions$
        .pipe(
            ofType<CoinLastEditionMainStructureRequested>(CompositeIndicatorsActionTypes.CoinLastEditionMainStructureRequested),
            map(request => request.payload.code),
            mergeMap(code => of(code)
                .pipe(
                    withLatestFrom(this.store.select(selectCoinLastEditionIsLoaded(code))),
                    filter(([request, isLoaded]) => !isLoaded),
                    mergeMap(() => this.compositeService.getLastEditionMainStructureByIndex(code)),
                    concatMap(answer => {
                        return [
                            new IndicesLoaded( { indices: [answer.data.indice] }),
                            new EditionsLoaded({ editions: [answer.data.edition] }),
                            new VariablesLoaded({ variables: answer.data.variables }),
                            new LevelsLoaded({ url: answer.url, levels: answer.data.levels }),
                            new ComponentsLoaded({ components: answer.data.components }),
                            new ResourcesLoaded({ resources: answer.data.resources, resourcesLoaded: answer.url }),
                        ];
                    }),
                    catchError(() => of(new EffectError({ message: 'Failed to load main structure for last edition' })))
                ))
        ));

    allDataRequested$ = createEffect(() =>this.actions$
        .pipe(
            ofType<CoinEditionAllDataRequested>(CompositeIndicatorsActionTypes.CoinEditionAllDataRequested),
            map((request) => request.payload),
            mergeMap((edRequested) => of(edRequested)
                .pipe(
                    // withLatestFrom(this.store.select(selectCoinEditionAllDataLoadedOrLoading(edRequested.indice, edRequested.edition))),
                    // filter(([action, loaded]) => !loaded),
                    withLatestFrom(this.store.select(getEditionUrlByRel(edRequested.indice, edRequested.edition, 'data'))),
             //       filter(([action, url]) => !!url),
                    map(([action, url]) => url),
                    concatMap((url) => {
                        let actions = [];
                        if (!url) {
                            actions = [
                                new CoinEditionRequested({ ...edRequested, allData: CoinStatus.REQUESTED }),
                            ];
                        } else {

                            if (url.includes('data')) {
                                url = url + 'c3?year=' + edRequested.dataYear;
                            }

                            actions = [
                                new DataByUrlRequested({ url }),
                                new CoinEditionDataLoading({ ...edRequested, url }),
                            ];
                        }
                        return actions;
                    }),
                ),
            ),
        ));

    mainDataRequested$ = createEffect(() =>this.actions$
        .pipe(
            ofType<CoinLastEditionMainDataRequested>(CompositeIndicatorsActionTypes.CoinLastEditionMainDataRequested),
            map((request) => request.payload.code),
            map((code) => this.compositeService.getLastEditionUrlByType(code, LastEditionUrls.MAINDATA)),
            mergeMap((url) => of(url)
                .pipe(
                    withLatestFrom(this.store.select(selectDataUrlIsLoaded(url))),
                    filter(([action, loaded]) => !loaded),
                    map(() => new DataByUrlRequested({ url })),
                ),
            ),
        ));

    allDataLoaded$ = createEffect(() =>this.actions$
        .pipe(
            ofType<DataLoaded>(DataActionTypes.DataLoaded),
            map((request) => request.payload),
            filter((payload) => payload.end),
            mergeMap((payload) => of(payload)
                .pipe(
                    withLatestFrom(this.store.select(selectCoinEditionDataIsLoading(payload.url))),
                    filter(([action, loading]) => loading),
                    withLatestFrom(this.store.select(selectCoinEditionByDataUrl(payload.url))),
                    map(([action, status]) => new CoinEditionAllDataLoaded({ indice: status.indice, edition: status.edition })),
                ),
            ),
        ));

    allCategoriesRequested$ = createEffect(() =>this.actions$
        .pipe(
            ofType<CoinCategoriesWResourcesRequested>(CompositeIndicatorsActionTypes.CoinCategoriesWResourcesRequested),
            withLatestFrom(this.store.select(selectAllCategoriesLoaded)),
            withLatestFrom(this.store.select(selectAllResourcesLoaded('allCategories'))),
            filter(([[action, categoriesLoaded], resourcesLoaded]) => !(categoriesLoaded && resourcesLoaded)),
            mergeMap(() => this.compositeService.getAllCategoriesWithResources()),
            concatMap((res) => [
                new ResourcesLoaded({ resources: res.resources, resourcesLoaded: 'allCategories' }),
                new AllCategoriesLoaded({ categories: res.categories }),
                new AllCategoryGroupsRequested()
            ]),
        ));

    lastEditionUnitsRequested$ = createEffect(() =>this.actions$
        .pipe(
            ofType<CoinLastEditionUnitsRequested>(CompositeIndicatorsActionTypes.CoinLastEditionUnitsRequested),
            map(request => request.payload.code),
            concatMap(code => [
                    new UnitsByUrlRequested({ url: this.lastEditionRoutes.generateUrl(code, LastEditionUrls.UNITS) }),
                    new UnitEditionsByUrlRequested({ url: this.lastEditionRoutes.generateUrl(code, LastEditionUrls.EDITIONUNITS) })
                    ])
        ));

    constructor(
        private actions$: Actions,
        private store: Store<AppState>,
        private compositeService: CompositeService,
        private lastEditionRoutes: LastEditionRoutes
    ) {}
}
