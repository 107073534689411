import { DataHubRoutes } from '@features/data-hub/data-hub-routes';
import { ItemTypesCOINAPI } from '@shared/enums';

export enum DataHubCmsPages {
    HOME_PAGE = 'data-hub-home',
    EXPLORE_PAGE = 'data-hub-explorer',
    INDICE_PROFILE = 'data-hub-indice-profile',
    UNIT_PROFILE = 'data-hub-unit-profile',
    CATEGORY_PROFILE = 'data-hub-category-profile',
    DEVELOPER_PROFILE = 'data-hub-developer-profile',
    SCOREBOARD_PROFILE = 'data-hub-scoreboard-profile',
    TOPICS = 'data-hub-topics',
    STORIES = 'data-hub-stories',
    STORY = 'data-hub-stories',
    FAQ = 'data-hub-faqs',
    ABOUT = 'data-hub-about',
    DATA_POLICY = 'data-hub-data-policy',
    SYNERGIES = 'data-hub-synergies',
}

export const DataHubPagesMap = new Map([
    [
        DataHubRoutes.routes.INDICES,
        {
            cmsPage: DataHubCmsPages.INDICE_PROFILE,
            type: ItemTypesCOINAPI.INDICE,
            key: 'code'
        }
    ],
    [
        DataHubRoutes.routes.COUNTRIES_AND_TERRITORIES,
        {
            cmsPage: DataHubCmsPages.UNIT_PROFILE,
            type: ItemTypesCOINAPI.COUNTRY,
            key: 'code'
        }
    ],
    [
        DataHubRoutes.routes.CITIES,
        {
            cmsPage: DataHubCmsPages.UNIT_PROFILE,
            type: ItemTypesCOINAPI.CITY,
            key: 'code'
        }
    ],
    [
        DataHubRoutes.routes.REGIONS,
        {
            cmsPage: DataHubCmsPages.UNIT_PROFILE,
            type: ItemTypesCOINAPI.REGION,
            key: 'code'
        }
    ],
    [
        DataHubRoutes.routes.CATEGORIES,
        {
            cmsPage: DataHubCmsPages.CATEGORY_PROFILE,
            type: ItemTypesCOINAPI.CATEGORY,
            key: 'id'
        }
    ],
    [
        DataHubRoutes.routes.DEVELOPERS,
        {
            cmsPage: DataHubCmsPages.DEVELOPER_PROFILE,
            type: ItemTypesCOINAPI.DEVELOPER,
            key: 'id'
        }
    ],
    [
        DataHubRoutes.routes.EXPLORER,
        {
            cmsPage: DataHubCmsPages.EXPLORE_PAGE,
        }
    ],
    [
        DataHubRoutes.routes.HOME_PAGE,
        {
            cmsPage: DataHubCmsPages.HOME_PAGE,
        }
    ],
    [
        DataHubRoutes.routes.SCOREBOARDS,
        {
            cmsPage: DataHubCmsPages.SCOREBOARD_PROFILE,
            type: ItemTypesCOINAPI.SCOREBOARD,
            key: 'code'
        }
    ],
    [
        DataHubRoutes.routes.TOPICS,
        {
            cmsPage: DataHubCmsPages.TOPICS,
        }
    ],
    [
        DataHubRoutes.routes.STORIES,
        {
            cmsPage: DataHubCmsPages.STORIES,
        }
    ],
    [
        DataHubRoutes.routes.STORY,
        {
            cmsPage: DataHubCmsPages.STORY,
            key: 'code'
        }
    ],
    [
        DataHubRoutes.routes.FAQ,
        {
            cmsPage: DataHubCmsPages.FAQ,
        }
    ],
    [
        DataHubRoutes.routes.ABOUT,
        {
            cmsPage: DataHubCmsPages.ABOUT,
        }
    ],
    [
        DataHubRoutes.routes.DATA_POLICY,
        {
            cmsPage: DataHubCmsPages.DATA_POLICY,
        }
    ],
    [
        DataHubRoutes.routes.SYNERGIES,
        {
            cmsPage: DataHubCmsPages.SYNERGIES,
        }
    ],
]);
