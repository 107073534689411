
import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromIndices from './indices.reducers';

import { CoinState } from '../index';
import {IIndice} from "@shared/interfaces";
import {ArrayHelper} from "@shared/helpers";
import {selectAllUnits} from "@features/composite-indicator/state/units/units.selectors";

export const selectCoinState = createFeatureSelector<CoinState>('composite');

export const selectIndicesState = createSelector(
    selectCoinState,
    (state) => state.indices,
);

export const selectAllIndices = createSelector(
    selectIndicesState,
    fromIndices.selectAll,
);

export const selectAllIndicesFlattened = createSelector(
    selectIndicesState,
    fromIndices.selectEntities
);

export const selectAllIndicesFlattenedByCode = createSelector(
    selectAllIndices,
    (indices) => ArrayHelper.flatten(indices, 'code'),
);

export const selectIndiceByCode = (code: string) => createSelector(
    selectAllIndicesFlattened,
    (indices) => {
        return indices[code] || null;
    },
);

export const getIndiceLinks = (indice: string) => createSelector(
    selectIndiceByCode(indice),
    (indice) => {

        try {
            return indice.links;
        } catch (e) {
            return undefined;
        }
    },
);

export const getIndiceUrlByRel = (indice: string, rel: string) => createSelector(
    getIndiceLinks(indice),
    (links) => findLink(links, rel),
);

export const selectAllIndicesLoaded = createSelector(
    selectIndicesState,
    (state) => state.allIndicesLoaded,
);

export const selectIndiceLastEditionLink = (code: string) => createSelector(
    getIndiceLinks(code),
    (links) => links.find((l) => l.rel === 'lastEdition'),
);

const findLink = function(links, rel) {
    try {
        return links.find((link) => link.rel === rel).href;
    } catch (error) {
        return null;
    }
};
