import { Action } from '@ngrx/store';
import { IQueueItem } from '@features/general/queue/structure/queue.interfaces';

export enum QueueActionTypes {
    QueueAdd = '[QUEUE] Add',
    QueueResolve = '[QUEUE] Resolve',
    QueueRemove = '[QUEUE] Remove',
    QueueRemoveByType = '[COIN SCENARIOS QUEUE] Remove by relation Type',
}

export class QueueAdd implements Action {
    readonly type = QueueActionTypes.QueueAdd;

    constructor(public payload: { items: IQueueItem[] }) {}
}

export class QueueResolve implements Action {
    readonly type = QueueActionTypes.QueueResolve;

    constructor(public payload: { relation: string }) {}
}

export class QueueRemove implements Action {
    readonly type = QueueActionTypes.QueueRemove;

    constructor(public payload: { ids: string[] }) {}
}

export class QueueRemoveByType implements Action {
    readonly type = QueueActionTypes.QueueRemoveByType;

    constructor(public payload: { dependence: string }) {}
}

export type QueueActions =
    QueueAdd
    | QueueResolve
    | QueueRemove
    | QueueRemoveByType;
