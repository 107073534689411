import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromData from './data.reducers';

import { CoinState } from '../index';
import {selectUnitById} from "@features/composite-indicator/state/units/units.selectors";

export const selectCoinState = createFeatureSelector<CoinState>('composite');

export const selectDataState = createSelector(
    selectCoinState,
    (state) => state.data,
);

export const selectAllData = createSelector(
    selectDataState,
    fromData.selectAll,
);

export const selectDataSize = createSelector(
    selectAllData,
    (data) => data.length,
);

export const selectDataLoadedUrls = createSelector(
  selectDataState,
  (state) => state.loadedUrls,
);

export const selectDataUrlIsLoaded = (url: string) => createSelector(
    selectDataLoadedUrls,
    (urls) => urls.includes(url),
);

export const selectDataUnitIsLoaded = (indice: string, edition: number, unit_id: number) => createSelector(
    selectDataLoadedUrls,
    urls => {
        return !!urls.find(url => url.includes(`${indice}/${edition}/data`)
            || (url.includes(indice) && url.includes(edition+'') && url.includes(unit_id+'') && !url.includes('maindata')));
    }
);

export const selectDataMainUnitIsLoaded = (indice: string, edition: number, unit_id: number) => createSelector(
    selectDataLoadedUrls,
    selectUnitById(unit_id),
    (urls, unit) => {
        return !!urls.find(url => url.includes(`${indice}/${edition}/data`)
            || url.includes(`${indice}/${edition}/maindata`)
            || (url.includes(indice) && url.includes(edition+'') && url.includes(unit_id+'')))
            || (unit && urls.includes(`units/${unit.code}/maindata`));
    }
);


export const selectDataHubUnitAllMainDataLoaded = (code: string) => createSelector(
    selectDataLoadedUrls,
    urls => {
        return !!urls.find(url => url.includes(`units/${code}/maindata`));
    }
);

export const selectDataByType = (type_id: number) => createSelector(
    selectAllData,
    (data) => data.filter((d) => d.type_id === type_id),
);

export const selectDataByVariable = (variable_id: number) => createSelector(
    selectAllData,
    (data) => data.filter((d) => d.variable_id === variable_id),
);

export const selectDataByVariableAndType = (variable_id: number, type_id: number) => createSelector(
    selectAllData,
    (data) => data.filter((d) => d.variable_id === variable_id && d.type_id === type_id),
);

export const selectDataByVariableAndUnit = (variable_id: number, unit_id: number) => createSelector(
    selectAllData,
    (data) => data.filter((d) => d.variable_id === variable_id && d.unit_id === unit_id),
);

export const selectDataByVariableYearType = (variable_id: number, year: number, type_id: number) => createSelector(
    selectAllData,
    (data) => data.filter((d) => d.variable_id === variable_id && d.year === year && d.type_id === type_id),
);

export const selectDataByVariableYearTypeUnit = (variable_id: number, year: number, type_id: number, unit_id: number) => createSelector(
    selectAllData,
    (data) => data.filter((d) => d.variable_id === variable_id && d.year === year && d.type_id === type_id && unit_id === unit_id),
);
