import { createSelector } from '@ngrx/store';
import { selectGeneralState } from '@features/general/general.selectors';
import * as fromCmsCharts from '@features/general/charts/state/cms-charts.reducer';

export const selectCmsChartsDataState = createSelector(
    selectGeneralState,
    state => state.charts.data
);

export const selectAllCmsChartsData = createSelector(
    selectCmsChartsDataState,
    fromCmsCharts.selectAlLChartsData
);

export const selectAllCmsChartsEntitiesData = createSelector(
    selectCmsChartsDataState,
    fromCmsCharts.selectEntitiesChartsData
);

export const selectCmsChartsAllDataUrls = createSelector(
    selectAllCmsChartsData,
    (data) => data.map((d) => d.url)
);

export const selectCmsChartsDataByUrls = (urls: string[]) => createSelector(
    selectAllCmsChartsData,
    (data) => data.filter((d) => urls.includes(d.url))
);
