import { Inject, Injectable } from '@angular/core';
import { CONFIG_TOKEN, EuiAppConfig, UxLanguage } from '@eui/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { AppState } from 'app/core/reducers';
import { selectRouterUrl } from 'app/core/selectors';
import { selectCmsTranslations } from '@features/general/cms/state/cms.selectors';
import { ToolService } from '@features/general/tool/state/tool.service';
import { LocalStorageKeys } from '@shared/enums';

import i18nEui_bg from 'node_modules/@eui/core/assets/i18n-eui/bg.json';
import i18nEui_cs from 'node_modules/@eui/core/assets/i18n-eui/cs.json';
import i18nEui_da from 'node_modules/@eui/core/assets/i18n-eui/da.json';
import i18nEui_de from 'node_modules/@eui/core/assets/i18n-eui/de.json';
import i18nEui_el from 'node_modules/@eui/core/assets/i18n-eui/el.json';
import i18nEui_en from 'node_modules/@eui/core/assets/i18n-eui/en.json';
import i18nEui_es from 'node_modules/@eui/core/assets/i18n-eui/es.json';
import i18nEui_et from 'node_modules/@eui/core/assets/i18n-eui/et.json';
import i18nEui_fi from 'node_modules/@eui/core/assets/i18n-eui/fi.json';
import i18nEui_fr from 'node_modules/@eui/core/assets/i18n-eui/fr.json';
import i18nEui_ga from 'node_modules/@eui/core/assets/i18n-eui/ga.json';
import i18nEui_hr from 'node_modules/@eui/core/assets/i18n-eui/hr.json';
import i18nEui_hu from 'node_modules/@eui/core/assets/i18n-eui/hu.json';
import i18nEui_it from 'node_modules/@eui/core/assets/i18n-eui/it.json';
import i18nEui_lt from 'node_modules/@eui/core/assets/i18n-eui/lt.json';
import i18nEui_lv from 'node_modules/@eui/core/assets/i18n-eui/lv.json';
import i18nEui_mt from 'node_modules/@eui/core/assets/i18n-eui/mt.json';
import i18nEui_nl from 'node_modules/@eui/core/assets/i18n-eui/nl.json';
import i18nEui_pl from 'node_modules/@eui/core/assets/i18n-eui/pl.json';
import i18nEui_pt from 'node_modules/@eui/core/assets/i18n-eui/pt.json';
import i18nEui_ro from 'node_modules/@eui/core/assets/i18n-eui/ro.json';
import i18nEui_sk from 'node_modules/@eui/core/assets/i18n-eui/sk.json';
import i18nEui_sl from 'node_modules/@eui/core/assets/i18n-eui/sl.json';
import i18nEui_sv from 'node_modules/@eui/core/assets/i18n-eui/sv.json';
import i18nEcl_bg from 'node_modules/@eui/ecl/assets/i18n-ecl/bg.json';
import i18nEcl_cs from 'node_modules/@eui/ecl/assets/i18n-ecl/cs.json';
import i18nEcl_da from 'node_modules/@eui/ecl/assets/i18n-ecl/da.json';
import i18nEcl_de from 'node_modules/@eui/ecl/assets/i18n-ecl/de.json';
import i18nEcl_el from 'node_modules/@eui/ecl/assets/i18n-ecl/el.json';
import i18nEcl_en from 'node_modules/@eui/ecl/assets/i18n-ecl/en.json';
import i18nEcl_es from 'node_modules/@eui/ecl/assets/i18n-ecl/es.json';
import i18nEcl_et from 'node_modules/@eui/ecl/assets/i18n-ecl/et.json';
import i18nEcl_fi from 'node_modules/@eui/ecl/assets/i18n-ecl/fi.json';
import i18nEcl_fr from 'node_modules/@eui/ecl/assets/i18n-ecl/fr.json';
import i18nEcl_ga from 'node_modules/@eui/ecl/assets/i18n-ecl/ga.json';
import i18nEcl_hr from 'node_modules/@eui/ecl/assets/i18n-ecl/hr.json';
import i18nEcl_hu from 'node_modules/@eui/ecl/assets/i18n-ecl/hu.json';
import i18nEcl_it from 'node_modules/@eui/ecl/assets/i18n-ecl/it.json';
import i18nEcl_lt from 'node_modules/@eui/ecl/assets/i18n-ecl/lt.json';
import i18nEcl_lv from 'node_modules/@eui/ecl/assets/i18n-ecl/lv.json';
import i18nEcl_mt from 'node_modules/@eui/ecl/assets/i18n-ecl/mt.json';
import i18nEcl_nl from 'node_modules/@eui/ecl/assets/i18n-ecl/nl.json';
import i18nEcl_pl from 'node_modules/@eui/ecl/assets/i18n-ecl/pl.json';
import i18nEcl_pt from 'node_modules/@eui/ecl/assets/i18n-ecl/pt.json';
import i18nEcl_ro from 'node_modules/@eui/ecl/assets/i18n-ecl/ro.json';
import i18nEcl_sk from 'node_modules/@eui/ecl/assets/i18n-ecl/sk.json';
import i18nEcl_sl from 'node_modules/@eui/ecl/assets/i18n-ecl/sl.json';
import i18nEcl_sv from 'node_modules/@eui/ecl/assets/i18n-ecl/sv.json';
import defaultLanguageTranslations from './../../../../assets/i18n/en.json';

@Injectable()
export class TranslationsService {
    protected translations = {};
    protected lastUrl = null;

    public allLanguages = [
        { label: 'български', code: 'bg', files: [i18nEui_bg, i18nEcl_bg] } as UxLanguage,
        { label: 'Español', code: 'es', files: [i18nEcl_es, i18nEui_es] } as UxLanguage,
        { label: 'Čeština', code: 'cs', files: [i18nEcl_cs, i18nEui_cs] } as UxLanguage,
        { label: 'Dansk', code: 'da', files: [i18nEcl_da, i18nEui_da] } as UxLanguage,
        { label: 'Deutsch', code: 'de', files: [i18nEcl_de, i18nEui_de] } as UxLanguage,
        { label: 'Eesti', code: 'ee', files: [] } as UxLanguage,
        { label: 'Ελληνικά', code: 'el', files: [i18nEcl_el, i18nEui_el] } as UxLanguage,
        { label: 'English', code: 'en', files: [i18nEcl_en, i18nEui_en] } as UxLanguage,
        { label: 'Français', code: 'fr', files: [i18nEcl_fr, i18nEui_fr] } as UxLanguage,
        { label: 'Gaeilge', code: 'ga', files: [i18nEcl_ga, i18nEui_ga] } as UxLanguage,
        { label: 'Hrvatski', code: 'hr', files: [i18nEcl_hr, i18nEui_hr] } as UxLanguage,
        { label: 'Italiano', code: 'it', files: [i18nEcl_it, i18nEui_it] } as UxLanguage,
        { label: 'Latviešu', code: 'lv', files: [i18nEcl_lv, i18nEui_lv] } as UxLanguage,
        { label: 'Lietuvių', code: 'lt', files: [i18nEcl_lt, i18nEui_lt] } as UxLanguage,
        { label: 'Magyar', code: 'hu', files: [i18nEcl_hu, i18nEui_hu] } as UxLanguage,
        { label: 'Malti', code: 'mt', files: [i18nEcl_mt, i18nEui_mt] } as UxLanguage,
        { label: 'Nederlands', code: 'nl', files: [i18nEcl_nl, i18nEui_nl] } as UxLanguage,
        { label: 'Polski', code: 'pl', files: [i18nEcl_pl, i18nEui_pl] } as UxLanguage,
        { label: 'Português', code: 'pt', files: [i18nEcl_pt, i18nEui_pt] } as UxLanguage,
        { label: 'Română', code: 'ro', files: [i18nEcl_ro, i18nEui_ro] } as UxLanguage,
        { label: 'Slovenčina', code: 'sk', files: [i18nEcl_sk, i18nEui_sk] } as UxLanguage,
        { label: 'Slovenščina', code: 'sl', files: [i18nEcl_sl, i18nEui_sl] } as UxLanguage,
        { label: 'Suomi', code: 'fi', files: [i18nEcl_fi, i18nEui_fi] } as UxLanguage,
        { label: 'Svenska', code: 'sv', files: [i18nEcl_sv, i18nEui_sv] } as UxLanguage,
    ];

    constructor(
        @Inject(CONFIG_TOKEN) private config: EuiAppConfig,
        public translateService: TranslateService,
        public toolService: ToolService,
        private _store: Store<AppState>
    ) {
        if (this._store) {
            this._store.select(selectCmsTranslations).subscribe((translations) => {
                this.setPageCustomData(translations);
            });
            this._store.select(selectRouterUrl).subscribe((url) => {
                if (this.lastUrl === url) {
                    return;
                }
                this.lastUrl = url;
            });
        }
    }

    public getCurrentLocale(): string {
        const toolLangs = this.toolService.getToolLocalesFromUrl(this.lastUrl ? this.lastUrl : window.location.pathname);

        let newLang = null;

        // Get user's last selected language
        let userLang = null;
        const userLangFound = localStorage.getItem(LocalStorageKeys.userLang);
        if (userLangFound) {
            userLang = toolLangs.find((lang) => lang === userLangFound);

            if (userLang) {
                newLang = userLang;
            }
        }

        // Get user's browser's language
        if (! newLang) {
            let browserLang = null;
            const browserLanguage = this.translateService.getBrowserLang();

            if (browserLanguage) {
                browserLang = toolLangs.find((lang) => lang === browserLanguage);

                if (browserLang) {
                    newLang = browserLang;
                }
            }
        }

        if (! newLang) {
            // Default language is set in app/config/global.ts
            const globalDefaultLangSetting = toolLangs.find((lang) => lang === this.config.global.i18n.i18nService.defaultLanguage);

            if (globalDefaultLangSetting) {
                newLang = globalDefaultLangSetting;
            }
        }
        return newLang ? newLang : toolLangs[0];
    }

    /**
     * @param {string} url
     * @param {string|null}locale
     */
    public ensureLocaleInUrl(url: string, locale?: string): string {
        if (! locale) {
            locale = this.getCurrentLocale();
        }
        if (! locale) {
            // locale = 'en'; // TODO move this from environment file as default lang
            throw new Error('Locale was not determined in order to generate locale based url: ' + url);
        }
        return url.replace('{{locale}}', locale);
    }

    /**
     * @param {object} translations
     */
    public setPageCustomData(translations): void {
        this.translations = translations ? translations : this.translations ? this.translations : {};
    }

    /**
     * @param {string} key
     * @param {string} fallbackValue
     */
    public get(key: string, fallbackValue?: string): string {
        key = key.toLowerCase();
        let value = key;
        if (this.translations.hasOwnProperty(key)) {
            value = this.translations[key];
        } else {
            const i18nTranslation = this.translateService ? this.translateService.instant(key) : null;
            if (i18nTranslation) {
                value = i18nTranslation;
            }
        }
        return value ? value : fallbackValue;
    }

    /**
     * @param {string} locale
     * @private
     */
    public generateTranslationsFromFilesForLocale(locale: string) {
        const translations = {};

        const language = this.allLanguages.find((lang) => lang.code === locale);
        // @ts-ignore
        if (language.files) {
            // @ts-ignore
            language.files.map((obj) => {
                for (const prop in obj) {
                    if (! obj.hasOwnProperty(prop)) {
                        continue;
                    }
                    translations[prop] = obj[prop];
                }
            });
        }
        return translations;
    }
}
