import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslationsService } from '@features/general/translations/translations.service';
import { Observable, of } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IAnswer, ICompactAnswer } from './interfaces';
import {EnvService} from "@shared/services/env.service";

@Injectable({
    providedIn: 'root'
})
export class SharedService {
    // Don't use directly to ensure the right locale through this.getApiUrl()
    // private baseApi = environment.apiUrl;

    constructor(
        protected envService: EnvService,
        protected http: HttpClient,
        protected translationsService: TranslationsService,
    ) {}

    /**
     * @param {string} locale
     */
    getApiUrl(locale?: string): string {
        return this.translationsService.ensureLocaleInUrl(this.envService.apiUrl, locale);
    }

    /**
     * @param {string} locale
     */
    getBackendServicesApiUrl(locale?: string): string {
        return this.translationsService.ensureLocaleInUrl(this.envService.backendServicesApi, locale);
    }

    /**
     * Generic calls
     */

    getLocalFile = (url): Observable<any> => {
        return this.http.get(url)
            .pipe(
                retry(3),
            );
    }

    getByUrl<T>(url: string, errorCallback: any = null): Observable<T> {
        return this.http.get<IAnswer<T>>(url)
            .pipe(
                retry(3),
                map((res) => res.data),
                catchError((res) => {
                    if (errorCallback) {
                        errorCallback();
                    }
                    return of(res?.code || null);
                })
            );
    }

    protected getFullAnswerByUrlViaPost<T>(url: string, body: {[_: string]: any}): Observable<IAnswer<T>> {
        return this.http.post<IAnswer<T>>(url, JSON.stringify(body))
            .pipe(
                retry(3),
                map((res) => ({ data: res.data, url, body }))
            );
    }

    protected getByUrlViaPostMethod<T>(url: string, body: any): Observable<T> {
        return this.http.post<IAnswer<T>>(url, JSON.stringify(body))
            .pipe(
                retry(3),
                map((res) => res.data),
            );
    }

    protected getFullAnswerByUrl<T>(url: string): Observable<IAnswer<T>> {
        return this.http.get<IAnswer<T>>(url)
            .pipe(
                retry(3),
                map((res) => ({ data: res.data, url }))
            );
    }

    protected getFullCompactAnswerByUrl(url: string): Observable<ICompactAnswer> {
        return this.http.get<ICompactAnswer>(url)
            .pipe(
                retry(3),
            );
    }

    public getExternalData<T>(url: string, errorCallback: any = null): Observable<T> {
        return this.http.get<T>(url)
            .pipe(
                retry(3),
                catchError((res) => {
                    if (errorCallback) {
                        errorCallback();
                    }
                    return of(res?.code || null);
                })
            );
    }
}
