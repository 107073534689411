import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import { effect } from 'signal';
import {Store} from '@ngrx/store';
import {filter, map, mergeMap, withLatestFrom} from 'rxjs/operators';
import {of} from 'rxjs';
import { CmsService } from '@features/general/cms/state/cms.service';
import { CmsActionTypes, CmsPageRequested, CmsPagesLoaded } from '@features/general/cms/state/cms.actions';
import {
    selectCmsActiveModulesLists,
    selectCmsPageIsLoaded
} from '@features/general/cms/state/cms.selectors';
import { selectRouterUrl } from 'app/core/selectors';
import { QueueResolve } from '@features/general/queue/state/queue.actions';

import {ListsLoadRequested} from "@features/general/lists/state/lists.actions";
import {ListsScopes} from "@features/general/lists/structure";
import {DataHubCmsPages} from "@features/data-hub/dh-shared/enums";
import {DataHubRoutes} from "@features/data-hub/data-hub-routes";

@Injectable()
export class CmsEffects {

    cmsPageRequest = createEffect(() =>this.actions$
        .pipe(
            ofType<CmsPageRequested>(CmsActionTypes.CmsPageRequested),
            map((request) => request.payload),
            mergeMap((request) => of(request)
                .pipe(
                    withLatestFrom(this.store.select(selectCmsPageIsLoaded(request.page, request.params))),
                    filter(([, isLoaded]) => !isLoaded),
                    withLatestFrom(this.store.select(selectRouterUrl)),
                    map(([, url]) => url),
                    mergeMap((url) => of(url)
                        .pipe(
                            mergeMap((url) => this.cmsService.getFullAnswerCMSPage(request.page, request.params)),
                            map((page) => this.cmsService.simplifyCmsAnswer(page)),
                            mergeMap((page) => {
                                const arr = [];

                                // Cover when indice is not found
                                if (page.data.type === DataHubCmsPages.INDICE_PROFILE) {
                                    const module = page.data.modules.find((module) => module.slug === 'index');

                                    // {error: "The indice with the specified parameters doesn't exists", code: 404}
                                    // @ts-ignore
                                    if (module.api.index.code === 404) {
                                        // @ts-ignore
                                        console.warn(module.api.index.error);

                                        window.location.href = DataHubRoutes.generate(DataHubRoutes.routes.EXPLORER);
                                        return arr;
                                    }
                                }
                                arr.push(new CmsPagesLoaded({ pages: [{frontend_page: url, page_type: request.page, map: request.params, content: page }]}));
                                // console.log('CmsPageRequested -> handleSEOFromCmsPage');
                                // arr.concat(this.cmsService.handleSEOFromCmsPage(page.data, url))
                                return arr;
                            })
                        )),
                ))
        ));

    cmsPagesLoaded = createEffect(() =>this.actions$
        .pipe(
            ofType<CmsPagesLoaded>(CmsActionTypes.CmsPagesLoaded),
            map(request => new QueueResolve({ relation: request.type }))
        ));

    cmsPagesLoadedAddLists = createEffect(() =>this.actions$
        .pipe(
            ofType<CmsPagesLoaded>(CmsActionTypes.CmsPagesLoaded),
            withLatestFrom(this.store.select(selectCmsActiveModulesLists)),
            filter(([, lists]) => lists.length > 0),
            map(([, lists]) => new ListsLoadRequested({type: ListsScopes.page, lists}))
        ));

    constructor(
        private actions$: Actions,
        private store: Store<any>,
        private cmsService: CmsService,
    ) {}
}
