import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { IEdition } from '@shared/interfaces';
import { EditionsActions, EditionsActionTypes } from './editions.actions';

export interface EditionsState extends EntityState<IEdition> {}

export const adapter: EntityAdapter<IEdition> =
    createEntityAdapter<IEdition>({
        selectId: (edition) => CreateEditionId({ indice: edition.indice, edition: edition.year }),
    });

export const initialEditionsState: EditionsState = adapter.getInitialState({});

export function editionsReducer(state: EditionsState = initialEditionsState , action: EditionsActions): EditionsState {
    switch (action.type) {
    case EditionsActionTypes.EditionsLoaded:
        return adapter.addMany(action.payload.editions, state);
    default: {
        return state;
    }
    }
}

export function CreateEditionId( payload: {indice: string, edition: number}): string {
    return payload.indice + '_' + payload.edition;
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal,

} = adapter.getSelectors();
