import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { LastEditionRoutes } from '@features/composite-indicator/shared/api_routes';
import { CategoryGroupsService } from '@features/composite-indicator/state/category-groups/category-groups.service';
import { ColorsService } from '@features/composite-indicator/state/colors/colors.service';
import { ComponentVariablesService } from '@features/composite-indicator/state/component-variables/component-variables.service';
import { CountriesService } from '@features/composite-indicator/state/countries/countries.service';
import { StatisticsService } from '@features/composite-indicator/state/statistics/statistics.service';
import { CompositeService } from './composite.service';
import { CoinEffects, coinReducer } from './state';
import { CategoriesService } from './state/categories/categories.service';
import { ComponentsService } from './state/components/components.service';
import { DataService } from './state/data/data.service';
import { EditionsService } from './state/editions/editions.service';
import { IndicesService } from './state/indices/indices.service';
import { LevelsService } from './state/levels/levels.service';
import { ResourcesService } from './state/resources/resources.service';
import { UnitsService } from './state/units/units.service';
import { VariablesService } from './state/variables/variables.service';
import { ScalesService } from '@features/composite-indicator/state/scales/scales.service';
import { DevelopersService } from '@features/composite-indicator/state/developers/developers.service';

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature('composite', coinReducer),
        EffectsModule.forFeature(CoinEffects),
    ],
    declarations: [],
    providers: [
        CategoryGroupsService,
        CategoriesService,
        CountriesService,
        ComponentsService,
        DataService,
        EditionsService,
        IndicesService,
        LevelsService,
        ResourcesService,
        UnitsService,
        VariablesService,
        CompositeService,
        ColorsService,
        StatisticsService,
        ScalesService,
        DevelopersService,
        LastEditionRoutes,
        ComponentVariablesService
    ],
})
export class CompositeIndicatorModule { }
