import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import {CmsChartsFiltersDisplay, ICmsChartFilter} from '@features/general/charts/structure';

export interface ICmsChartFilterElem extends ICmsChartFilter {
    chart_id: string;
    list_id: string;
    display: CmsChartsFiltersDisplay;
}

export function selectCmsChartFilterId(filter: Partial<ICmsChartFilterElem>): string {
    return filter.chart_id + '_' + filter.param;
}

export const cmsChartFilterAdapter: EntityAdapter<ICmsChartFilterElem> =
    createEntityAdapter<ICmsChartFilterElem>({
        selectId: selectCmsChartFilterId
    });

export interface CmsChartsFiltersState extends EntityState<ICmsChartFilterElem> {}

