import { Action } from '@ngrx/store';
import { IDeveloper } from '@shared/interfaces';

export enum DevelopersActionTypes {
    DeveloperRequested = '[DEVELOPER] Developer requested',
    DeveloperLoaded = '[DEVELOPER] Developer loaded',
    AllDevelopersRequested = '[DEVELOPER] All Developers requested',
    AllDevelopersLoaded = '[DEVELOPER] All Developers loaded',
}

export class AllDevelopersRequested implements Action {
    readonly type = DevelopersActionTypes.AllDevelopersRequested;
}

export class AllDevelopersLoaded implements Action {
    readonly type = DevelopersActionTypes.AllDevelopersLoaded;

    constructor(public payload: { developers: IDeveloper[]}) {}
}

export class DeveloperRequested implements Action {
    readonly type = DevelopersActionTypes.DeveloperRequested;

    constructor(public payload: { id: number}) {}
}

export class DeveloperLoaded implements Action {
    readonly type = DevelopersActionTypes.DeveloperLoaded;

    constructor(public payload: { developer: IDeveloper }) {}
}


export type DevelopersActions =
    AllDevelopersLoaded
    | AllDevelopersRequested
    | DeveloperLoaded
    | DeveloperRequested;
