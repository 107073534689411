
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { BreadcrumbsState } from '@shared/breadcrumbs/breadcrumbs.reducers';

export const selectBreadcrumbsState = createFeatureSelector<BreadcrumbsState>('breadcrumbs');

export const selectBreadcrumbCustomLastTitle = createSelector(
    selectBreadcrumbsState,
    (state) => state.customLastTitle,
);
