import { Injectable } from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import { effect } from 'signal';
import { Store } from '@ngrx/store';
import { ComponentVariablesLoaded, ComponentVariablesRequested, ComponentVariablesActionTypes } from '@features/composite-indicator/state/component-variables/component-variables.actions';
import { of } from 'rxjs';
import { filter, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { ComponentVariablesService } from './component-variables.service';
import { selectComponentVariablesByIds } from './component-variables.selectors';

@Injectable()
export class ComponentVariablesEffects {

    componentVariableRequested$ = createEffect(() =>this.actions$
        .pipe(
            ofType<ComponentVariablesRequested>(ComponentVariablesActionTypes.ComponentVariablesRequested),
            map((request) => request.payload),
            filter((payload) => !!(payload && payload.ids)),
            map(payload => payload.ids),
            mergeMap(ids => of(ids)
                .pipe(
                    withLatestFrom(this.store.select(selectComponentVariablesByIds(ids))),
                    filter(([id, request]) => request.length < ids.length),
                    mergeMap((payload) => this.componentVariablesService.getByIds(ids)),
                    map((componentVariables) => new ComponentVariablesLoaded({ componentVariables }))
                )
        )));

    constructor(private actions$: Actions, private componentVariablesService: ComponentVariablesService,
                private store: Store<any>) {}
}
