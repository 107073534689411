import { GeoActions, GeoActionTypes } from './geo.actions';
import { EntityState } from '@ngrx/entity';
import { adapterGeo } from '@features/geo/structure/geo.entity';

export interface GeoState extends EntityState<any> {
    urlsLoaded: string[];
}

const initialGeoState = adapterGeo.getInitialState({
    urlsLoaded: []
});

export function geoReducer(state = initialGeoState, action: GeoActions): GeoState {
    let urlsLoaded: string[];
    switch (action.type) {
        case GeoActionTypes.GeoDataLoaded:
            urlsLoaded = state.urlsLoaded.filter((url) => url !== action.payload.url);
            return adapterGeo.addOne({ file: action.payload.data, url: action.payload.url }, { ...state, urlsLoaded: [...urlsLoaded, action.payload.url] });
        default:
            return state;
    }
}
export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal,

} = adapterGeo.getSelectors();
