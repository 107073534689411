import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import {IColor} from '@shared/interfaces';
import { ColorsActions, ColorsActionTypes } from './colors.actions';

export interface ColorsState extends EntityState<IColor> {
    urlsLoaded: string[];
}

export const adapter: EntityAdapter<IColor> =
    createEntityAdapter<IColor>();

export const initialColorsState: ColorsState = adapter.getInitialState({
    urlsLoaded: [],
});

export function colorsReducer(state = initialColorsState , action: ColorsActions): ColorsState {
    let urls: string[];
    switch (action.type) {
        case ColorsActionTypes.ColorsLoaded:
            urls = state.urlsLoaded.filter(url => url !== action.payload.url);
            return adapter.addMany(action.payload.colors, {...state, urlsLoaded: [...urls, action.payload.url]});1
        default: {
             return state;
        }
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal,

} = adapter.getSelectors();
