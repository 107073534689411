import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import { effect } from 'signal';
import {Store} from '@ngrx/store';
import {filter, map, mergeMap} from 'rxjs/operators';

import {
    ComponentsActionTypes, ComponentsByUrlRequested, ComponentsLastEditionRequested, ComponentsLoaded,
} from './components.actions';
import {ComponentsRequestsTypes, ComponentsService} from './components.service';
import { of } from 'rxjs';

@Injectable()
export class ComponentsEffects {

    loadComponentsByUrl$ = createEffect(() =>this.actions$
        .pipe(
            ofType<ComponentsByUrlRequested>(ComponentsActionTypes.ComponentsByUrlRequested),
            map((request) => request.payload.url),
            mergeMap((url) => this.componentsService.getComponentsByUrl(url)),
            map( (components) => new ComponentsLoaded( { components })),
        ));

    componentsByLastEditionRequested$ = createEffect(() =>this.actions$
        .pipe(
            ofType<ComponentsLastEditionRequested>(ComponentsActionTypes.ComponentsLastEditionRequested),
            map((request) => request.payload),
            filter((payload) => !!(payload && payload.indice)),
            map((params) => this.componentsService.componentsUrlByType(ComponentsRequestsTypes.lastEdition, params)),
            map((url) => new ComponentsByUrlRequested({ url }))
        ));

    constructor(private actions$: Actions, private componentsService: ComponentsService,
                private store: Store<any>) {}
}
