
<span>
<!--    TODO hover popover logic-->
    <span *ngIf="this.data.routerLink && !this.data.link">
        <a href="#" [routerLink]="this.data.routerLink">
            <span [innerHTML]="data.value"></span>
        </a>
    </span>
    <span *ngIf="this.data.link && !this.data.routerLink">
        <a [href]="this.data.link.url" [target]="this.data.link.target" [title]="this.data.link.title">
            <span [innerHTML]="data.value"></span>
        </a>
    </span>
    <span *ngIf="!this.data.link && !this.data.routerLink" [innerHTML]="data.value"></span>
</span>

