import {Injectable} from '@angular/core';
import {ChartV3, IChartV3} from "@shared/models/ChartV3";
import {CmsService} from "@features/general/cms/state/cms.service";
import {Params} from "@angular/router";

@Injectable()
export class ChartV3Service {

    constructor(
        private cmsService: CmsService,
    ) {
      // ...
    }

    prepareChartsFromCms(params: Params, charts: Partial<ChartV3>[]) {
        const chartsV3 = charts.map((chart) => new ChartV3(<Partial<ChartV3>>{...chart, api: this.cmsService.getChartsApiUrl()}));
        // Ensure params i.e. param code=arei -> index_code=arei (chartParams: {index_code: 'arei'})
        for (const param in params) {
            for (const chart of chartsV3) {
                if (chart.convert.hasOwnProperty(param) || chart.params.includes(param)) {
                    chart.setCmsParam(param, params[param]);
                }
            }
        }

        return chartsV3.map((chart) => {
            // Prepare chart params
            let chartParams = [];
            for (const prop in chart.chartParams) {
                if (! chart.chartParams.hasOwnProperty(prop)) {
                    continue;
                }

                chartParams.push(prop + '=' + chart.chartParams[prop]);
            }

            // Generate baseurl
            return <ChartV3>{...chart, baseUrl: this.cmsService.getCmsApiUrl() + '/' + chart.uid + chart.paramsSeparator + chartParams.join('&')};
        });
    }
}
