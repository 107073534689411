import { Action } from '@ngrx/store';
import { IUnitEdition } from '@shared/interfaces';

export enum UnitEditionsActionTypes {
    UnitEditionsByUnitRequested = '[UNITEDITIONS] Unit editions by unit requested',
    UnitEditionsByEditionRequested = '[UNITEDITIONS] Unit editions by edition requested',
    UnitEditionsByUrlRequested = '[UNITEDITIONS] Unit editions by url requested',
    UnitEditionsLoaded = '[UNITEDITIONS] Unit editions Loaded',
}

export class UnitEditionsByUnitRequested implements Action {
    readonly type = UnitEditionsActionTypes.UnitEditionsByUnitRequested;

    constructor(public payload: { code: string }) {}
}

export class UnitEditionsByEditionRequested implements Action {
    readonly type = UnitEditionsActionTypes.UnitEditionsByEditionRequested;

    constructor(public payload: { indice: string, edition: number }) {}
}

export class UnitEditionsByUrlRequested implements Action {
    readonly type = UnitEditionsActionTypes.UnitEditionsByUrlRequested;

    constructor(public payload: { url: string }) {}
}

export class UnitEditionsLoaded implements Action {
    readonly type = UnitEditionsActionTypes.UnitEditionsLoaded;

    constructor(public payload: { data: IUnitEdition[], loaded: string }) {}
}

export type UnitEditionsActions =
    UnitEditionsByUnitRequested
    | UnitEditionsByEditionRequested
    | UnitEditionsByUrlRequested
    | UnitEditionsLoaded;
