import { Injectable } from '@angular/core';

import { Actions, ofType } from '@ngrx/effects';
import { Effect } from 'signal';
import { Store } from '@ngrx/store';
import {concatMap, concatMapTo, filter, first, map, mapTo, mergeMap, switchMap, withLatestFrom} from 'rxjs/operators';

import {of} from 'rxjs';
import {
    CoinEditionAllDataLoaded,
    CoinEditionLoaded, CompositeIndicatorsActionTypes
} from '@features/composite-indicator/state/status/composite-indicators.actions';
import {selectCoinEditionIsFullyLoaded} from "@features/composite-indicator/state/status/composite-indicators.selectors";

@Injectable()
export class CoinQueueEffects {

    /*
    @createEffect(() =>)
    loadEdition$ = this.actions$
        .pipe(
            ofType<CoinEditionLoaded>(CompositeIndicatorsActionTypes.CoinEditionLoaded),
            map(request => request.payload),
            mergeMap(request => of(request)
                .pipe(
                    withLatestFrom(this.store.select(selectScenariosQueueByEdition(request.indice, request.edition))),
                    filter(([action, queue]) => !!queue.length),
                    withLatestFrom(this.store.select(selectCoinEditionIsFullyLoaded(request.indice, request.edition))),
                    filter(([action, loaded]) => loaded),
                )),
            concatMap(([[request, queue], loaded]) => {
                let actions = [
                    new SCRemoveFromQueue({ids: queue.map(q => q.id)}),
                    ...queue.map(q => q.payload)
                ];
                return actions;
            })
        );

    @createEffect(() =>)
    loadEditionData$ = this.actions$
        .pipe(
            ofType<CoinEditionAllDataLoaded>(CompositeIndicatorsActionTypes.CoinEditionAllDataLoaded),
            map(request => request.payload),
            mergeMap(request => of(request)
                .pipe(
                    withLatestFrom(this.store.select(selectScenariosQueueByEdition(request.indice, request.edition))),
                    filter(([action, queue]) => !!queue.length),
                    withLatestFrom(this.store.select(selectCoinEditionIsFullyLoaded(request.indice, request.edition))),
                    filter(([action, loaded]) => loaded),
                )),
            concatMap(([[request, queue], loaded]) => {
                let actions = [
                    new SCRemoveFromQueue({ids: queue.map(q => q.id)}),
                    ...queue.map(q => q.payload)
                ];
                return actions;
            })
        );
*/
    constructor(private actions$: Actions, private store: Store<any>) {

    }
}
