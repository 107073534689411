import { StatisticsType } from '@shared/enums';
import * as ss from 'simple-statistics';

export class Statistics {

    /**
     * Filters the array returning elements with valid numbers
     * @param data
     * @param valueAttr
     */
    static filterValids = <T>(data: T[], valueAttr = 'value'): T[] => {
        return data.filter((d) => !isNaN(d[valueAttr]));
    }

    static getNumbers = <T>(data: T[], valueAttr = 'value'): number[] => {
        const valid = Statistics.filterValids<T>(data);
        return valid.map((d) => d[valueAttr]);
    }

    static unique = <T>(data: T[], attr: string) => {
        try {
            if (!attr) {
                return Array.from(new Set(data));
            } else {
                return Array.from(new Set(data.map((d: T) => d[attr])));
            }
        } catch (e) {
            return [];
        }
    }

    static sum = <T>(data: T[], valueAttr = 'value'): number => {
        try {
            return ss.sum(Statistics.getNumbers<T>(data, valueAttr));
        } catch (e) {
            return null;
        }
    }

    static avg = <T>(data: T[], valueAttr = 'value'): number => {
        return ss.mean(Statistics.getNumbers<T>(data, valueAttr));
    }

     static powerSum = <T>(data: T[], valueAttr = 'value', power: number, ): number => {
         try {
             const fdata = data.filter((d) => typeof d[valueAttr] === 'number');
             const n = fdata.length;
             if (!n) {
                 return null;
             }

             const powerData = data.map((d) => ({value: Math.pow(d[valueAttr], power)}));
             return Statistics.sum(powerData, valueAttr);
         } catch (e) {
             return null;
         }
    };

    static popVariance = <T>(data: T[], valueAttr = 'value'): number => {
        try {
            const fdata = data.filter((d) => typeof d[valueAttr] === 'number');
            const n = fdata.length;
            if (!n) {
                return null;
            }

            const avg = Statistics.avg<T>(fdata);
            const squareSum = Statistics.powerSum<T>(fdata, valueAttr, 2);
            return squareSum / n - Math.pow(avg, 2);
        } catch (e) {
            return null;
        }
    };

    static popStdev = <T>(data: T[], valueAttr = 'value'): number => {
        try {
            const variance = Statistics.popVariance<T>(data, valueAttr);

            return variance ? Math.sqrt(variance) : variance;
        } catch (e) {
            return null;
        }
    };

    static min = <T>(data: T[], valueAttr = 'value'): number => {
        try {
            return Math.min(...Statistics.unique<T>(data, valueAttr));
        } catch (e) {
            return null;
        }
    };

    static max = <T>(data: T[], valueAttr = 'value'): number => {
        try {
            return Math.max(...Statistics.unique<T>(data, valueAttr));
        } catch (e) {
            return null;
        }
    };

    static range = <T>(data: T[], valueAttr = 'value', [criteriaAttr, criteriaValue] = [null, null]): [number, number] => {
        try {
            let fdata = criteriaAttr ? data.filter((d) => d[criteriaAttr] === criteriaValue) : data;
            let min = fdata[0][valueAttr];
            let max = fdata[0][valueAttr];
            for (let elem of fdata) {
                const value = elem[valueAttr];
                if (value != null) {
                    if (value < min) {
                        min = value;
                    } else if (value > max) {
                        max = value;
                    }
                }
            }

            return [min, max];
        } catch (e) {
            return null;
        }
    };

    static statByType = <T>(type: StatisticsType): any => {
        switch (type) {
            case StatisticsType.Min:
                return Statistics.min;
            case StatisticsType.Max:
                return Statistics.max;
            case StatisticsType.Avg:
                return Statistics.avg;
            case StatisticsType.STD:
                return Statistics.popStdev;
            case StatisticsType.RANGE:
                return Statistics.range;
            default:
                return Statistics.max;
        }
    };

    static calcStatByType = <T>(data: T[], valueAttr: string, type: StatisticsType, power?: number) => {
        const statFunction = Statistics.statByType(type);
        return power ? statFunction(data, valueAttr, power) :  statFunction(data, valueAttr);
    }
}
