import {APP_INITIALIZER, NgModule} from '@angular/core';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSliderModule } from '@angular/material/slider';
import { EuiAllModule } from '@eui/components';
import { UxAllModule } from '@eui/components/legacy';
import {
    EclAllModule,
    EclIconComponentModule
} from '@eui/ecl';
import { ChartsModule } from '@features/charts/charts.module';
import { Charts2Module } from '@features/charts2/charts2.module';
import { TranslationsService } from '@features/general/translations/translations.service';
import { AlphabeticalListComponent } from '@shared/components/alphabetical-list/alphabetical-list.component';
import { CountryCardSmallComponent } from '@shared/components/country-card-small/country-card-small.component';
import { DropDownEclStyleComponent } from '@shared/components/dropdown-ecl-style/dropdown-ecl-style.component';
import {
    EclBestPracticesByCountriesListComponent
} from '@shared/components/ecl-best-practices-by-countries-list/ecl-best-practices-by-countries-list.component';
import {
    EclBestPracticesSideListComponent
} from '@shared/components/ecl-best-practices-side-list/ecl-best-practices-side-list.component';
import { EclBestPracticesComponent } from '@shared/components/ecl-best-practices/ecl-best-practices.component';
import { EclBlockquoteComponent } from '@shared/components/ecl-blockquote/ecl-blockquote.component';
import { EclChartContainerComponent } from '@shared/components/ecl-chart-container/ecl-chart-container.component';
import { EclChartDataTableComponent } from '@shared/components/ecl-chart-data-table/ecl-chart-data-table.component';
import { EclFactFiguresComponent } from '@shared/components/ecl-fact-figures/ecl-fact-figures.component';
import { EclFiltersReadOnlyListComponent } from '@shared/components/ecl-filters-read-only-list/ecl-filters-read-only-list.component';
import { EclKpiSideListComponent } from '@shared/components/ecl-kpi-side-list/ecl-kpi-side-list.component';
import { EclLinksListComponent } from '@shared/components/ecl-links-list/ecl-links-list.component';
import { EclSkeletonLoaderComponent } from '@shared/components/ecl-skeleton-loader/ecl-skeleton-loader.component';
import { EclSocialMediaShareComponent } from '@shared/components/ecl-social-media-share/ecl-social-media-share.component';
import { FilesComponent } from '@shared/components/files/files.component';
import { FrameworkChildComponent } from '@shared/components/framework-child/framework-child.component';
import { FrameworkComponent } from '@shared/components/framework/framework.component';
import { HighlightCardComponent } from '@shared/components/highlight-card/highlight-card.component';
import { ListItemTypeCategoryComponent } from '@shared/components/list-item-type-category/list-item-type-category.component';
import { ListItemTypeCountryComponent } from '@shared/components/list-item-type-country/list-item-type-country.component';
import { ListItemTypeIndiceComponent } from '@shared/components/list-item-type-indice/list-item-type-indice.component';
import { ListItemTypeDeveloperComponent } from "@shared/components/list-item-type-developer/list-item-type-developer.component";
import { ListItemsListFiltersComponent } from '@shared/components/list-items-list-filters/list-items-list-filters.component';
import { ListItemsListWrapperComponent } from '@shared/components/list-items-list-wrapper/list-items-list-wrapper.component';
import { TableLayoutListItemsListWrapperComponent } from '@shared/components/table-layout-list-items-list-wrapper/table-layout-list-items-list-wrapper.component';
import { ListItemsListComponent } from '@shared/components/list-items-list/list-items-list.component';
import { NumberGrowComponent } from '@shared/components/number-grow/number-grow.component';
import { PageHeaderEclStyleComponent } from '@shared/components/page-header-ecl-style/page-header-ecl-style.component';
import { PaginationComponent } from '@shared/components/pagination/pagination.component';
import {
    PopoverHowToReadThisChartComponent
} from '@shared/components/popover-how-to-read-this-chart/popover-how-to-read-this-chart.component';
import { PopoverIndicatorDataComponent } from '@shared/components/popover-indicator-data/popover-indicator-data.component';
import { PopoverWrapperComponent } from '@shared/components/popover-wrapper/popover-wrapper.component';
import { ResourceComponent } from '@shared/components/resource/resource.component';
import { TableMultilevelCellComponent } from '@shared/components/table-multilevel/table-multilevel-cell/table-multilevel-cell.component';
import { TableMultilevelComponent } from '@shared/components/table-multilevel/table-multilevel.component';
import { TimelineComponent } from '@shared/components/timeline/timeline.component';
import { AppAdditionalDataDirective } from '@shared/directives/additional-data.directive';
import { SimpleClickOutsideDirective } from '@shared/directives/simple-click-outside.directive';
import { CallbackPipe } from '@shared/filters/callback.pipe';
import { SafeHTMLPipe } from '@shared/pipes/SafeHTMLPipe';
import { SafeUrlPipe } from '@shared/pipes/SafeUrlPipe';
import { GoogleAnalyticsService } from '@shared/services/google-analytics.service';
import { ClickOutsideModule } from 'ng-click-outside';
import { CompositeIndicatorModule } from 'src/app/features/composite-indicator/composite-indicator.module';
import { CardEclStyleComponent } from './components/card-ecl-style/card-ecl-style.component';
import { CardImageComponent } from './components/card-image/card-image.component';
import { CardNoImageComponent } from './components/card-no-image/card-no-image.component';
import { ComponentScoreEditRowComponent } from './components/component-score-edit-row/component-score-edit-row.component';
import { EclChartControllerComponent } from './components/ecl-chart-controller/ecl-chart-controller.component';
import { EclChartFiltersSideListComponent } from './components/ecl-chart-filters-side-list/ecl-chart-filters-side-list.component';
import { EclChartComponent } from './components/ecl-chart/ecl-chart.component';
import { EclInpageNavComponent } from './components/ecl-inpage-nav/ecl-inpage-nav.component';
import { IconPanelComponent } from './components/icon-panel/icon-panel.component';
import { IconPanelsComponent } from './components/icon-panels/icon-panels.component';
import { MatSliderComponent } from './components/mat-slider/mat-slider.component';
import { SearchFilterTabComponent } from './components/search-filter-tab/search-filter-tab.component';
import { SliderPanelComponent } from './components/slider-panel/slider-panel.component';
import { SliderComponent } from './components/slider/slider.component';
import { AppSandboxService } from './sandboxes/appSandbox';
import {
    PopoverTemplateIListElemDescriptionComponent
} from "@shared/components/popover-wrapper/templates/popoverTemplateIListElemDescription/popoverTemplateIListElemDescription.component";
import {KpisWrapperComponent} from "@shared/components/kpis-wrapper/kpis-wrapper.component";
import {KpiTypeStepLineComponent} from "@shared/components/kpi-type-step-line/kpi-type-step-line.component";
import {KpiTypeIconComponent} from "@shared/components/kpi-type-icon/kpi-type-icon.component";
import {
    PopoverTemplateIListElemDescriptionFlatComponent
} from "@shared/components/popover-wrapper/templates/popoverTemplateIListElemDescriptionFlat/popoverTemplateIListElemDescriptionFlat.component";
import {CommonModule} from "@angular/common";
import {RouterModule} from "@angular/router";
import {FormsModule} from "@angular/forms";
import {EclChartWrapperComponent} from "@shared/components/ecl-chart-wrapper/ecl-chart-wrapper.component";
import {DropdownWrapperComponent} from "@shared/components/dropdown/dropdown-wrapper/dropdown-wrapper.component";
import {DropdownDefaultComponent} from "@shared/components/dropdown/dropdown-default/dropdown-default.component";
import {DropdownOptionComponent} from "@shared/components/dropdown/dropdown-option/dropdown-option.component";
import {
    DropdownChartFilterComponent
} from "@shared/components/dropdown/dropdown-chart-filter/dropdown-chart-filter.component";
import {
    DropdownChartHighlightComponent
} from "@shared/components/dropdown/dropdown-chart-highlight/dropdown-chart-highlight.component";
import { ChartCorrelationMatrixComponent } from './charts/chart-correlation-matrix/chart-correlation-matrix.component';
import {ClickOutsideDirective} from "@shared/directives/click-outside-directive";
import {ChartScatterPlotComponent} from "@shared/charts/chart-scatter-plot/chart-scatter-plot.component";
import {TrackVisibilityDirective} from "@shared/directives/track-visibility.directive";
import {DropdownButtonComponent} from "@shared/components/dropdown/dropdown-button/dropdown-button.component";
import {ChartOldWrapperComponent} from "@shared/charts/chart-old-wrapper/chart-old-wrapper.component";
import {
    ChartUnitsQuantilesTableComponent
} from "@shared/charts/chart-units-quantiles-table/chart-units-quantiles-table.component";
import {ChartCorrelationMatrixSquareEmptyComponent} from "@shared/charts/chart-correlation-matrix/components/chart-correlation-matrix-square-empty.component";
import {ChartCorrelationMatrixSquareComponent} from "@shared/charts/chart-correlation-matrix/components/chart-correlation-matrix-square.component";
import {ChartCorrelationMatrixSquareWrapperComponent} from "@shared/charts/chart-correlation-matrix/components/chart-correlation-matrix-square-wrapper.component";

@NgModule({
    imports: [
        UxAllModule,
        EuiAllModule,
        EclAllModule,
        RouterModule,
        TranslateModule,
        ChartsModule,
        CompositeIndicatorModule,
        MatSliderModule,
        ClickOutsideModule,
        MatInputModule,
        MatFormFieldModule,
        EclIconComponentModule,
        Charts2Module,
        CommonModule,
        FormsModule
    ],
    declarations: [
        SliderPanelComponent,
        SliderComponent,
        IconPanelComponent,
        IconPanelsComponent,
        MatSliderComponent,
        SimpleClickOutsideDirective,
        ClickOutsideDirective,
        AppAdditionalDataDirective,
        TrackVisibilityDirective,
        CountryCardSmallComponent,
        SearchFilterTabComponent,
        CardImageComponent,
        CardNoImageComponent,
        ComponentScoreEditRowComponent,
        CardEclStyleComponent,
        PageHeaderEclStyleComponent,
        PaginationComponent,
        ResourceComponent,
        ListItemsListWrapperComponent,
        TableLayoutListItemsListWrapperComponent,
        TableMultilevelComponent,
        TableMultilevelCellComponent,
        EclFactFiguresComponent,
        FilesComponent,
        EclBestPracticesComponent,
        DropDownEclStyleComponent,
        EclChartDataTableComponent,
        CallbackPipe,
        EclBlockquoteComponent,
        EclKpiSideListComponent,
        EclBestPracticesSideListComponent,
        EclLinksListComponent,
        EclSkeletonLoaderComponent,
        EclChartContainerComponent,
        EclChartComponent,
        EclInpageNavComponent,
        EclBestPracticesByCountriesListComponent,
        EclChartFiltersSideListComponent,
        EclFiltersReadOnlyListComponent,
        EclSocialMediaShareComponent,
        FrameworkComponent,
        FrameworkChildComponent,
        EclChartControllerComponent,
        PopoverIndicatorDataComponent,
        NumberGrowComponent,
        TimelineComponent,
        ListItemTypeIndiceComponent,
        ListItemTypeDeveloperComponent,
        ListItemTypeCountryComponent,
        ListItemsListComponent,
        ListItemTypeCategoryComponent,
        AlphabeticalListComponent,
        ListItemsListFiltersComponent,
        HighlightCardComponent,
        SafeUrlPipe,
        SafeHTMLPipe,
        PopoverHowToReadThisChartComponent,
        PopoverWrapperComponent,
        PopoverTemplateIListElemDescriptionComponent,
        PopoverTemplateIListElemDescriptionFlatComponent,
        KpisWrapperComponent,
        KpiTypeStepLineComponent,
        KpiTypeIconComponent,
        EclChartWrapperComponent,
        DropdownWrapperComponent,
        DropdownDefaultComponent,
        DropdownOptionComponent,
        DropdownChartFilterComponent,
        DropdownChartHighlightComponent,
        DropdownButtonComponent,
        ChartCorrelationMatrixComponent,
        ChartCorrelationMatrixSquareEmptyComponent,
        ChartCorrelationMatrixSquareComponent,
        ChartCorrelationMatrixSquareWrapperComponent,
        ChartScatterPlotComponent,
        ChartOldWrapperComponent,
        ChartUnitsQuantilesTableComponent,
    ],
    exports: [
        EclAllModule,
        UxAllModule,
        EuiAllModule,
        TranslateModule,
        SliderPanelComponent,
        SliderComponent,
        IconPanelComponent,
        IconPanelsComponent,
        MatSliderComponent,
        ClickOutsideModule,
        SimpleClickOutsideDirective,
        ClickOutsideDirective,
        CountryCardSmallComponent,
        MatInputModule,
        MatFormFieldModule,
        SearchFilterTabComponent,
        CardImageComponent,
        CardNoImageComponent,
        ComponentScoreEditRowComponent,
        CardEclStyleComponent,
        PageHeaderEclStyleComponent,
        PaginationComponent,
        ResourceComponent,
        ListItemsListWrapperComponent,
        TableLayoutListItemsListWrapperComponent,
        TableMultilevelComponent,
        TableMultilevelCellComponent,
        EclFactFiguresComponent,
        FilesComponent,
        EclBestPracticesComponent,
        DropDownEclStyleComponent,
        EclChartDataTableComponent,
        CallbackPipe,
        AppAdditionalDataDirective,
        TrackVisibilityDirective,
        EclBlockquoteComponent,
        EclKpiSideListComponent,
        EclBestPracticesSideListComponent,
        EclLinksListComponent,
        EclSkeletonLoaderComponent,
        EclChartContainerComponent,
        EclChartComponent,
        EclInpageNavComponent,
        EclBestPracticesByCountriesListComponent,
        EclChartFiltersSideListComponent,
        EclFiltersReadOnlyListComponent,
        EclSocialMediaShareComponent,
        FrameworkComponent,
        FrameworkChildComponent,
        EclChartControllerComponent,
        PopoverIndicatorDataComponent,
        NumberGrowComponent,
        ListItemsListComponent,
        HighlightCardComponent,
        SafeUrlPipe,
        SafeHTMLPipe,
        PopoverHowToReadThisChartComponent,
        PopoverWrapperComponent,
        PopoverTemplateIListElemDescriptionComponent,
        PopoverTemplateIListElemDescriptionFlatComponent,
        KpisWrapperComponent,
        KpiTypeStepLineComponent,
        KpiTypeIconComponent,
        EclChartWrapperComponent,
        ChartCorrelationMatrixComponent,
        ChartCorrelationMatrixSquareEmptyComponent,
        ChartCorrelationMatrixSquareComponent,
        ChartCorrelationMatrixSquareWrapperComponent,
        ChartScatterPlotComponent,
        DropdownWrapperComponent,
        ChartOldWrapperComponent,
        ChartUnitsQuantilesTableComponent,
    ],
    providers: [
        AppSandboxService,
        GoogleAnalyticsService,
        TranslationsService
    ]
})
export class SharedModule {
}
