import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { IScale } from '@shared/interfaces';
import { ScalesActions, ScalesActionTypes } from './scales.actions';

export interface ScalesState extends EntityState<IScale> {
    loadedUrls: string[];
}

export const adapter: EntityAdapter<IScale> =
    createEntityAdapter<IScale>();

export const initialScalesState: ScalesState = adapter.getInitialState({
    loadedUrls: [],
});

export function scalesReducer(state = initialScalesState , action: ScalesActions): ScalesState {

    switch (action.type) {
    case ScalesActionTypes.ScalesLoaded:
        const urls = [...state.loadedUrls.filter((url) => url !== action.payload.url), action.payload.url];
        return adapter.addMany(action.payload.scales, { ...state, loadedUrls: urls });
    default:
        return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal,

} = adapter.getSelectors();
