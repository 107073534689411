import { createSelector } from '@ngrx/store';

import * as fromCmsCharts from '@features/general/charts/state/cms-charts.reducer';
import { selectGeneralState } from '@features/general/general.selectors';
import { ArrayHelper } from '@shared/helpers';

export const selectCmsChartsGeoState = createSelector(
    selectGeneralState,
    state => state.charts.geo
);

export const selectAllCmsChartsGeo = createSelector(
    selectCmsChartsGeoState,
    fromCmsCharts.selectAllChartsGeo
);

export const selectAllCmsChartsGeoEntities = createSelector(
    selectCmsChartsGeoState,
    fromCmsCharts.selectEntitiesChartsGeo
);
/**
 * Get the chart layers
 * @param chart_id
 */
export const selectCmsChartsGetChartLayers = (chart_id: string) => createSelector(
    selectAllCmsChartsGeo,
    (layers) => layers.filter((layer) => layer.chart_id === chart_id)
);

export const selectCmsChartsGetChartNoParamsLayers = (chart_id: string) => createSelector(
    selectCmsChartsGetChartLayers(chart_id),
    (layers) => layers.filter((layer) => layer.params === null)
);

export const selectCmsChartsGetChartLayersByParam = (chart_id: string, param: string) => createSelector(
    selectCmsChartsGetChartLayers(chart_id),
    (layers) => layers.filter((layer) => layer.params && layer.params.includes(param))
);

export const selectCmsChartsGetChartLayersWithAtLeastOneParam = (chart_id: string, params: string[]) => createSelector(
    selectCmsChartsGetChartLayers(chart_id),
    (layers) => layers.filter((layer) => layer.params && ArrayHelper.intersects(layer.params, params))
);
