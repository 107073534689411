import { Injectable } from '@angular/core';
import { IVariable } from '@shared/interfaces';
import { SharedService } from '@shared/shared.service';
import { Observable } from 'rxjs';

@Injectable()
export class VariablesService extends SharedService {

    getVariablesByUrl(url: string): Observable<IVariable[]> {
      return this.getByUrl(url);
  }

}
