import {APP_INITIALIZER, NgModule} from '@angular/core';
import { FormsModule } from '@angular/forms';
import {BrowserModule, withNoDomReuse} from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
    EclAllModule,
    EclCheckboxDirective,
    EclCheckboxInputDirective,
    // EclIconComponentModule,
    // EclLinkDirectiveModule,
    // EclMenuComponentModule,
} from '@eui/ecl';
import { EffectsModule } from '@ngrx/effects';
import { DragScrollModule } from 'ngx-drag-scroll';
import { AppBreadcrumbService } from './app-breadcrumb.service';
import { AppRoutingModule } from './app-routing.module';
import { AppStarterService } from './app-starter.service';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { HttpClientModule } from '@angular/common/http';
import {RouterModule} from "@angular/router";
import {EnvService} from "@shared/services/env.service";
import {provideClientHydration} from '@angular/platform-browser';
import { TranslateModule } from '@ngx-translate/core';
import {TransferHttpCacheModule} from '@nguniversal/common';

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        TranslateModule,
        RouterModule,
        BrowserModule,
        EffectsModule.forRoot([]),
        EclAllModule,
        // EffectsModule.forFeature(),
        BrowserAnimationsModule,
        CoreModule,
        HttpClientModule,
        AppRoutingModule,
        FormsModule,
        DragScrollModule,
        // EclMenuComponentModule,
        // EclLinkDirectiveModule,
        // EclIconComponentModule,
        TransferHttpCacheModule,

    ],
    bootstrap: [
        AppComponent,
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: (envService: EnvService) => () => {
                envService.reset();
                envService.init();
            },
            deps: [EnvService, AppStarterService],
            multi: true
        },
        // provideClientHydration(),
        provideClientHydration(withNoDomReuse()),
        EclCheckboxDirective,
        EclCheckboxInputDirective,
        AppBreadcrumbService,

    ]
})
export class AppModule {}
