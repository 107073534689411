import { Action } from '@ngrx/store';
import { ICountriesList } from '@shared/interfaces';

export enum CountriesActionTypes {
    TopCountriesGroupedByIncomeLevelByComponentRequested = '[COUNTRY] Top countries grouped by income level by component requested',
    TopCountriesGroupedByIncomeLevelByComponentLoaded = '[COUNTRY] Top countries grouped by income level by component loaded',
}

export class TopCountriesGroupedByIncomeLevelByComponentRequested implements Action {
    readonly type = CountriesActionTypes.TopCountriesGroupedByIncomeLevelByComponentRequested;

    constructor(public payload: { componentId: number }) {}
}

export class TopCountriesGroupedByIncomeLevelByComponentLoaded implements Action {
    readonly type = CountriesActionTypes.TopCountriesGroupedByIncomeLevelByComponentLoaded;

    constructor(public payload: { countriesList: ICountriesList }) {}
}

export type CountriesActions =
    TopCountriesGroupedByIncomeLevelByComponentRequested
    | TopCountriesGroupedByIncomeLevelByComponentLoaded;
