import { Inject, Injectable } from '@angular/core';
import {Title} from '@angular/platform-browser';
import {environment} from '../../../environments/environment';
import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';

declare const $wt; // European commission analytics plugin
declare const _paq; // Matomo

@Injectable()
export class MatomoService {
    protected isBrowser: boolean = false;
    protected isEnabled: boolean = false;

    constructor(
        private titleService: Title,
        @Inject(PLATFORM_ID) platformId: Object
    ) {
        this.isBrowser = isPlatformBrowser(platformId);

        if (environment.enableAnalyticsTracking) {
            this.isEnabled = true;
        }
    }

    public init(): void {
        if (!this.isTrackable()) {
            return;
        }
    }

    /**
     * @param {string} url
     */
    public trackPageView(url: string): void {
        if (! this.isTrackable()) {
            return;
        }

        $wt.trackPageView();
    }

    public trackLink(url: string): void {
        if (!this.isTrackable()) {
            return;
        }
        _paq.push(['trackLink', url, 'link']);
    }

    public trackDownload(url: string): void {
        if (!this.isTrackable()) {
            return;
        }
        _paq.push(['trackLink', url, 'download']);
    }

    private isTrackable(): boolean {
        if (!this.isBrowser) {
            return false;
        }
        return this.isEnabled && $wt.analytics.isTrackable();
    }
}
