import { Injectable } from '@angular/core';
import { ICategory } from '@shared/interfaces';
import { SharedService } from '@shared/shared.service';
import { Observable } from 'rxjs';

@Injectable()
export class CategoriesService extends SharedService {
    private categoriesLink = this.getApiUrl() + 'categories';

    loadCategories(): Observable<ICategory[]> {
        return this.getByUrl<ICategory[]>(this.categoriesLink);
    }

    getCategory(id: number): Observable<ICategory> {
        return this.getByUrl<ICategory>(this.categoriesLink + '/' + id);
    }

}
