import { sortArrayByAttr } from '@shared/helpers/array.helpers';
import { SortTypes } from '@shared/enums';

export class ContentHelpers {

    static getFullContentHeight = (minHeight: number = 800): number => {
        // const mainContentElement = document.getElementById('ecl-main-content');
        // const appHeaderElement = document.getElementById('app-header');
        // const navBarElement = document.getElementById('nav-bar');
        // const appFooterElement = document.getElementById('app-footer');
        // const mainContentElementPaddingBottom = window.getComputedStyle(mainContentElement, null).getPropertyValue('padding-bottom');
        // const height = parseFloat(window.innerHeight.toString()) - parseFloat(appHeaderElement.clientHeight.toString()) - parseFloat(navBarElement.clientHeight.toString()) - parseFloat(appFooterElement.clientHeight.toString()) - parseFloat(mainContentElementPaddingBottom);
        // return height >= minHeight ? height : minHeight;
        return minHeight;
    }

    public static normalize(string: string): string {
        const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;';
        const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------';
        const p = new RegExp(a.split('').join('|'), 'g');

        if (string) {
            string = string.normalize().toString().toLowerCase()
                .replace(/\s+/g, '-') // Replace spaces with -
                .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
                .replace(/&/g, '-and-') // Replace & with 'and'
                .replace(/[^\w\-]+/g, '') // Remove all non-word characters
                .replace(/\-\-+/g, '-') // Replace multiple - with single -
                .replace(/^-+/, '') // Trim - from start of text
                .replace(/-+$/, ''); // Trim - from end of text
        }
        return string;
    }

    public static convertNormalizeStringToText(string: string): string {
        let parts = string.split('-');
        parts = parts.map((part) => {
            return ContentHelpers.capitalize(part);
        });
        return parts.join(' ');
    }

    public static removeNonASCIIChars(string: string): string {
        return string
            .trim()
            .replace(/“/g, '"') // left quotes
            .replace(/”/g, '"') // right quotes
            .replace(/[^A-Za-z 0-9 \.,\?""!@#\$%\^&\*\(\)-_=\+;:<>\/\\\|\}\{\[\]`~]*/g, '');
    }

    public static search(searchableValues: number[] | string[], userInput: string) {
        let bool = false;
        userInput = ContentHelpers.normalize(userInput.toLowerCase());

        for (let i = 0; i < searchableValues.length; i++) {
            const value = ContentHelpers.normalize(searchableValues[i].toString().toLowerCase());
            if (value.includes(userInput) || userInput === value) {
                bool = true;
                break;
            }
        }
        return bool;
    }

    public static numberToAlphabetEquivalent(num: number, lowercase: boolean): string {
        let s = '';
        while (num > 0) {
            const t = (num - 1) % 26;
            s = String.fromCharCode(65 + t) + s;
            num = (num - t) / 26 | 0;
        }
        return s && lowercase ? s.toLowerCase() : s;
    }

    public static capitalize = (s: string): string => {
        return s.charAt(0).toUpperCase() + s.slice(1);
    }

    public static limitStrLength(str: string, limit: number, addThreeDots: boolean): string {
        return str.length > limit ? str.slice(0, limit) + (addThreeDots ? ' ...' : '') : str;
    }

    public static generateListByFirstLetter(data): {} {
        const list = {};

        for (let i = 0; i < data.length; i++) {
            const item = data[i];
            const firstLetter = item.title.toLowerCase().trim().slice(0, 1);
            if (!list[firstLetter]) {
                list[firstLetter] = [];
            }
            list[firstLetter].push(item);
        }
        // Sort
        for (const prop in list) {
            if (!list.hasOwnProperty(prop)) {
                continue;
            }
            list[prop] = sortArrayByAttr(list[prop], 'title', SortTypes.ASC, -1);
        }
        return list;
    }

    public static generateListOfFirstLetters(listByFirstLetter: any): string[] {
        const arr = [];
        for (const prop in listByFirstLetter) {
            if (!listByFirstLetter.hasOwnProperty(prop)) {
                continue;
            }
            arr.push(prop);
        }
        return arr.sort();
    }

    public static extractAndReplaceVariablesInString(string: string, callback: CallableFunction) {
        let res: any = string;
        const rawArr = ContentHelpers.extractVariablesFromString(string);

        if (rawArr) {
            for (const rawVariable of rawArr) {
                const props = ContentHelpers.extractVariablePropsFromRawVariable(rawVariable).split('.');
                let value = callback(props);

                if (value !== null && typeof value !== 'object' && ! Array.isArray(value)) {
                    // @ts-ignore
                    value = typeof value === 'string' ? value.replaceAll('"', '\\"') : value;
                    // res = !!value ? res.replace(new RegExp(rawVariable, 'g'), value) : res;
                }

                value = !! value ? value : '';
                value = value === 'none' ? '' : value;

                res = res.replace(new RegExp(rawVariable, 'g'), value);
            }
        }
        return res;
    }

    public static extractVariablesFromString(string: string): string[] {
        const regex = /{{[A-z0-9.]+}}/g;
        return (string || '').match(regex);
    }

    public static extractVariablePropsFromRawVariable(string: string): string {
        return string.replace('{{', '').replace('}}', '');
    }

    /**
     * Extract coordinates from an attribute like translate(3,4)
     * Returns an array of numbers, e.g. [3,4]
     * Only returns the first instance found
     * If no instance is found returns null
     * @param string
     */
    public static extractCoordinatesFromTransform(string: string): number[] {
        let result = null;
        const regExp = /\(([^)]+)\)/;

        string = string || '';

        const match = string.match(regExp);

        if (match) {
            result = match[1].split(',').map((d)=>parseFloat(d));
        }

        return result;
    }
}
