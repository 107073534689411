import { Action } from '@ngrx/store';
import { IGroupClass } from '@shared/interfaces';

export enum ClassesActionTypes {
    ClassesByEditionRequested = '[CLASSES] Requested all classes for an edition',
    ClassesLoaded = '[CLASSES]  Classes loaded',
}

export class ClassesByEditionRequested implements Action {
    readonly type = ClassesActionTypes.ClassesByEditionRequested;
}

export class ClassesLoaded implements Action {
    readonly type = ClassesActionTypes.ClassesLoaded;

    constructor(public payload: { classes: IGroupClass[] }) {}
}

export type ClassesActions =
    ClassesByEditionRequested
    | ClassesLoaded;
