import { Injectable } from '@angular/core';
import { IScale } from '@shared/interfaces';
import { SharedService } from '@shared/shared.service';
import { Observable } from 'rxjs';

@Injectable()
export class ScalesService extends SharedService {

    getScalesByUrl(url: string): Observable<IScale[]> {
        return this.getByUrl<IScale[]>(url);
    }

    getScalesByEditionUrl(indice: string, edition: number): string {
        return this.getApiUrl() + `scales/${indice}/${edition}`;
    }

    getScalesByEdition(indice: string, edition: number): Observable<IScale[]> {
        const url = this.getScalesByEditionUrl(indice, edition);
        return this.getScalesByUrl(url);
    }

}
