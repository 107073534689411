import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../core/reducers';
import { AllCategoriesRequested } from '@features/composite-indicator/state/categories/categories.actions';
import { selectAllCategories } from '@features/composite-indicator/state/categories/categories.selectors';
import { SetScreenState } from '../screen/screen.actions';
import { isDesktop, isMobile, isTablet, selectScreenSize } from '../screen/screen.selectors';

@Injectable()
export class AppSandboxService {
    mobile$ = this.store.select(isMobile);
    tablet$ = this.store.select(isTablet);
    desktop$ = this.store.select(isDesktop);
    state$ = this.store.select(selectScreenSize);

    constructor(private store: Store<AppState>) {}

    setWindowWidth(width: number): void {
        this.store.dispatch(new SetScreenState(width));
    }

    getAllCategories(): void {
        this.store.dispatch(new AllCategoriesRequested());
        const categories$ = this.store.select(selectAllCategories);
    }
}
