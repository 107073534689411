// import { ICmsParams } from '@features/general/charts/structure';
import { IList, IListElem } from '@features/general/lists/structure/lists.interfaces';
import { ListsCms, ListsCmsSlugs } from '@features/general/lists/structure/lists.structure';

export class ListsCmsHelper {

//    static lists = ListsCms;

    static getPartialListCmsUrlBySlug = (slug: ListsCmsSlugs, params, conversionRules = null): string => {

        let url: string = null;
        let convertedParams;

        if (ListsCms.has(slug)) {
            const listCms = ListsCms.get(slug);

            convertedParams = ListsCmsHelper.ConvertParams(params, conversionRules);
            if (ListsCmsHelper.validateListCmsParams(convertedParams, listCms.rules)) {
                url = listCms.route(convertedParams);
            }
        }
        return url;
    }

    static validateListCmsParams = (params, rules: string[]): boolean => {
        return rules.reduce((result, prop) => result && params.hasOwnProperty(prop) && (params[prop] != null), true);
    }

    static getElemByKey = (list: IList, key: string | number): IListElem => {
        return ListsCmsHelper.searchElem(list.values, key);
    }

    static searchElem = (elems: IListElem[], key: string | number): IListElem => {
        if (!elems || !elems.length) {
            return null;
        }
        let find_it = elems.find((elem) => elem.key === key);

        if (!find_it) {
          find_it = elems.reduce((res, elem) => res || ListsCmsHelper.searchElem(elem.children, key), null);
        }

        return find_it;
    }

    /**
     *
     * @param params Object with the params
     * @param rules Object with the set of rules to convert the params into the needed format
     * @constructor
     */
    static ConvertParams = (params: {[_param: string]: any}, rules: {[_rule: string]: string} = null) => {
        const converted = {};
        if (!rules) {
            return params;
        }
        for (const param in rules) {
            if (params.hasOwnProperty(param)) {
                const convertedParam = rules[param];
                converted[convertedParam] = params[param];
            }
        }
        return { ...params, ...converted };
    }

    static ConvertArgsToStringUrl = (args: string[], params: {[_param: string]: any}, rules: {[_rule: string]: string} = null): string => {
        let url = '?';

        const convertedParams = ListsCmsHelper.ConvertParams(params, rules);

        args.map((arg) => {
            if (convertedParams.hasOwnProperty(arg)) {
                const prefix = (url === '?' ? '' : '&');
                url += `${prefix}${arg}=${convertedParams[arg]}`;
            }
        });

        return url;
    }
}
