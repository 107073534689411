export const environment = {
    title: 'DEMO',
    showEnvTitleOnLogo: true,
    configFilePath: 'assets/openid-login-config.json',
    production: true,
    enableDevToolRedux: false,
    apiUrl: 'https://demo.api.coin-dev.eu/api/v1/{{locale}}/',
    cmsApiUrl: 'https://demo.api.coin-dev.eu/api/v1/{{locale}}/cms',
    i18nUrl: 'https://demo.api.coin-dev.eu/api/v1/{{locale}}/cms/i18n',
    backendServicesStorage: 'https://demo.coin-backend-services.coin-dev.eu/storage/',
    backendServicesApi: 'https://demo.coin-backend-services.coin-dev.eu/services/dissemination/api/',
    cmsStorageUrl: 'https://demo.cms.coin-dev.eu/storage',
    enableAnalyticsTracking: false,
    matomoUrl: null,
    matomoSiteId: null,
    enableGoogleAnalyticsTracking: false,
    googleAnalyticsSiteId: null,
};
