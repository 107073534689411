import {Component, Input} from '@angular/core';
import {BaseComponent} from "@shared/base-classes/base-component";
import {Action} from "@features/charts/models/charts.interfaces";
import {ITableMultilevel, ITableMultilevelConfig, ITableMultilevelHeadItem} from "@shared/interfaces";

export enum TableMultilevelComponentMobileLayoutTypes {
    LIST_TYPE,
    TABLE_TYPE
}

@Component({
  selector: 'app-table-multilevel',
  templateUrl: './table-multilevel.component.html',
  styleUrls: ['./table-multilevel.component.scss']
})
export class TableMultilevelComponent extends BaseComponent<ITableMultilevel, ITableMultilevelConfig, Action> {
    TableMultilevelComponentMobileLayoutTypes = TableMultilevelComponentMobileLayoutTypes;
    level: number = 0;

    @Input('level') set setLevel(level: number) {
        this.level = level;
    }
    head: ITableMultilevelHeadItem[] = [];

    @Input('head') set setHead(head: ITableMultilevelHeadItem[]) {
        this.head = head;
    }
    wideScreenOnly: boolean = false;

    @Input('wideScreenOnly') set setWideScreenOnly(wideScreenOnly: boolean) {
        this.wideScreenOnly = wideScreenOnly;
    }
    mobileOnly: boolean = false;

    @Input('mobileOnly') set setMobileOnly(mobileOnly: boolean) {
        this.mobileOnly = mobileOnly;
    }

    configUpdate() {
    }

    dataUpdate() {
    }

    initConfig(): any {
        this.initialConfig = {
            colWidth: {
                0: 20,
            },
            // Which layout to use for mobile?
            mobileLayout: TableMultilevelComponentMobileLayoutTypes.LIST_TYPE
        } as ITableMultilevelConfig;
        return this.initialConfig;
    }

    calcCellWidth(index: number, totalInRow: number): string {
        let width = 0;
        if (this.config.colWidth[index] || this.config.colWidth[index.toString()]) {
            if (this.config.colWidth[index]) {
                width = this.config.colWidth[index];

            } else if (this.config.colWidth[index.toString()]) {
                width = this.config.colWidth[index.toString()];
            }
        } else {
            let totalSetWidth = 0;
            let itemsWidthPreset = 0;
            for (let key in this.config.colWidth) {
                if (!this.config.colWidth.hasOwnProperty(key)) {
                    continue;
                }
                totalSetWidth += this.config.colWidth[key];
                itemsWidthPreset++;
            }
            width = (100 - totalSetWidth) / (totalInRow - itemsWidthPreset);
        }
        return width + '%';
    }

    isMobileWidth(): boolean {
        return self.outerWidth < 768;
    }
}
