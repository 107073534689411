import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { IList } from '@features/general/lists/structure';

export function selectListId(list: IList): string {
    return list.id;
}

export const listAdapter: EntityAdapter<IList> =
    createEntityAdapter<IList>({
        selectId: selectListId
    });
