import { Action } from '@ngrx/store';

export enum C3GeneralActionTypes {
    C3AllDataRequested = '[C3] All C3 Data Requested',
    C3IndiceLoaded = '[C3] Indice loaded',
    C3SetLastEdition = '[C3] Set C3 with last edition',
    C3SetActiveEdition = '[C3] Set Active Edition',
    C3SetDataYears = '[C3] Set Data Years',
    C3AllDataYearsRequested = '[C3] All Data Years Requested',
    C3SetDataBySelectedDataYear = '[C3] Set Data By Selected Data Year'
}

export class C3AllDataRequested implements Action {
    readonly type = C3GeneralActionTypes.C3AllDataRequested;
    constructor(public payload: { indice: string, edition: number }) {}
}
export class C3IndiceLoaded implements Action {
    readonly type = C3GeneralActionTypes.C3IndiceLoaded;
}
export class C3SetLastEdition implements Action {
    readonly type = C3GeneralActionTypes.C3SetLastEdition;
    constructor(public payload: {indice: string}) {}
}
export class C3SetActiveEdition implements Action {
    readonly type = C3GeneralActionTypes.C3SetActiveEdition;
    constructor(public payload: {indice: string, edition: number, dataYear?: number}) {}
}

export class C3AllDataYearsRequested implements Action {
    readonly type = C3GeneralActionTypes.C3AllDataYearsRequested;
    constructor(public payload: {type: string}) {}
}

export class C3SetDataYears implements Action {
    readonly type = C3GeneralActionTypes.C3SetDataYears;
    constructor(public payload: {data: any}) {}
}

export class C3SetDataBySelectedDataYear implements Action {
    readonly type = C3GeneralActionTypes.C3SetDataBySelectedDataYear;
    constructor(public payload: {data: any}) {}
}

export type C3GeneralActions =
    C3AllDataRequested
    | C3IndiceLoaded
    | C3SetLastEdition
    | C3SetActiveEdition
    | C3SetDataYears
    | C3AllDataYearsRequested
    | C3SetDataBySelectedDataYear;
