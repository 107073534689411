import { Action } from '@ngrx/store';
import { IComponent } from '@shared/interfaces';

export enum ComponentsActionTypes {
    EditionComponentsRequested = '[Component] Request Components for one edition',
    ComponentsByUrlRequested = '[Component] Components by url requested',
    ComponentsLoaded = '[Component] Components Loaded',
    UpdateComponent = '[Component] Component Update',
    ComponentsLastEditionRequested = '[Component] Request components for the last edition of an index'
}

export class EditionComponentsRequested implements Action {
    readonly type = ComponentsActionTypes.EditionComponentsRequested;

    constructor(public payload: { indice: string, edition: number }) {}
}

export class ComponentsByUrlRequested implements Action {
    readonly type = ComponentsActionTypes.ComponentsByUrlRequested;

    constructor(public payload: { url: string }) {}
}

export class ComponentsLoaded implements Action {
    readonly type = ComponentsActionTypes.ComponentsLoaded;

    constructor(public payload: { components: IComponent[] }) {}
}

export class UpdateComponent implements Action {
    readonly type = ComponentsActionTypes.UpdateComponent;

    constructor(public payload: { component: IComponent }) {}
}

export class ComponentsLastEditionRequested implements Action {
    readonly type = ComponentsActionTypes.ComponentsLastEditionRequested;

    constructor(public payload: { indice: string }) {}
}

export type ComponentsActions =
    EditionComponentsRequested
    | ComponentsByUrlRequested
    | ComponentsLoaded
    | UpdateComponent
    | ComponentsLastEditionRequested;
