import { Injectable } from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import { ROUTER_NAVIGATED, RouterNavigatedAction } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { HeadService } from '@features/general/head/state/head.service';
import { mergeMap } from 'rxjs/operators';
import {MatomoService} from "@shared/services/matomo.service";
import {GoogleAnalyticsService} from "@shared/services/google-analytics.service";

@Injectable()
export class RouterEffects {

    routerNavigated$ = createEffect(() =>this.actions$
        .pipe(
            ofType<RouterNavigatedAction>(ROUTER_NAVIGATED),
            mergeMap(action => {
                const url = action.payload.routerState.url;

                this.headService.createLinkForCanonicalURL(url);
                this.googleAnalyticsService.trackPageView(url);
                this.matomoService.trackPageView(url);
                return [];
            })
        ));

    constructor(
        private actions$: Actions,
        private store: Store<any>,
        private headService: HeadService,
        private matomoService: MatomoService,
        private googleAnalyticsService: GoogleAnalyticsService,
    ) {
    }
}
