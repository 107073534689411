import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'app/../environments/environment';
import { Action } from '@features/charts/models/charts.interfaces';
import {
    ICmsBaseModule,
    ICmsModuleFull,
    ICmsPage,
    ICmsPageFull,
    ICmsSite,
} from '@features/general/cms/structure';
import { HeadSetDescription, HeadSetKeywords, HeadSetTitle } from '@features/general/head/state/head.actions';
import { ToolService } from '@features/general/tool/state/tool.service';
import { TranslationsService } from '@features/general/translations/translations.service';
import { CmsHelpers } from '@shared/helpers/cms.helpers';
import { RequestHelpers } from '@shared/helpers/request.helpers';
import { IAnswer } from '@shared/interfaces';
import { Observable } from 'rxjs';
import { map, retry } from 'rxjs/operators';
import {EnvService} from "@shared/services/env.service";

@Injectable()
export class CmsService {

    constructor(
        protected envService: EnvService,
        protected http: HttpClient,
        protected translateService: TranslateService,
        protected translationsService: TranslationsService,
        protected router: Router,
        protected toolService: ToolService,
    ) {
        // ...
    }

    /**
     * @param {string} locale
     */
    getCmsApiUrl(locale?: string): string {
        return this.translationsService.ensureLocaleInUrl(this.envService.cmsApiUrl, locale);
    }

    getChartsApiUrl(locale?: string): string {
        return this.translationsService.ensureLocaleInUrl(this.envService.apiUrl, locale);
    }

    /**
     * API Calls
     */
    getCMSPage(page: string, mapData?: any): Observable<ICmsPageFull> {
        return this.getByUrlViaPostMethod<ICmsPageFull>(this.getCmsApiUrl() + '/page', { page_type: page, map: mapData });
    }

    getFullAnswerCMSPage(page: string, mapData?: any): Observable<IAnswer<ICmsPageFull>> {
        return this.getFullAnswerByUrlViaPost<ICmsPageFull>(this.getCmsApiUrl() + '/page', { page_type: page, map: mapData });
    }

    simplifyCmsAnswer(answer: IAnswer<ICmsPageFull>): IAnswer<ICmsPage> {
        return { ...answer, data: RequestHelpers.prepareResponse({ ...answer.data }) };
    }

    getSite(site: string): Observable<ICmsSite[]> {
        return this.getByUrlViaGetMethod<any>(this.getCmsApiUrl() + '/site/' + site);
    }

    /**
     * Methods
     */
    handleSEOFromCmsPage(page: ICmsPage, url: string): Action[] {
        let titleSuffix = null;
        let title = null;
        let description = null;
        let keywords = null;

        if (page) {
            if (url) {
                titleSuffix = this.translateService.instant('site.NAME');
                const tool = this.toolService.getToolIdentifierFromUrl(url);

                if (tool) {
                    titleSuffix = this.translateService.instant(tool + '.page.main');
                }

                // const toolRoute = this.toolService.getToolRouteByUrl(url);
                // if (toolRoute && toolRoute.data && toolRoute.data.breadcrumb) {
                //     titlePrefix = this.translateService.instant(toolRoute.data.breadcrumb);
                // }
            }
            title = this.SEOMetaTitleVariablesReplacementMapByPageSlugs(page, titleSuffix);

            description = this.SEOMetaDescriptionVariablesReplacementMapByPageSlugs(page);
            keywords = this.SEOMetaKeywords(page);
        }

        const arr = [];

        if (title) {
            arr.push(new HeadSetTitle({ title }));
        }
        // Force reset if no description even
        arr.push(new HeadSetDescription({ description }));
        // Force reset if no description even
        arr.push(new HeadSetKeywords({ keywords }));

        return arr;
    }

    /**
     * @param {string} link
     */
    generateStorageLink(link: string) {
        return CmsService.generateStorageLink(link);
    }

    /**
     * @param {string} link
     */
    generateBackendServicesStorageLink(link: string) {
        return CmsService.generateBackendServicesStorageLink(link);
    }

    static generateBackendServicesStorageLink(link: string): string {
        const envService = new EnvService();

        return envService.backendServicesStorage + link;
    }

    static generateStorageLink(link: string): string {
        const envService = new EnvService();

        return envService.cmsStorageUrl + '/' + link;
    }

    /**
     * @param page
     * @constructor
     */
    SEOMetaTitleVariablesReplacementMapByPageSlugs(page: ICmsPage, titleSuffix: string): string {
        let metaTitle = page.meta_title ? page.meta_title : page.title;

        let slug = null;
        let module = null;
        switch (page.slug) {
            // Datahub/explorer
            case 'datahub-indice-profile':
                slug = 'data-hub-profile-header';
                module = this.searchPageModule(page, slug);
                if (module) {
                        // @ts-ignore
                    metaTitle = metaTitle.replace('{{index-name}}', module.api[slug].title) + ' | ' + page.title;
                }
                break;

            case 'data-hub-unit-profile':
                slug = 'data-hub-profile-header';
                module = this.searchPageModule(page, slug);
                if (module) {
                        // @ts-ignore
                    metaTitle = metaTitle.replace('{{unit-name}}', module.api[slug].title) + ' | ' + page.title;
                }
                break;

            case 'data-hub-scoreboard-profile':
                slug = 'scoreboard';
                module = this.searchPageModule(page, slug);
                if (module) {
                    metaTitle = module.api[slug].name + ' | ' + metaTitle;
                }
                break;

            case 'category-profile':
                slug = 'data-hub-profile-header';
                module = this.searchPageModule(page, slug);
                if (module) {
                    metaTitle = module.api[slug].title + ' | ' + metaTitle;
                }
                break;

            case 'data-hub-developer-profile':
                slug = 'data-hub-profile-header';
                module = this.searchPageModule(page, slug);
                if (module) {
                    metaTitle = module.api[slug].title + ' | ' + metaTitle;
                }
                break;

            // MIMF

            case 'eu-mimf-country-profile':
                slug = 'eu-mimf-active-unit';
                module = this.searchPageModule(page, slug);
                if (module) {
                    const name = module.api[slug].name_en ? module.api[slug].name_en : module.api[slug].name;
                    metaTitle = name + ' | ' + metaTitle;
                }
                break;

            // GES
            case 'ges-country-profile':
                slug = 'ges-monitor-active-unit';
                module = this.searchPageModule(page, slug);
                if (module) {
                    const name = module.api[slug].name_en ? module.api[slug].name_en : module.api[slug].name;
                    metaTitle = name + ' | ' + metaTitle;
                }
                break;

            default:
                break;
        }

        if (titleSuffix && metaTitle !== titleSuffix) {
            metaTitle =  metaTitle + ' | ' + titleSuffix;
        }

        return metaTitle;
    }

    /**
     * @param page
     * @constructor
     */
    SEOMetaDescriptionVariablesReplacementMapByPageSlugs(page: ICmsPage): string {
        let metaDescription = page.meta_description ? page.meta_description : null;

        let slug = null;
        let module = null;
        switch (page.slug) {
            case 'datahub-indice-profile':
                slug = 'index';
                module = this.searchPageModule(page, slug);
                if (module) {
                    const description = module.api[slug].description;
                    // @ts-ignore
                    metaDescription = description ? metaDescription.replace('{{index-name}}', description) : null;
                }
                break;

            case 'data-hub-unit-profile':
                slug = 'data-hub-profile-header';
                module = this.searchPageModule(page, slug);
                if (module) {
                    const description = module.api[slug].description;
                    // @ts-ignore
                    metaDescription = description ? metaDescription.replace('{{unit-name}}', description) : null;
                }
                break;

            case 'data-hub-scoreboard-profile':
                slug = 'scoreboard';
                module = this.searchPageModule(page, slug);
                if (module) {
                    // @ts-ignore
                    metaDescription = module.api[slug].description;
                }
                break;

            case 'category-profile':
                slug = 'data-hub-profile-header';
                module = this.searchPageModule(page, slug);
                if (module) {
                    metaDescription = module.api[slug].description;
                }
                break;

            case 'data-hub-developer-profile':
                slug = 'data-hub-profile-header';
                module = this.searchPageModule(page, slug);
                if (module) {
                    metaDescription = module.api[slug].description;
                }
                break;

        default:
            break;
        }
        return metaDescription;
    }

    /**
     * @param page
     * @constructor
     */
    SEOMetaKeywords(page: ICmsPage): string {
        return page.meta_keywords ? page.meta_keywords : null;
    }

    /**
     * @param page
     * @param slug
     */
    searchPageModule(page: ICmsPage, slug: string): ICmsModuleFull|null {
        return page.modules.find((module) => module.slug === slug);
    }

    /**
     * @param {ICmsBaseModule[]} data
     */
    handleDocumentsData(data: ICmsBaseModule[]): any[] {
        const files = [];

        for (let module of data) {
            if (! module) {
                continue;
            }
            module = CmsHelpers.decodeModuleData(module);
            // @ts-ignore
            const href = module.file1 && module.file1.download_link ? this.generateStorageLink(module.file1.download_link) : module.hasOwnProperty('url') ? module.url : null
            // @ts-ignore
            const disabled = (module.hasOwnProperty('url') && ! module.url) || (! module.hasOwnProperty('url') && ! href);

            const linkType = module.json_field_1?.link_type;

            let label = 'download'; // Translated on html side with | translate
            switch (linkType) {
                case 'link':
                    label = 'key_go_to_link'; // Translated on html side with | translate
                    break;
            }
            files.push({
                title: module.title, // Translated on html side with | translate
                language: module.text_content ? module.text_content : 'language_en', // Translated on html side with | translate
                meta: module.file1 && module.file1.original_name ? '(' + module.file1.original_name.split('.').pop().toUpperCase() + ')' : null,
                label,
                linkType,
                description_after: module.html_content, // Translated on html side with | translate
                href,
                disabled,
                fileName: module.file1 && module.file1.original_name ? module.file1.original_name : null,
            });
        }

        return files;
    }

    private getByUrlViaGetMethod<T>(url: string): Observable<T> {
        return this.http.get<IAnswer<T>>(url)
            .pipe(
                retry(3),
                map((res) => res.data),
            );
    }

    private getByUrlViaPostMethod<T>(url: string, body: any): Observable<T> {
        return this.http.post<IAnswer<T>>(url, JSON.stringify(body))
            .pipe(
                retry(3),
                map((res) => res.data),
            );
    }

    private getFullAnswerByUrlViaPost<T>(url: string, body: {[_: string]: any}): Observable<IAnswer<T>> {
        return this.http.post<IAnswer<T>>(url, JSON.stringify(body))
            .pipe(
                retry(3),
                map((res) => ({ data: res.data, url, body }))
            );
    }
}
