import {
    asyncRequestsAdapter, AsyncRequestsHelper, AsyncRequestsState, AsyncRequestStatus
} from '@features/general/async_requests/async-requests.entity';
import {
    AsyncRequestsActions, AsyncRequestsActionTypes
} from '@features/general/async_requests/async-requests.actions';
import {
    asyncRequestItemsAdapter, AsyncRequestItemsState, AsyncRequestsItemsHelper, selectAsyncRequestItemsId
} from '@features/general/async_requests/async-requests-items.entity';

export interface AsyncRequestFullState {
    requests: AsyncRequestsState;
    items: AsyncRequestItemsState;
}

export const initialAsyncRequestsReducerState: AsyncRequestFullState = {
    requests: asyncRequestsAdapter.getInitialState(),
    items: asyncRequestItemsAdapter.getInitialState()
};

export function asyncRequestsReducer(state = initialAsyncRequestsReducerState, action: AsyncRequestsActions): AsyncRequestFullState {
    let request;
    let items;
    let update_obj;
    let id;
    switch (action.type) {
        case AsyncRequestsActionTypes.ARSetRequest:
           return { ...state, requests: asyncRequestsAdapter.addOne({ ...action.payload, status: AsyncRequestStatus.incomplete}, state.requests) };
        case AsyncRequestsActionTypes.ARRemoveAll:
            return { ...state,
                requests: asyncRequestsAdapter.removeAll(state.requests),
                items: asyncRequestItemsAdapter.removeAll(state.items)
            };
        case AsyncRequestsActionTypes.ARRemoveOne:
            items = state.requests.entities[action.payload.id] && state.requests.entities[action.payload.id].items || [];
            return { ...state, requests: asyncRequestsAdapter.removeOne(action.payload.id, state.requests), items:  asyncRequestItemsAdapter.removeMany(items, state.items)};
        case AsyncRequestsActionTypes.ARSetRequestItems:
            return { ...state, items: asyncRequestItemsAdapter.addMany(action.payload.items, state.items) };
        case AsyncRequestsActionTypes.ARSetStatus:
            update_obj = AsyncRequestsHelper.setStatus(action.payload.request_id, action.payload.status);
            return { ...state, requests: asyncRequestsAdapter.updateOne(update_obj, state.requests) };
        case AsyncRequestsActionTypes.ARSetItem:
            id = selectAsyncRequestItemsId({ request_id: action.payload.request_id, id: action.payload.item_id });
            update_obj = AsyncRequestsItemsHelper.updateItem(id, action.payload.result);
            return { ...state, items: asyncRequestItemsAdapter.updateOne(update_obj, state.items) };
        case AsyncRequestsActionTypes.ARSetRequestWithItems:
            request = AsyncRequestsHelper.createRequest(action.payload.request_id, action.payload.item_ids);
            items = action.payload.item_ids.map((item) => AsyncRequestsItemsHelper.createItem(action.payload.request_id, item));
            return { ...state, requests: asyncRequestsAdapter.addOne(request, state.requests), items: asyncRequestItemsAdapter.addMany(items, state.items) };
        default:
            return state;
    }
}

export const {
    selectAll: selectAllRequests,
    selectEntities: selectAllRequestsEntities,
} = asyncRequestsAdapter.getSelectors();

export const {
    selectAll: selectAllRequestsItems,
    selectEntities: selectAllRequestsItemsEntities,
} = asyncRequestItemsAdapter.getSelectors();
