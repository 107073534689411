import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { IData } from '@shared/interfaces';
import { DataActions, DataActionTypes } from './data.actions';

export interface DataState extends EntityState<IData> {
    loadedUrls: string[];
}

export const adapter: EntityAdapter<IData> =
    createEntityAdapter<IData>({});

export const initialDataState: DataState = adapter.getInitialState({
    loadedUrls: [],
});

export function dataReducer(state = initialDataState , action: DataActions): DataState {

    switch (action.type) {
    case DataActionTypes.DataLoaded:
        // state = { ...state, ids: [], entities: {}, loadedUrls: [] };

 //       console.log('data state', state);
        const urls = state.loadedUrls.filter((url) => url !== action.payload.url);
        // return adapter.setAll(action.payload.data, { ...state, loadedUrls: [...urls, action.payload.url] });
        return adapter.setAll(action.payload.data, { ...state });
    default: {
            return state;
        }
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal,

} = adapter.getSelectors();
