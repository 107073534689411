import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromResources from './resources.reducers';

import { CoinState } from '../index';

export const selectCoinState = createFeatureSelector<CoinState>('composite');

export const selectResourcesState = createSelector(
    selectCoinState,
    (state) => state.resources,
);

export const selectAllResources = createSelector(
    selectResourcesState,
    fromResources.selectAll,
);

export const selectResourcesLoaded = createSelector(
    selectResourcesState,
    (state) => state.resourcesLoaded,
);

export const selectResourcesByType = (resourceType: string) => createSelector(
    selectAllResources,
    (resources) => resources.filter((resource) => resource.resourceType === resourceType),
);

export const selectResourcesById = (resourceId: number, resourceType: string) => createSelector(
    selectAllResources,
    (resources) => resources.filter((resource) => resource.resourceType === resourceType && resource.id === resourceId),
);

export const selectResourcesByIds = ( ids: Array<{'resourceId': number, 'resourceType': string}>) => createSelector(
    selectAllResources,
    (resources) => resources.filter((resource) => !!ids.find((id) => (resource.resourceType === id.resourceType) && (resource.id === id.resourceId))),
);

export const selectAllResourcesLoaded = (resourceType: string) => createSelector(
    selectResourcesLoaded,
    (resources) => !!resources.find((res) => res === resourceType),
);

export const selectResourceLinkByIdRel = (resourceId: number, resourceType: string, rel: string) => createSelector(
    selectResourcesById(resourceId, resourceType),
    (resources) => {
        try {
            return resources.find((r) => r.rel === rel).links.find((l) => l.rel === 'self').href;
        } catch (e) {
            return null;
        }
    },
);
