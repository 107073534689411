import { RouterHelper } from '@shared/helpers';

export enum GesMonitorRoutesList {
    HOME_PAGE = '',
    COUNTRY_PROFILES = 'country-profiles',
    COUNTRY_PROFILE = 'country-profile',
    MAPS = 'charts',
    RANKINGS = 'rankings',
    TIMELINE = 'time-line',
    DOCUMENTATION = 'resources',
    MAINTENANCE = 'maintenance',
}

export class GesMonitorRoutes extends RouterHelper {
    static routesClass = GesMonitorRoutes;
    static MAIN_URL = 'ges-monitor';
    static CMS_SITE = 'ges';
    static routes = GesMonitorRoutesList;
}
