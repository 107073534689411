import { createFeatureSelector, createSelector } from '@ngrx/store';

import { CoinState } from '../index';
import * as fromScales from './scales.reducers';

export const selectCoinState = createFeatureSelector<CoinState>('composite');

export const selectScalesState = createSelector(
    selectCoinState,
    (state) => state.scales,
);

export const selectScalesLoadedUrls = createSelector(
    selectScalesState,
    (state) => state.loadedUrls,
);

export const selectAllScales = createSelector(
    selectScalesState,
    fromScales.selectAll,
);

export const selectScalesByEdition = (indice: string, edition: number) => createSelector(
    selectAllScales,
    (scales) => scales.find((s) => s.indice === indice && s.edition === edition),
);

export const selectScalesUrlIsLoaded = (url: string) => createSelector(
    selectScalesLoadedUrls,
    (urls) => urls.includes(url),
);
