import { ICmsBaseModule, ICmsModuleFull } from '@features/general/cms/structure';
import { environment } from '../../../environments/environment';
import {EnvService} from "@shared/services/env.service";

export class CmsHelpers {

    /**
     * @param {ICmsBaseModule|ICmsModuleFull} module
     */
    static decodeModuleData(module) {
        if (! module) {
            return module;
        }

        // Cover file1, file2, ... to be decoded as objects if given as json strings
        for (let i = 1; i < 100; i++) {
            const prop = 'file' + i;

            if (! module.hasOwnProperty(prop)) {
                break;
            }

            let value = module[prop];

            if (typeof value === 'string' || value instanceof String) {
                value = JSON.parse(value as string);

                module = { ...module, [prop]: value[0] };
            }
        }

        let children = module?.children;
        const arr = [];
        if (children && children.length) {
            for (const child of children) {
                arr.push(CmsHelpers.decodeModuleData(child));
            }
        }
        children = arr;

        return { ...module, children };
    }

    /**
     * @param {ICmsBaseModule|ICmsModuleFull} module
     * @param {String} url
     */
    static decodeUrlAsFile(module, url) {
        let fileName = null;
        if (['file1', 'file2', 'file3'].includes(url)) {
            fileName = CmsHelpers.generateFileName(module, url);
            url = CmsHelpers.generateFileUrl(module, url);
        }
        return { url, fileName };
    }

    /**
     * @param {ICmsBaseModule|ICmsModuleFull} module
     * @param {String} url
     */
    static generateFileUrl(module, url): string {
        const envService = new EnvService();

        return module[url] && module[url].download_link ? envService.cmsStorageUrl + '/' + module[url].download_link : null;
    }

    /**
     * @param {ICmsBaseModule|ICmsModuleFull} module
     * @param {String} url
     */
    static generateFileName(module, url) {
        return module[url] && module[url].original_name ? module[url].original_name : null;
    }
}
