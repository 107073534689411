import { Injectable } from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import { effect } from 'signal';
import { Store } from '@ngrx/store';
import { filter, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { Title, Meta } from '@angular/platform-browser';
import { HeadActionTypes, HeadSetDescription, HeadSetKeywords, HeadSetTitle } from '@features/general/head/state/head.actions';

@Injectable()
export class HeadEffects {

    headSetTitle = createEffect(() =>this.actions$
        .pipe(
            ofType<HeadSetTitle>(HeadActionTypes.HeadSetTitle),
            mergeMap((request) => {
                this.titleService.setTitle(request.payload.title);
                this.metaService.updateTag({ name: 'Date', content: new Date().toLocaleDateString() }, `name='Date'`);
                this.metaService.updateTag({ property: 'og:url', content: window.location.href }, `property='og:url'`);
                this.metaService.updateTag({ property: 'og:site_name', content: request.payload.title }, `property='og:site_name'`);
                this.metaService.updateTag({ property: 'og:title', content: request.payload.title }, `property='og:title'`);
                this.metaService.updateTag({ name: 'twitter:title', content: request.payload.title }, `name='twitter:title'`);

                return [];
            })
        ));

    headSetDescription = createEffect(() =>this.actions$
        .pipe(
            ofType<HeadSetDescription>(HeadActionTypes.HeadSetDescription),
            mergeMap((request) => {
                this.metaService.updateTag({ name: 'description', content: request.payload.description ? request.payload.description : '' }, `name='description'`);
                this.metaService.updateTag({ property: 'og:description', content: request.payload.description ? request.payload.description : '' }, `property='og:description'`);
                return [];
            })
        ));

    headSetKeywords = createEffect(() =>this.actions$
        .pipe(
            ofType<HeadSetKeywords>(HeadActionTypes.HeadSetKeywords),
            mergeMap((request) => {
                this.metaService.updateTag({ name: 'keywords', content: request.payload.keywords ? request.payload.keywords : '' }, `name='keywords'`);
                return [];
            })
        ));

    constructor(
        private actions$: Actions,
        private store: Store<any>,
        private titleService: Title,
        private metaService: Meta,
    ) {}
}
