import { InjectionToken } from '@angular/core';
import { CoreState, localStorageSync, reducers as coreReducers } from '@eui/core';
import { breadcrumbsStateReducer } from '@shared/breadcrumbs/breadcrumbs.reducers';
import { ScreenState } from '@shared/interfaces';
import { screenStateReducer } from '@shared/screen/screen.reducers';
import { routerReducer } from '@ngrx/router-store';
import * as fromRouter from '@ngrx/router-store';

export const REDUCER_TOKEN = new InjectionToken('Registered Reducers');

/**
 * Define here your app state
 *
 * [IMPORTANT]
 * There are some **reserved** slice of the state
 * that you **can not** use in your application ==> app |user | notification
 */
/* tslint:disable-next-line */
export interface AppState extends CoreState {
    // [key: string]: fromTaskManager.State | any;
    screen: ScreenState;
    router: fromRouter.RouterReducerState<any>;
}

/**
 * Define here the reducers of your app
 */
const rootReducer = Object.assign({}, {router: routerReducer}, coreReducers, {
    // [fromTaskManager.namespace]: fromTaskManager.reducers,
    screen: screenStateReducer,
    breadcrumbs: breadcrumbsStateReducer,
    // router: routerReducer
}, localStorageSync);

export function getReducers() {
    return rootReducer;
}
