import { ItemTypesCOINAPI } from '@shared/enums';
import { ICategory, ICity, ICountry, IDeveloper, IIndice, INuts, IUnit } from '@shared/interfaces';

export type ItemRouteType =  IIndice | IUnit | ICountry | ICity | INuts | ICategory | IDeveloper | null;

export class ItemHelpers {

    static routerLinkParams = new Map([
        [
            ItemTypesCOINAPI.INDICE,
            (indice) => [{ value: indice.code.toString().toLowerCase(), slugify: false }, indice.name]
        ],
        [
            ItemTypesCOINAPI.UNIT,
            (unit) => [{ value: unit.code.toString().toLowerCase(), slugify: false }, unit.name_en ? unit.name_en : unit.name]
        ],
        [
            ItemTypesCOINAPI.COUNTRY,
            (country) => [{ value: country.code.toString().toLowerCase(), slugify: false }, country.name_en ? country.name_en : country.name]
        ],
        [
            ItemTypesCOINAPI.CITY,
            (city) => [{ value: city.code.toString().toLowerCase(), slugify: false }, city.name_en ? city.name_en : city.name]
        ],
        [
            ItemTypesCOINAPI.REGION,
            (region) => [{ value: region.code.toString().toLowerCase(), slugify: false }, region.name_en ? region.name_en : region.name]
        ],
        [
            ItemTypesCOINAPI.CATEGORY_GROUP,
            (categoryGroup) => [categoryGroup.id.toString(), categoryGroup.name]
        ],
        [
            ItemTypesCOINAPI.CATEGORY,
            (category) => [category.id.toString(), category.name]
        ],
        [
            ItemTypesCOINAPI.CATEGORIES,
            (category) => [category.name]
        ],
        [
            ItemTypesCOINAPI.DEVELOPER,
            (developer) => [developer.id.toString(), developer.name]
        ],
        [
            ItemTypesCOINAPI.SCOREBOARD,
            (scoreboard) => [{ value: scoreboard.code.toString().toLowerCase(), slugify: false }, scoreboard.name]
        ],
        [
            ItemTypesCOINAPI.INDICES_AND_SCOREBOARDS,
            (indicedOrscoreboardItem) => [{ value: indicedOrscoreboardItem.code.toString().toLowerCase(), slugify: false }, indicedOrscoreboardItem.name]
        ],
    ]);

    static generateRouterLinkParams(type, item: ItemRouteType): string[] {
        const router = ItemHelpers.routerLinkParams.get(type);
        return item && router && router(item) || [];
    }
}
