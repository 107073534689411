import { Action } from '@ngrx/store';
import { IUnit } from '@shared/interfaces';

export enum UnitsActionTypes {
    AllUnitsByTypeRequested = '[UNIT] All units requested for one type',
    AllUnitsWithIndicesByTypeRequested = '[UNIT] All units with indices requested for one type',
    UnitsByUrlRequested = '[UNIT] Units by url requested',
    UnitByCodeRequested = '[UNIT] Unit by code requested',
    UnitsLoaded = '[UNIT] Units Loaded',
    UnitsByUrlLoaded = '[UNIT] Units by url loaded',
    UnitsUpdated = '[UNIT] Units updated'
}

export class UnitByCodeRequested implements Action {
    readonly type = UnitsActionTypes.UnitByCodeRequested;

    constructor(public payload: { code: string }) {}
}

export class AllUnitsByTypeRequested implements Action {
    readonly type = UnitsActionTypes.AllUnitsByTypeRequested;

    constructor(public payload: { type: string }) {}
}

export class AllUnitsWithIndicesByTypeRequested implements Action {
    readonly type = UnitsActionTypes.AllUnitsWithIndicesByTypeRequested;

    constructor(public payload: { type: string }) {}
}

export class UnitsByUrlRequested implements Action {
    readonly type = UnitsActionTypes.UnitsByUrlRequested;

    constructor(public payload: { url: string }) {}
}

export class UnitsLoaded implements Action {
    readonly type = UnitsActionTypes.UnitsLoaded;

    constructor(public payload: { units: IUnit[], url?: string, type?: string }) {}
}

export class UnitsByUrlLoaded implements Action {
    readonly type = UnitsActionTypes.UnitsByUrlLoaded;

    constructor(public payload: { units: IUnit[], url: string }) {}
}

export class UnitsUpdated implements Action {
    readonly type = UnitsActionTypes.UnitsUpdated;

    constructor(public payload: { units: IUnit[], type: string }) {}
}

export type UnitsActions =
      UnitByCodeRequested
    | AllUnitsByTypeRequested
    | AllUnitsWithIndicesByTypeRequested
    | UnitsByUrlRequested
    | UnitsLoaded
    | UnitsByUrlLoaded
    | UnitsUpdated;
