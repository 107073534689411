import { Injectable } from '@angular/core';
import { IAsyncRequestItem } from '@features/general/async_requests/async-requests-items.entity';
import {GeneralService} from "@features/general/general.service";
import {IPDFTable} from "@shared/interfaces";
import * as jsPDF from 'src/assets/jspdf.min';
// import { applyPlugin } from 'jspdf-autotable/dist/jspdf.plugin.autotable.min.js';
// applyPlugin(jsPDF);
import domtoimage from 'dom-to-image';

export enum PDFElementTypes {
    ELEMENT = 'pdf-element',
    GROUP_ELEMENT = 'pdf-group-element',
    AS_IMAGE = 'pdf-as-image',
    AS_LINK = 'pdf-as-link',
    AS_GROUP_ELEMENT = 'pdf-grouped',
    AS_ECL_CHART = 'pdf-as-ecl-chart',
    PAGE_BREAK = 'pdf-page-break',
    PAGE_LANDSCAPE = 'pdf-page-landscape',
    PAGE_PORTRAIT = 'pdf-page-portrait',
    AS_TABLE = 'pdf-as-table',
    AS_IMAGE_TO_BASE64 = 'pdf-as-image-to-base64',
}

@Injectable()
export class PDFService extends GeneralService {

    // Default jsPDF A4 72DPI - 595 x 842
    static browserContentWidth: number = 670;
    protected static mainElementId: string = 'pdf-layout';
    public static paddingTop: number = 30;
    protected static paddingBottom: number = 30;
    public static paddingLeft: number = 30;
    protected static paddingRight: number = 30;
    protected lastPageFormat: string = 'portrait';

    public static PdfContentWidth = (doc: jsPDF) => doc.internal.pageSize.getWidth() - (PDFService.paddingLeft + PDFService.paddingRight);
    public static PdfContentHeight = (doc: jsPDF) => doc.internal.pageSize.getHeight() - (PDFService.paddingTop + PDFService.paddingBottom);
    protected static ratioFromPdfToBrowser = (doc: jsPDF) => PDFService.browserContentWidth / PDFService.PdfContentWidth(doc);

    protected title: string;
    protected asyncItems: IAsyncRequestItem[] = [];
    protected pdf: jsPDF;
    protected pdfOriginalTableElements: HTMLCollection;

    generatePDF(callback?: CallableFunction): void {
        this.pdf = new jsPDF({
            orientation: 'p',
            unit: 'px'
        });
        this.beforePDFGeneration();
        this.convertElementsToImages( () => {  // Prepare elements to images first if any because it's an async action
            this.generatePDFStep2(this.pdf);
            this.pdf.save(PDFService.generatePDFFileName(this.title), { returnPromise: true }).then(() => {
                if (callback) {
                    callback();
                }
            });
            this.afterPDFGeneration();
        });
    }

    setTitle(title: string): void {
        this.title = title;
    }

    setLoadedAsyncItems(arr: IAsyncRequestItem[]): void {
        this.asyncItems = arr;
    }

    protected convertElementsToImages(callback: CallableFunction): void {
        let pdfElementsAndGroupElements = window.document.querySelectorAll('.' + PDFElementTypes.ELEMENT + ', .' + PDFElementTypes.GROUP_ELEMENT);
        let elementsToImages = [];
        let imageElementsToBase64Images = [];
        for (let i = 0; i < pdfElementsAndGroupElements.length; i++) {
            let element: any = pdfElementsAndGroupElements[i];
            if (PDFService.isImageToBase64Element(element)) {
                imageElementsToBase64Images.push(element);
            } else if (PDFService.isImageElement(element)) {
                elementsToImages.push(element);
            }
        }
        if (!elementsToImages.length && !imageElementsToBase64Images.length) {
            callback();
        } else {
            // Process html to image convertion
            let total = elementsToImages.length + imageElementsToBase64Images.length;
            let processed = 0;
            for (let i = 0; i < elementsToImages.length; i++) {
                let elementToImage: any = elementsToImages[i];

                domtoimage.toPng(elementToImage)
                    .then((dataUrl) => {
                        // let img = new Image();
                        // img.src = dataUrl;
                        // document.body.appendChild(img);
                        elementToImage.base64 = dataUrl;
                        processed++;
                        if (processed === total) {
                            callback();
                        }
                    })
                    .catch((error) => {
                        console.error('oops, something went wrong!', error);
                    });
            }
            for (let i = 0; i < imageElementsToBase64Images.length; i++) {
                let imageElementToImage: any = imageElementsToBase64Images[i];
                PDFService.convertImageUrlToBase64(
                    imageElementToImage.getAttribute('src'),
                    (dataUrl) => {
                        imageElementToImage.base64 = dataUrl;
                        processed++;
                        if (processed === total) {
                            callback();
                        }
                    },
                    imageElementToImage.getAttribute('data-quality'),
                    imageElementToImage.getAttribute('data-background'),
                );
            }
        }
    }

    protected generatePDFStep2(doc: jsPDF): void {
        let pages = 1;
        let mainElement = PDFService.getMainElement();
        // mainElement.style.width = browserContentWidth + 'px';
        let mainElementDOMRect: any = mainElement.getBoundingClientRect();
        let topZeroY = mainElementDOMRect.y;
        let finalY: number = null;
        let elements = window.document.getElementsByClassName(PDFElementTypes.ELEMENT);
        for (let i = 0; i < elements.length; i++) {
            let element: any = elements[i];
            const DOMRect: any = element.getBoundingClientRect();

            let PDFDOMRect = PDFService.convertDOMRectToPDFDOMRect(element, doc, topZeroY);

            if (PDFService.isPageBreak(element)) {
                this.lastPageFormat = 'portrait';
                doc.addPage();
                pages++;
                topZeroY = DOMRect.y + PDFService.paddingTop;
                continue;
            }

            if (PDFService.isPageLandscape(element)) {
                this.lastPageFormat = 'landscape';
                doc.addPage('a4', 'landscape');
                pages++;
                topZeroY = DOMRect.y + PDFService.paddingTop;
                continue;
            }

            if (PDFService.isPagePortrait(element)) {
                this.lastPageFormat = 'portrait';
                doc.addPage('a4', 'portrait');
                pages++;
                topZeroY = DOMRect.y + PDFService.paddingTop;
                continue;
            }

            /**
             * Recalculate top zero y when we have a final "y" i.e. after generating auto-table, it's end may differ and on html we don't have it's height,
             * because style on pdf is different, so this needs to be dynamically adapted and after generating auto-table we get it's end cursor position as
             * finalY by which we can recalculate the top y equivalent
             */
            if (finalY) {
                topZeroY = PDFService.recalculateTopZeroYByFinalY(PDFDOMRect.y, topZeroY, finalY, PDFService.ratioFromPdfToBrowser(doc));
                PDFDOMRect = PDFService.convertDOMRectToPDFDOMRect(element, doc, topZeroY);
                finalY = null;
            }

            // Ensure page break if item is bigger then the space left for it to be added
            if (PDFDOMRect.y + PDFDOMRect.height >= PDFService.PdfContentHeight(doc)) {
                if (this.lastPageFormat === 'landscape') {
                    // doc.addPage('a4', 'landscape');
                } else {
                    doc.addPage();
                    pages++;
                }
                topZeroY = DOMRect.y ;
                PDFDOMRect.y = PDFService.paddingTop;
            }

            if (PDFService.isGroupedElement(element)) {
                let groupElements = element.getElementsByClassName(PDFElementTypes.GROUP_ELEMENT);
                for (let k = 0; k < groupElements.length; k++) {
                    let groupElement = groupElements[k];
                    PDFDOMRect = PDFService.convertDOMRectToPDFDOMRect(groupElement, doc, topZeroY);
                    this.addElementToPDF(groupElement, PDFDOMRect, doc, (y) => {
                        finalY = y;
                    });
                }
            } else {
                this.addElementToPDF(element, PDFDOMRect, doc, (y) => {
                    finalY = y;
                });
            }
        }

    }

    public static generatePDFFileName(name: string): string {
        return encodeURI(name.replace(/\s/g, '_')) + '.pdf';
    }

    protected beforePDFGeneration(): void {
        this.prepareECLChartsElements();
        this.pdfOriginalTableElements = document.getElementsByClassName('pdf-original-table');
    }

    protected afterPDFGeneration(): void {

    }

    protected static getMainElement(): HTMLElement {
        return window.document.getElementById(PDFService.mainElementId);
    }

    /**
     * ECL charts are added to the dom as PNG (svg to png plugin used) images and then converted to base64 JPEG images to reduce image
     * quality in order to reduce PDF size from 150MB to less than 1mb.
     */
    protected prepareECLChartsElements(): void {
        let chartElements = PDFService.getMainElement().getElementsByClassName('chart-placeholder');
        for (let i = 0; i < chartElements.length; i++) {
            let element = chartElements[i];
            let id = element.getAttribute('id');
            let asyncItem = this.asyncItems.find((item) => item.id === id);
            if (asyncItem) {
                element.innerHTML = `<img class="pdf-group-element pdf-as-image-to-base64" src="${asyncItem.result}">`;
            }
        }
    }

    public static convertDOMRectToPDFDOMRect(element: HTMLElement, doc: jsPDF, topZeroY: number): DOMRect {
        let DOMRect: any = element.getBoundingClientRect();
        let PDFDOMRect: DOMRect = {} as DOMRect;

        PDFDOMRect.x = PDFService.paddingLeft + (DOMRect.x / PDFService.ratioFromPdfToBrowser(doc));
        PDFDOMRect.y = PDFService.paddingTop + ((DOMRect.y - topZeroY) / PDFService.ratioFromPdfToBrowser(doc));

        let calculatedWidth = DOMRect.width / PDFService.ratioFromPdfToBrowser(doc);
        if (calculatedWidth > PDFService.PdfContentWidth(doc)) {
            calculatedWidth = PDFService.PdfContentWidth(doc);
        }
        let calculatedHeight = DOMRect.height * calculatedWidth / DOMRect.width;

        // Check if image height is bigger than a page height, if so, ensure scale to one page.
        if (PDFService.isImageElement(element) && calculatedHeight > PDFService.PdfContentHeight(doc)) {
            calculatedWidth = calculatedWidth * PDFService.PdfContentHeight(doc) / calculatedHeight;
            calculatedHeight = PDFService.PdfContentHeight(doc);
        }
        PDFDOMRect.width = calculatedWidth;
        PDFDOMRect.height = calculatedHeight;
        return PDFDOMRect;
    }

    protected addElementToPDF(element, PDFDOMRect: DOMRect, doc: jsPDF, callback: CallableFunction): void {
        switch (true) {
        case PDFService.isImageToBase64Element(element):
            doc.addImage(element.base64, 'JPEG', PDFDOMRect.x, PDFDOMRect.y, PDFDOMRect.width, PDFDOMRect.height);
            break;

        case PDFService.isImageElement(element):
                // PDFDOMRect.height -= PDFDOMRect.height - 15 > 10 ? 15 : 5; // Manual adjustment
            doc.addImage(element.base64, 'PNG', PDFDOMRect.x, PDFDOMRect.y, PDFDOMRect.width, PDFDOMRect.height);
            // doc.addImage(element.base64, 'JPEG', PDFDOMRect.x, PDFDOMRect.y, PDFDOMRect.width, PDFDOMRect.height);
                // doc.addImage(element.base64, 'JPEG', PDFDOMRect.x, PDFDOMRect.y);
            break;

        case PDFService.isTableElement(element):
            if (this.isTableIgnored(element)) {
                break;
            }
            let pdfTable: IPDFTable = JSON.parse(element.innerText.trim());
            doc.compatAPI((doc1) => {
                doc1.autoTable(pdfTable.columns, pdfTable.rows, {
                    styles: {
                        fillColor: [51, 51, 51],
                        lineColor: 240,
                        lineWidth: 1,
                    },
                    columnStyles: PDFService.generatePDFColumnStyles(pdfTable),
                    startY: doc1.pageCount > 1 ? PDFService.paddingTop : PDFDOMRect.y
                });
                callback(doc1.previousAutoTable.finalY);
            });
            break;

        default:
            doc.fromHTML(element, PDFDOMRect.x, PDFDOMRect.y, {
                width: PDFService.PdfContentWidth(doc),
                // 'height': calculatedHeight,
                elementHandlers: {
                    // '#ignorePDF'(element, renderer) {
                    //     return true;
                    // },
                }
            });
            break;
        }
        if (PDFService.isLinkElement(element)) {
            let url = element.getElementsByTagName('a')[0].getAttribute('href');
            if (!url.includes('http://') && !url.includes('https://')) {
                url = window.location.host + url;
            }
            doc.link(PDFDOMRect.x, PDFDOMRect.y, PDFDOMRect.width, PDFDOMRect.height, { url });
        }
    }

    protected static isClassInElement(element: HTMLElement, string: string): boolean {
        return !!element.getAttribute('class').includes(string);
    }

    protected static isImageElement(element: HTMLElement): boolean {
        return PDFService.isClassInElement(element, PDFElementTypes.AS_IMAGE);
    }

    protected static isLinkElement(element: HTMLElement): boolean {
        return PDFService.isClassInElement(element, PDFElementTypes.AS_LINK);
    }

    protected static isGroupedElement(element: HTMLElement): boolean {
        return PDFService.isClassInElement(element, PDFElementTypes.AS_GROUP_ELEMENT);
    }

    protected static isECLChartElement(element: HTMLElement): boolean {
        return PDFService.isClassInElement(element, PDFElementTypes.AS_ECL_CHART);
    }

    protected static isPageBreak(element: HTMLElement): boolean {
        return PDFService.isClassInElement(element, PDFElementTypes.PAGE_BREAK);
    }
    protected static isPageLandscape(element: HTMLElement): boolean {
        return PDFService.isClassInElement(element, PDFElementTypes.PAGE_LANDSCAPE);
    }
    protected static isPagePortrait(element: HTMLElement): boolean {
        return PDFService.isClassInElement(element, PDFElementTypes.PAGE_PORTRAIT);
    }
    protected static isTableElement(element: HTMLElement): boolean {
        return PDFService.isClassInElement(element, PDFElementTypes.AS_TABLE);
    }
    protected static isImageToBase64Element(element: HTMLElement): boolean {
        return PDFService.isClassInElement(element, PDFElementTypes.AS_IMAGE_TO_BASE64);
    }

    static generatePDFColumnStyles(pdfTable: IPDFTable): { [_: string]: { [_: string]: any } } {
        let columnStyles = {};
        for (let i = 0; i < pdfTable.columns.length; i++) {
            columnStyles[pdfTable.columns[i].dataKey] = { fillColor: false };
        }
        return columnStyles;
    }

    private static recalculateTopZeroYByFinalY(pdfElementY: number, topZeroY: number, finalY: number, ratioFromPdfToBrowser: number): number {
        finalY += 15; // add some space from the last element bottom
        if (finalY < pdfElementY) {
            let a = pdfElementY - finalY;
            let b = a * ratioFromPdfToBrowser;
            topZeroY += b;
        } else {
            let a = finalY - pdfElementY;
            let b = a * ratioFromPdfToBrowser;
            topZeroY -= b;
        }
        return topZeroY;
    }

    static convertImageUrlToBase64(src: string, callback: CallableFunction, quality: number = 1, background?: string): void {
        let img = new Image();
        img.crossOrigin = 'Anonymous';
        img.onload = () => {
            // @ts-ignore
            let canvas: HTMLCanvasElement = document.createElement('CANVAS');
            canvas.height = img.naturalHeight;
            canvas.width = img.naturalWidth;
            let ctx = canvas.getContext('2d');
            // Ensure white background for transparent images
            ctx.fillStyle = '#FFF';
            ctx.fillRect(0, 0, canvas.width, canvas.height);
            // Add image
            ctx.drawImage(img, 0, 0);
            if (background) {
                ctx.fillStyle = background;
                ctx.fillRect(0, 0, canvas.width, canvas.height);
            }
            // To be able to reduce image size we need to use jpeg here for all the images
            callback(canvas.toDataURL('image/jpeg', quality ? quality : 1));
        };
        img.src = src;
        if (img.complete || img.complete === undefined) {
            img.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==';
            img.src = src;
        }
    }

    static getHTMLImageElementSrcExtension(element: HTMLImageElement): string {
        let imageSrcArr = element.getAttribute('src').split('.');
        return imageSrcArr[imageSrcArr.length - 1].toUpperCase();
    }

    static getDefaultPDFLayoutElementStyle(): object {
        return { width: PDFService.browserContentWidth + 'px', height: 0 };
    }

    private isTableIgnored(element: any): boolean {
        let tableDataId = element.getAttribute('data-table-id');
        let originalTables = this.pdfOriginalTableElements;
        let ignore = !!originalTables.length;
        let foundEquivalent = false;
        for (let prop in originalTables) {
            if (!originalTables.hasOwnProperty(prop)) {
                continue;
            }
            let originalTable = originalTables[prop];
            let excludedTableId = originalTable.getAttribute('data-table-id');
            if (excludedTableId && excludedTableId === tableDataId) {
                foundEquivalent = true;
                let showTable = originalTable.getAttribute('data-show-table');
                if (showTable === 'true') {
                    ignore = false;
                }
                break;
            }
        }
        return !foundEquivalent ? false : ignore;
    }
}
