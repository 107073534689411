import { Action } from '@ngrx/store';
import { IDataType } from '@shared/interfaces';

export enum DataTypesActionTypes {
    AllDataTypesRequested = '[DATATYPES] Requested all data types',
    DataTypesLoaded = '[DATATYPES] Data types loaded',
}

export class AllDataTypesRequested implements Action {
    readonly type = DataTypesActionTypes.AllDataTypesRequested;
}

export class DataTypesLoaded implements Action {
    readonly type = DataTypesActionTypes.DataTypesLoaded;

    constructor(public payload: { dataTypes: IDataType[] }) {}
}

export type DataTypesActions =
    AllDataTypesRequested
    | DataTypesLoaded;
