import * as d3 from 'd3';
import { IChartGeoLayer } from '@features/general/charts/state/geo/cms-chart-geo.entity';
import { GeoPath, GeoProjection } from 'd3-geo';
import { ZoomTransform } from 'd3';
import { D3GContainer } from '@features/charts2/interfaces';
import {Polygon} from "@features/geo/structure";

export enum Charts2MapLayerTypes {
    base = 'base',
    data = 'data'
}

export interface ICharts2MapLayer extends IChartGeoLayer {
    type: Charts2MapLayerTypes;
    file: any;
}

export interface ICharts2MapGeoLayer extends ICharts2MapLayer {
    geo: any;
}

export interface ICharts2MapFeature {
    type: string;
    id: string;
    name: string;
    parent: string;
    properties: {
        [_: string]: string;
    }
    order: number;
    geometry: {
        coordinates: any;
        type: string;
    }
}

export enum Charts2MapLayerScopes {
    world = 'world',
    europe = 'europe',
    eu27 = 'eu27',
    indicator = 'indicator',
    asem = 'asem',
    unit = 'unit'
}

export enum Charts2MapUnitTypes {
    countries = 'countries',
    nuts = 'nuts',
    nuts0 = 'nuts0',
    nuts1 = 'nuts1',
    nuts2 = 'nuts2',
    nuts3 = 'nuts3',
    lau = 'local administration unit',
    urbanAudit = 'urban audit'
}

export enum Charts2MapProjectionTypes {
    mercator = 'mercator',
    equalarea = 'equalarea',
    equirectangular = 'geoEquirectangular',
    ortographic = 'geoOrthografic',
    naturalearth = 'geoNaturalEarth1'
}

export const ChartsMapProjections = new Map([
    [
        Charts2MapProjectionTypes.mercator,
        () => d3.geoMercator()
    ],
    [
        Charts2MapProjectionTypes.equalarea,
        () => d3.geoAzimuthalEqualArea()
    ],
    [
        Charts2MapProjectionTypes.equirectangular,
        () => d3.geoEquirectangular()
    ],
    [
        Charts2MapProjectionTypes.ortographic,
        () => d3.geoOrthographic()
    ],
    [
        Charts2MapProjectionTypes.naturalearth,
        () => d3.geoNaturalEarth1()
    ],
]);

export enum ChartsZoomOptions {
    ZOOM_IN = 'zoomin',
    ZOOM_OUT = 'zoomout',
    ZOOM_RESET = 'zoomreset'
}
