import { CmsChartsApiDataRoutes, CmsChartsDataSourceTypes } from '@features/general/charts/structure/cms-charts.structure';
import { Charts2SeriesTypes, Charts2States } from '@features/charts2/types/charts.enums';
import { IGenericCObject } from '@features/charts2/interfaces/base-chart.interface';

export interface CmsChartsTooltipState {
    chart_id: string;
    chart_type: string;
    params: IGenericCObject<any>;
    type: Charts2SeriesTypes;
    state: Charts2States;
    data_url: string;
    loaded: Map<string, any>;
}

export class CmsChartsTooltipHelper {

    static SetTooltip(tooltip: any, state: CmsChartsTooltipState): CmsChartsTooltipState {
        const data_url = CmsChartsTooltipHelper.GetDataUrlFromParams(tooltip.params, CmsChartsDataSourceTypes.dataUnits);
        return {
            ...state,
            ...tooltip,
            data_url,
            state: CmsChartsTooltipHelper.GetTooltipState(state.loaded, data_url)
        };
    }

    static GetTooltipState(loaded: Map<string, any>, data_url: string): Charts2States {
        if (loaded.has(data_url)) {
            return Charts2States.active;
        } else {
            return Charts2States.loading;
        }
    }

    static GetDataUrlFromParams(params: any, data_type: CmsChartsDataSourceTypes): string {
        const route = CmsChartsApiDataRoutes.get(data_type);
        if (!route) { return; }
        const route_params = Object.entries(params).reduce((url, elem) => {
            return `${url}&${elem[0]}=${elem[1]}`;
        }, '');

        return route.route() + route_params;
    }

    static GetInitialState(): CmsChartsTooltipState {
        return {
            chart_id: null,
            chart_type: null,
            params: {},
            type: null,
            state: Charts2States.loading,
            data_url: null,
            loaded: new Map()
        };
    }

    static AddNewData(tooltip: CmsChartsTooltipState, new_data: {url: string, data: any}): CmsChartsTooltipState {
        const loaded = tooltip.loaded;
        if (!loaded.has(new_data.url)) {
            loaded.set(new_data.url, new_data.data);
        }
        return {
            ...tooltip,
            state: Charts2States.active,
            loaded
        };
    }
}
