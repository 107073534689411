import { RouterHelper } from '@shared/helpers';

export enum SocialScoreboardRoutesList {
    HOME_PAGE = '',
    COUNTRY_PROFILE = 'country-profile',
    COUNTRY_ANALYSIS = 'country-analysis',
    MAP = 'map',
    COMPARISON_TABLE = 'table',
    COUNTRY_HEATMAP = 'country-heatmap',
    TIMELINE = 'time-line',
    REGIONAL_MAP = 'regional-map',
    REGIONAL_ANALYSIS = 'regional-analysis',
    REGIONAL_HEATMAP = 'regional-heatmap',
    DOCUMENTATION = 'documentation',
    MAINTENANCE = 'maintenance',
}

export class SocialScoreboardRoutes extends RouterHelper {
    static routesClass = SocialScoreboardRoutes;
    static MAIN_URL = 'social-scoreboard';
    static CMS_SITE = 'social-scoreboard';
    static routes = SocialScoreboardRoutesList;
}
