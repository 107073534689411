import {IComponent, IData, IScale} from "@shared/interfaces";

export class ScaleHelpers
{
    static getComponentScale(scales: IScale[], component: IComponent): IScale {
        return scales && component ? scales.find((sc) => sc.component_id === component.id) : null;
    }
}




