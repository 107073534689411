import { Injectable } from '@angular/core';
import { IData } from '@shared/interfaces';
import { SharedService } from '@shared/shared.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {EnvironmentType, RescaledModelDefault} from "@shared/enums/app.enums";

@Injectable()
export class DataService extends SharedService {

    makeRescaledUnitUrl(unitId: number, environment: EnvironmentType, min = RescaledModelDefault.min, max = RescaledModelDefault.max): string {
        return this.getApiUrl() + `/units/${unitId}/maindata/rescaled/${environment}/${min}/${max}`;
    }

    getDataByUrl(url: string): Observable<{'data': IData[], 'nextPage': string, url: string}> {
        return this.getFullCompactAnswerByUrl(url)
            .pipe(
                map((res) => {
                    const headers = res.meta ? res.meta.headers : [];
                    const dataObj = res.data.map((elem) => this.createDataObj(headers, elem));

                    const nextPage = this.nextPage(res.meta);

                    return { data: dataObj, nextPage: nextPage, url: url };
                }),
            );

    }

    getDataForOneIndiceAndUnit(indice: string, edition: number, unit_id: number): Observable<{'data': IData[], 'nextPage': string, url: string}> {
        const url = this.getApiUrl() + `indices/${indice}/${edition}/data?unit_id=${unit_id}`;

        return this.getDataByUrl(url);
    }

    getMainDataForOneIndiceAndUnit(indice: string, edition: number, unit_id: number): Observable<{'data': IData[], 'nextPage': string, url: string}> {
        const url = this.getApiUrl() + `indices/${indice}/${edition}/maindata?unit_id=${unit_id}`;

        return this.getDataByUrl(url);
    }

    getMainDataForOneUnitAllIndices(code: string): Observable<{'data': IData[], 'nextPage': string, url: string}> {
        const url = this.getApiUrl() + `units/${code}/maindata`;

        return this.getDataByUrl(url);
    }

    private createDataObj(keys: string[], values: any): IData {
        return keys.reduce((idata, key, idx) => { idata[key] = values[idx]; return idata; }, {}) as any;
    }

    private nextPage(metadata: any): string {
        try {
            return metadata.pagination.links.next;
        } catch (error) {
            return null;
        }
    }
}
